import { Checkbox } from "@components/Checkbox";
import { ErrorMessage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { useFundraiserForNonprofits } from "@components/donate/hooks";
import styled from "@emotion/styled";
import Big from "big.js";
import { useContext, useEffect, useState } from "react";
import { FieldError, FormState, UseFormReturn } from "react-hook-form";

import {
  FundraiserResponse,
  Uuid,
} from "@every.org/common/src/codecs/entities";
import { joinEnglishWordSeries } from "@every.org/common/src/helpers/string";

import { NonprofitsContext } from "src/context/NonprofitsContext";
import { getNonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

interface ToNonptofitsWeightsProps {
  fundraiser: FundraiserResponse;
  nonprofit: ContextNonprofit;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
}

const useInitializeWeights = (
  fundraiser: FundraiserResponse,
  form: UseFormReturn<DonateFormType>
) => {
  const { toNonprofits, toNonprofitWeights } = form.watch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const ids = fundraiser.forNonprofitIds;
    if (initialized || !ids || ids.length === 0) {
      return;
    }

    const weights = calculateWeights(ids);
    form.setValue("toNonprofits", ids);
    form.setValue("toNonprofitWeights", weights);
    setInitialized(true);
  }, [
    initialized,
    form,
    fundraiser.forNonprofitIds,
    toNonprofitWeights,
    toNonprofits,
  ]);

  return initialized;
};

function calculateWeights(ids: string[]) {
  if (ids.length === 0) {
    return [];
  }

  const weightPerId = new Big(100).div(ids.length).round(2);
  const weights: Big[] = new Array(ids.length).fill(weightPerId);

  let sum = new Big(0);

  for (const weight of weights) {
    sum = sum.add(weight);
  }

  // If the sum is not 100, add the difference to the first weight
  const diff = new Big(100).minus(sum);
  weights[0] = weights[0].plus(diff);

  return weights.map((weight) => weight.toNumber());
}

export function getErrorMessage(errors: FormState<DonateFormType>["errors"]) {
  // because "toNonprofits" has an array type,
  // the type of errors for "toNonprofits" will be an array of FieldError
  // but it not actually FieldError[], it is just FieldError
  const toNonprofitsError = errors.toNonprofits as unknown as FieldError;
  return toNonprofitsError?.message;
}

export function ToNonprofitWeights(props: ToNonptofitsWeightsProps) {
  const initialized = useInitializeWeights(props.fundraiser, props.form);

  const forNonprofits = useFundraiserForNonprofits(props.fundraiser);
  const { toNonprofits = [] } = props.form.watch();

  const errorMessage = getErrorMessage(props.form.formState.errors);

  if (!initialized) {
    return null;
  }

  const onChange = (nonprofitId: string) => {
    props.form.clearErrors("toNonprofits");
    const newArray = toNonprofits.includes(nonprofitId)
      ? toNonprofits.filter((id) => id !== nonprofitId)
      : [...toNonprofits, nonprofitId];
    props.form.setValue("toNonprofits", newArray);
    props.form.setValue("toNonprofitWeights", calculateWeights(newArray));
  };

  return (
    <div css={verticalStackCss.m}>
      <h4 css={textSizeCss.s}>
        {toNonprofits.length > 1
          ? "Donation split evenly between:"
          : "Supporting:"}
      </h4>
      <div css={verticalStackCss.xs}>
        {forNonprofits.map((nonprofit) => (
          <Checkbox
            css={horizontalStackCss.xxs}
            data-tname={`ToNonprofitWeights-${nonprofit.primarySlug}`}
            key={nonprofit.id}
            checked={toNonprofits.includes(nonprofit.id)}
            onChange={onChange.bind(null, nonprofit.id)}
          >
            {nonprofit.name}
          </Checkbox>
        ))}
        <MatchCapReachedMessage
          formContext={props.formContext}
          toNonprofits={toNonprofits}
        />
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export function useNonprofitsString({
  nonprofitIds,
}: {
  nonprofitIds?: string[] | null;
}) {
  const nonprofitsState = useContext(NonprofitsContext);
  if (!nonprofitIds) {
    return "the nonprofits";
  }
  const nonprofits = nonprofitIds
    .map((id) => getNonprofitOrUndefined(nonprofitsState, { id }))
    .filter((n): n is ContextNonprofit => !!n);
  return joinEnglishWordSeries(nonprofits.map((n) => n.name));
}

export function MatchCapReachedMessage({
  formContext,
  toNonprofits,
}: {
  formContext: DonateFormContext;
  toNonprofits: string[];
}) {
  const { nonprofitMatchCampaign } = formContext;

  const availableAmountPerNonprofit =
    nonprofitMatchCampaign?.availableAmountPerNonprofit;

  const nonprofitsReachedCap =
    availableAmountPerNonprofit &&
    Object.keys(availableAmountPerNonprofit).filter((key) => {
      return (
        toNonprofits.includes(key) &&
        new Big(availableAmountPerNonprofit[key as Uuid] || 0).eq(0)
      );
    });

  const nonprofitsString = useNonprofitsString({
    nonprofitIds: nonprofitsReachedCap,
  });

  if (!nonprofitMatchCampaign || toNonprofits.length === 0) {
    return null;
  }

  if (nonprofitsReachedCap?.length) {
    // I prefer "You can still donate but it will not be matched."
    // but for now Pivotal demanded "You can still donate to them but their donations cannot be matched."
    return (
      <p>
        <BoldText>NOTE:</BoldText>{" "}
        {nonprofitsReachedCap.length === 1 ? nonprofitsString : "This campaign"}{" "}
        has reached its cap in matching funds. You can still donate to them but
        their donations cannot be matched.
      </p>
    );
  }

  return null;
}

const BoldText = styled.span`
  font-weight: ${FontWeight.BOLD};
`;
