import { DonationCardCommentBodyProps } from "@components/DonationCard";
import { DonationHeaderCardProps } from "@components/DonationCardHeader";
import { UUID } from "io-ts-types";

import { DonationBoostResponse } from "@every.org/common/src/codecs/entities";
import { DonationFrequency } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export const MIN_CARD_WIDTH_RAW = 320;

export type DonationCardProps = {
  nonprofit: ContextNonprofit;
  frequency?: DonationFrequency;
  donationId?: UUID;
  feedId?: UUID;
  /**
   * Show the nonprofit as a chip instead of as a card with header image.
   */
  useNonprofitChip?: boolean;
  createdAt: Date | null;
  isPending?: boolean;
  /**
   * If present, renders an element below the contents of the donation card,
   * like actions
   */
  footer?: React.ReactNode;
  hideNonprofitDescription?: boolean;
  boost?: DonationBoostResponse;
  fromFundraiserId?: string | null;
  donationToJoinId?: string;
  userToJoinId?: string;
  timesCharged?: number;
  className?: string;
  shortDescription?: boolean;
} & DonationCardCommentBodyProps &
  DonationHeaderCardProps;
