import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect, useContext, FCC, useRef, useCallback } from "react";

import {
  EntityName,
  TagResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { AccountStatus } from "@every.org/common/src/entity/types";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import {
  clientRouteMetas,
  ClientRouteName,
  getRoutePath,
  RouteRestrictions,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { GetMeResponse, getMeRouteSpec } from "@every.org/common/src/routes/me";
import { SearchAllResponseBody } from "@every.org/common/src/routes/search";

import { AbTestProvider } from "src/context/AbTestContext";
import { AchievementsProvider } from "src/context/AchievementsContext";
import { AuthProvider, AuthContext } from "src/context/AuthContext";
import {
  logoutAuthState,
  updateAuthState,
} from "src/context/AuthContext/actions";
import { AuthStatus } from "src/context/AuthContext/types";
import { ConfigVariableProvider } from "src/context/ConfigVariableContext";
import { DonationsProvider } from "src/context/DonationsContext";
import { FundraisersProvider } from "src/context/FundraisersContext";
import { LikesProvider } from "src/context/LikesContext";
import { MyDonationsProvider } from "src/context/MyDonationsContext";
import { fetchMyDonations } from "src/context/MyDonationsContext/actions";
import { NonprofitsProvider } from "src/context/NonprofitsContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { PaymentRequestProvider } from "src/context/PaymentRequestContext";
import {
  SearchProvider,
  submitSearchAction,
  SearchContext,
  executeSearch,
} from "src/context/SearchContext";
import {
  useSearchOptionsFromUrl,
  isEmptySearch,
  useSearchRoute,
} from "src/context/SearchContext/helpers";
import {
  ContextSearchParams,
  LocalSearchRouteNames,
} from "src/context/SearchContext/types";
import { StatsigProvider } from "src/context/StatsigProvider";
import { TagsProvider } from "src/context/TagContext";
import { addTags } from "src/context/TagContext/actions";
import { EdoThemeContextProvider } from "src/context/ThemeContext";
import { TurnstileProvider } from "src/context/TurnstileContext";
import { UsersProvider } from "src/context/UsersContext";
import { isForbiddenApiError } from "src/errors/ApiError";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { getRouteNameForPathName } from "src/utilities/middleware/utilities";
import {
  getRedirection,
  getRestrictions,
} from "src/utilities/routeRestrictions";
import { queryApi } from "src/utility/apiClient";
import { buildUrlPath } from "src/utility/url";
import { isMissingRequiredUserFields } from "src/utility/user";

const restrictionsRedirect = (
  userStatus: GetMeResponse["user"],
  pathname: string
) => {
  const routeName = getRouteNameForPathName(pathname);
  const restrictions = getRestrictions(routeName);

  if (!restrictions) {
    return null;
  }

  // no status == not logged in
  if (!userStatus) {
    return restrictions === RouteRestrictions.ONBOARDING_REQUIRED_IF_AUTH
      ? null // Not logged in so ONBOARDING_REQUIRED_IF_AUTH does not require a redirect
      : ClientRouteName.LOGIN; // All other restrictions require log in (or sign up)
  }

  if (userStatus.entityName === EntityName.USER_PERSONAL) {
    return getRedirection({
      deactivated: userStatus.accountStatus === AccountStatus.DEACTIVATED,
      profileIncomplete: isMissingRequiredUserFields(userStatus),
      emailVerified: userStatus.isEmailVerified,
      routeName: routeName as ClientRouteName,
      restrictions,
    });
  }

  return null;
};

// TODO: find a way to bootstrap this stuff serverside for server-rendered
// routes
const ContextInitializer = () => {
  const authState = useContext(AuthContext);
  const searchState = useContext(SearchContext);
  const { pathname, push, hash, search } = useEdoRouter();

  // We wrap this in a useRef because we don't want to retrigger the effect
  // when the pathname changes. This should run the very first time the user enters to a page
  const redirectUserForRouteRequirements = useRef(
    (user?: GetMeResponse["user"]) => {
      const redirect = restrictionsRedirect(user, pathname);

      if (redirect) {
        push(
          getRoutePath({
            format: URLFormat.ABSOLUTE,
            name: redirect,
            query: {
              // Include ?redirectUrl=<currentUrl> in the redirect to get back here
              redirectUrl: buildUrlPath({
                pathname,
                search,
                hash,
              }),
            },
          })
        );
      }
    }
  );

  useEffect(function initializeAuthState() {
    async function fetchAuthState() {
      if (!Cookies.get(CookieKey.SESSION_EXISTS)) {
        logoutAuthState();
        redirectUserForRouteRequirements.current();
        return;
      }

      try {
        const { user, nonprofitTags } = await queryApi(
          getMeRouteSpec,
          {
            routeTokens: {},
            queryParams: {},
            body: {},
          },
          { disableUnauthenticatedToast: true }
        );
        if (nonprofitTags) {
          addTags(nonprofitTags);
        }
        updateAuthState(user);
        redirectUserForRouteRequirements.current(user);
      } catch (e) {
        if (isForbiddenApiError(e)) {
          logoutAuthState();
        }
      }
    }
    fetchAuthState();
  }, []);

  useEffect(
    function initializeMyDonations() {
      if (authState.status === AuthStatus.LOGGED_IN) {
        fetchMyDonations();
      }
    },
    [authState.status]
  );

  const searchParams = useSearchOptionsFromUrl();
  const searchRouteName = useSearchRoute().name;
  const router = useRouter();
  const { getPathnameWithParams } = useEdoRouter();
  const { path: currentPath } = getPathnameWithParams();

  const routerPush = useCallback(
    (path: string) => {
      // use replace only on search page
      if (
        clientRouteMetas[ClientRouteName.SEARCH_RESULTS].path === currentPath
      ) {
        router.replace(path, undefined, { shallow: true });
        return;
      }
      router.push(path);
    },
    [router, currentPath]
  );

  useEffect(
    /**
     * Triggers search for the query in the URL if present
     */
    function initializeSearchContext() {
      if (
        LocalSearchRouteNames.includes(searchRouteName) ||
        isEmptySearch(searchParams)
      ) {
        return;
      }
      submitSearchAction(searchParams, false);
    },
    [searchRouteName, searchParams]
  );
  useEffect(() => {
    executeSearch(searchState, searchRouteName, routerPush);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.submittedSearchParams]);

  return null;
};

export type InitialContextData = {
  nonprofits?: ContextNonprofit[];
  users?: UserResponse[];
  tags?: TagResponse[];
  search?: {
    searchResult: SearchAllResponseBody;
    searchParams: ContextSearchParams;
  };
  statsigInitProps?: Record<string, unknown>;
  abTestingId?: string;
};

/**
 * Component for registering all context providers necessary to run the
 * application
 *
 * - Also includes LoadingMask, which is used for animating the logo SVG with
 *   CSS into a loading icon, since functionally it's like a CSS "context" for
 *   the loading icon
 *
 * Note that providers with dependencies should be in order.
 * E.g.: AuthContext is used in PaymentRequestProvider, so Auth context should
 * be deeper
 *
 * Dependencias at 2023-08-17:
 *
 * ContextInitializer: AuthContext, SearchContext
 * StatsigProvider: AuthContext
 * AchievementsProvider: AuthContext
 * PaymentRequestProvider: AuthContext
 * NonprofitsProvider: LikesContext
 *
 */
export const ContextProviders: FCC<{ initialData?: InitialContextData }> = ({
  children,
  initialData,
}) => (
  <ConfigVariableProvider>
    <TurnstileProvider>
      <AuthProvider abTestingId={initialData?.abTestingId}>
        <SearchProvider ssrSearch={initialData?.search}>
          <ContextInitializer />
          <StatsigProvider
            statsigInitProps={initialData?.statsigInitProps}
            abTestingId={initialData?.abTestingId}
          >
            <AbTestProvider>
              <AchievementsProvider>
                <PaymentRequestProvider>
                  <LikesProvider>
                    <NonprofitsProvider initialData={initialData?.nonprofits}>
                      <FundraisersProvider>
                        <UsersProvider initialData={initialData?.users}>
                          <MyDonationsProvider>
                            <DonationsProvider>
                              <TagsProvider initialData={initialData?.tags}>
                                <EdoThemeContextProvider>
                                  {children}
                                </EdoThemeContextProvider>
                              </TagsProvider>
                            </DonationsProvider>
                          </MyDonationsProvider>
                        </UsersProvider>
                      </FundraisersProvider>
                    </NonprofitsProvider>
                  </LikesProvider>
                </PaymentRequestProvider>
              </AchievementsProvider>
            </AbTestProvider>
          </StatsigProvider>
        </SearchProvider>
      </AuthProvider>
    </TurnstileProvider>
  </ConfigVariableProvider>
);
