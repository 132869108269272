import { match } from "path-to-regexp";
import { useContext } from "react";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";
import {
  ClientRouteName,
  LEGACY_DONATE_CRYPTO_HASH,
  DONATE_HASH,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { AuthContext } from "src/context/AuthContext";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { useEdoRouter } from "src/hooks/useEdoRouter";

export const usePostDonationAction = ({
  redirectUrl,
  onComplete,
  nonprofit,
  fundraiser,
}: {
  redirectUrl?: string;
  onComplete?: () => void;
  nonprofit: NonprofitResponse;
  fundraiser?: FundraiserResponse | null;
}) => {
  const router = useEdoRouter();
  const authState = useContext(AuthContext);

  const pageToReturnUrl = [DONATE_HASH, LEGACY_DONATE_CRYPTO_HASH].find(
    (path) => match(path)(router.hash)
  )
    ? getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: nonprofit.primarySlug },
      })
    : router.pathname;

  const group = nonprofitOrUndefined(
    useNonprofit(
      authState.user?.memberships?.length === 1
        ? { id: authState.user?.memberships[0].nonprofitId }
        : undefined
    )
  );

  const primaryGroupUrl =
    group &&
    getRoutePath({
      format: URLFormat.RELATIVE,
      name: ClientRouteName.NONPROFIT_OR_CAUSE,
      tokens: { nonprofitSlug: group.primarySlug },
    });

  const fundraiserUrl =
    fundraiser &&
    getRoutePath({
      format: URLFormat.RELATIVE,
      name: ClientRouteName.FUNDRAISER,
      tokens: {
        nonprofitSlug: nonprofit.primarySlug,
        fundraiserSlug: fundraiser.slug,
      },
    });

  if (onComplete) {
    return onComplete;
  }

  return () =>
    router.push(
      redirectUrl || primaryGroupUrl || fundraiserUrl || pageToReturnUrl
    );
};
