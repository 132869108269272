import {
  EntityName,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";

import {
  FundraiserFetchStatus,
  FundraiserOrFetchStatus,
  FundraiserIdentifier,
  InternalFundraisersState,
  FundraisersState,
} from "src/context/FundraisersContext/types";
import { logger } from "src/utility/logger";

/**
 * Get a fundraiser that has been previously fetched.
 *
 * @returns the fundraiser if it is found locally, a `FundriserFetchStatus` if
 * not yet found, or `undefined` if the fundraiser has not been fetched yet.
 *
 */
export function getFundraiser(
  state: InternalFundraisersState,
  identifier: FundraiserIdentifier
):
  | FundraiserResponse
  | Exclude<FundraiserFetchStatus, FundraiserFetchStatus.FOUND>
  | undefined {
  const fetchStatus = state.fundraiserFetchStatus.get(identifier.id);
  if (!fetchStatus) {
    return undefined;
  }
  if (fetchStatus !== FundraiserFetchStatus.FOUND) {
    return fetchStatus;
  }

  const fundraiser = state.fundraisersById.get(identifier.id);
  if (!fundraiser) {
    logger.warn({
      message:
        "Fundraiser had been fetched before, but fundraiser data is missing",
      data: { identifier },
    });
    return undefined;
  }
  return fundraiser;
}

export function getFundraiserOrUndefined(
  state: FundraisersState,
  identifier: FundraiserIdentifier
): FundraiserResponse | undefined {
  return fundraiserOrUndefined(getFundraiser(state, identifier));
}

export function fundraiserOrUndefined(
  fundraiser: FundraiserOrFetchStatus | undefined
) {
  if (
    fundraiser &&
    typeof fundraiser !== "string" &&
    fundraiser.entityName === EntityName.FUNDRAISER
  ) {
    return fundraiser;
  }
  return undefined;
}
