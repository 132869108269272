import { NextPageContext } from "next";

import { SPECIAL_FUNDRAISER_NONPROFIT_SLUG } from "@every.org/common/src/entity/constants";

import { addCacheControl } from "src/utilities/ssr/caching";
import { validateAndDecodeParam } from "src/utilities/ssr/validateAndDecodeParam";
import { getFundraiserPageInitialProps } from "src/utility/apiClient/fundraiserPage";
import { getServerSideTestingProps } from "src/utility/statsig";

export type FundraiserInitialProps = {
  nonprofitSlug: string;
  fundraiserSlug: string;
} & Partial<Awaited<ReturnType<typeof getFundraiserPageInitialProps>>>;

export const KNOWN_MULTI_NONPROFIT_FUNDRAISERS = new Set([
  "test-pre-launch",
  "test-launched-gt",
  "test-after-gt",
  "test-partially-done",
  "test-done",
  "multi-nonprofit-fundraiser",
  "pivotal",
]);

export const getFundraiserInitialProps = async (
  ctx: NextPageContext
): Promise<FundraiserInitialProps> => {
  const nonprofitSlug = validateAndDecodeParam(ctx, "nonprofitSlugOrUsername");
  const fundraiserSlug = validateAndDecodeParam(ctx, "fundraiserSlug");

  const props = { nonprofitSlug, fundraiserSlug };

  // Not on server, do nothing - let client-side data fetching handle this
  if (!ctx.res || !ctx.req) {
    return props;
  }

  // Cache this SSR response at edge
  addCacheControl(ctx);

  // For special fundraiser pages use url without nonprofit slug
  // e.g. /fundraiser-slug instead of /special-fundraiser/f/fundraiser-slug
  if (nonprofitSlug === SPECIAL_FUNDRAISER_NONPROFIT_SLUG) {
    const location =
      ctx.req.url?.replace(
        `/${SPECIAL_FUNDRAISER_NONPROFIT_SLUG}/f/${fundraiserSlug}`,
        `/${fundraiserSlug}`
      ) || `/${fundraiserSlug}`;

    ctx.res.statusCode = 302;
    ctx.res.setHeader("Location", location);
    return props;
  }

  try {
    const data = await getFundraiserPageInitialProps({
      nonprofitSlug,
      fundraiserSlug,
    });

    return { ...props, ...data };
  } catch (error) {
    ctx.res.statusCode = 404;
    return props;
  }
};

export const getSpecialFundraiserInitialProps = async (
  ctx: NextPageContext
): Promise<FundraiserInitialProps | undefined> => {
  const fundraiserSlug = validateAndDecodeParam(ctx, "nonprofitSlugOrUsername");

  // Do not query special fundraisers for any non-known page
  // remove once we implement the actual solution to
  // https://github.com/everydotorg/every.org/issues/17709
  if (!KNOWN_MULTI_NONPROFIT_FUNDRAISERS.has(fundraiserSlug)) {
    return;
  }

  const props = {
    nonprofitSlug: SPECIAL_FUNDRAISER_NONPROFIT_SLUG,
    fundraiserSlug,
  };

  if (!ctx.res) {
    return props;
  }

  // Cache this SSR response at edge
  addCacheControl(ctx);

  try {
    const [data, serverSideTestingProps] = await Promise.all([
      getFundraiserPageInitialProps(props),
      getServerSideTestingProps(ctx),
    ]);

    const initialData = {
      ...data.initialData,
      ...serverSideTestingProps,
    };

    return { ...props, ...data, initialData };
  } catch (error) {
    return;
  }
};
