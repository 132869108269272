import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import {
  DonateModalAction,
  DonateV3FormProps,
  DonateV3PageProps,
} from "@components/donate/DonateV3/types";
import { usePostDonationAction } from "@components/donate/DonateV3/usePostDonationAction";
import { css } from "@emotion/react";
import { useCallback } from "react";
import { useMatch } from "react-router-dom";

import { HOTJAR_ABANDONED_DONATE_EVENT_NAME } from "@every.org/common/src/entity/constants";
import { daysAgo } from "@every.org/common/src/helpers/date";

import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing } from "src/theme/spacing";
import { getWindow } from "src/utility/window";

let hotjarAlreadyOpened = false;

export const Header = (props: DonateV3PageProps & DonateV3FormProps) => {
  const router = useEdoRouter();

  const {
    onComplete,
    formContext,
    nonprofit,
    donateAction,
    onRequestClose,
    fundraiser,
  } = props;

  const { redirectUrl, exitUrl } = formContext;

  const isShareMatchPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.SHARE_MATCH]
  );
  const isThankYouPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
  );
  const isDonationComplete = isShareMatchPage || isThankYouPage;
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();

  const postDonationAction = usePostDonationAction({
    redirectUrl,
    onComplete,
    nonprofit,
    fundraiser,
  });

  const onCancel = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (isDonationComplete) {
        postDonationAction();
      } else if (exitUrl) {
        router.push(exitUrl);
      } else {
        if (
          donateAction === DonateModalAction.DONATE &&
          // Don't ask the same person again in same session
          !hotjarAlreadyOpened &&
          // Don't bother people with questions if they just donated
          (!loggedInOrGuestUser?.lastDonation ||
            daysAgo(loggedInOrGuestUser.lastDonation.createdAt) > 1.0)
        ) {
          hotjarAlreadyOpened = true;
          const hotjar = getWindow()?.hj;
          hotjar && hotjar("event", HOTJAR_ABANDONED_DONATE_EVENT_NAME);
        }
        onRequestClose && onRequestClose(event);
      }
    },
    [
      donateAction,
      isDonationComplete,
      loggedInOrGuestUser,
      postDonationAction,
      onRequestClose,
      router,
      exitUrl,
    ]
  );

  return (
    <div
      css={[
        css`
          display: flex;
          padding: ${spacing.m} ${spacing.l};
          float: right;
        `,
        cssForMediaSize({
          min: MediaSize.LARGE,
          css: css`
            padding: ${spacing.l} 0 0;
          `,
        }),
      ]}
    >
      <Button
        role={ButtonRole.TEXT_ONLY}
        data-tname="closeDonateV3"
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: onCancel,
        }}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/XIcon")}
          display={IconDisplay.CURRENT_COLOR}
          size={IconSize.MEDIUM}
          css={css`
            display: none;
            ${cssForMediaSize({
              max: MediaSize.SMALL,
              css: css`
                display: block;
              `,
            })}
          `}
        />
        <span
          css={css`
            ${cssForMediaSize({
              max: MediaSize.SMALL,
              css: css`
                display: none;
              `,
            })}
          `}
        >
          {isDonationComplete ? "Close" : exitUrl ? "Exit" : "Cancel"}
        </span>
      </Button>
    </div>
  );
};
