import { EditableText } from "@components/EditableText";
import { css } from "@emotion/react";
import React, { useCallback, useContext } from "react";

import { FeedPage } from "@every.org/common/src/entity/types";

import {
  DonationCardCommentBodyProps,
  CommentContainer,
  readMoreOverlayLinkCss,
  clickableCss,
} from ".";

import { Link } from "src/components/Link";
import { ViewDonationContext } from "src/context/ViewDonationContext";
import { LinkAppearance } from "src/styles/link";
import { ClickAction } from "src/utility/analytics";
import { commentTextToDisplay } from "src/utility/comment";

export const DonationCardCommentBody: React.FCC<
  DonationCardCommentBodyProps
> = ({
  commentInterface,
  commentEditDisabled,
  autoFocus,
  initiallyEditingComment,
  saveOnBlur,
  page,
  donationPageLinkInfo,
  shortDescription = false,
}) => {
  const { text } = commentInterface;
  const { setDonationData = null } = useContext(ViewDonationContext) || {};
  const textToDisplay = commentTextToDisplay(text);
  const isTextCutoff = text !== textToDisplay;

  const showReadMore =
    isTextCutoff && donationPageLinkInfo && page !== FeedPage.LANDING;

  const storeDonationDataInContext = useCallback(() => {
    if (!setDonationData || !donationPageLinkInfo?.data) {
      return;
    }
    setDonationData(donationPageLinkInfo.data);
  }, [donationPageLinkInfo?.data, setDonationData]);

  if (!text && commentEditDisabled) {
    return null;
  }

  return (
    <CommentContainer>
      {commentEditDisabled && // do not add link wrapper if comment should be editable
        donationPageLinkInfo && (
          <Link
            title={`Link to donation ${donationPageLinkInfo.url}`}
            css={readMoreOverlayLinkCss}
            appearance={LinkAppearance.UNSTYLED}
            data-tname="DonationCard--ReadMoreOverlay"
            data-action={ClickAction.DONATION}
            to={donationPageLinkInfo.url}
            onClick={storeDonationDataInContext}
            // disallow focus on this, user can still get here through the "Read more" link below
            tabIndex={-1}
          />
        )}
      <EditableText
        data-tname="DonationCard--EditableText"
        textToDisplay={`${textToDisplay}${isTextCutoff ? "..." : ""}`}
        editCommentCss={clickableCss}
        viewCommentCss={
          showReadMore
            ? css`
                /* Make links inside comment text clickable from under ReadMoreOverlayLink*/
                a {
                  ${clickableCss}
                }
              `
            : undefined
        }
        initiallyEditing={initiallyEditingComment}
        disabled={commentEditDisabled}
        editableTextInterface={commentInterface}
        // let the caller decide if autofocus is acceptable
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        saveOnBlur={saveOnBlur}
        textEnding={
          showReadMore &&
          donationPageLinkInfo && (
            <React.Fragment>
              {" "}
              <Link
                appearance={LinkAppearance.HYPERLINK}
                data-tname="DonationCard--ReadMore"
                data-action={ClickAction.DONATION}
                /* Make 'Read more' clickable from under ReadMoreOverlayLink*/
                css={[
                  clickableCss,
                  css`
                    display: inline;
                    white-space: nowrap;
                  `,
                ]}
                to={donationPageLinkInfo.url}
                onClick={storeDonationDataInContext}
              >
                Read more
              </Link>
            </React.Fragment>
          )
        }
        shortDescription={shortDescription}
      />
    </CommentContainer>
  );
};
