import { DonationCardCommentBody } from "@components/DonationCard/DonationCardCommentBody";
import { SpecialFundraiserDonationCard } from "@components/DonationCard/SpecialFundraiserDonationCard";
import { DonationCardProps } from "@components/DonationCard/types";
import { DonationHeaderCard } from "@components/DonationCardHeader";
import { NonprofitCard as NewNonprofitCard } from "@components/feed/NewNonprofitCard";
import { FULL_SIZE_LINK_CLASS } from "@components/feed/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, useContext } from "react";

import { SPECIAL_FUNDRAISER_NONPROFIT_ID } from "@every.org/common/src/entity/constants";
import { FeedItemType, FeedPage } from "@every.org/common/src/entity/types";

import { Link } from "src/components/Link";
import {
  ViewDonationContext,
  ViewDonationData,
  ViewDonationProvider,
} from "src/context/ViewDonationContext";
import { EditableTextInterface } from "src/hooks/useEditableText";
import { verticalStackCss } from "src/theme/spacing";
import { compositionTextCss } from "src/theme/text";

const Comment = styled.article`
  ${compositionTextCss};
  overflow-wrap: break-word;
`;

// Used to make elements clickable if there is an absolutely positioned link on top of them
export const clickableCss = css`
  position: relative;
  z-index: 1;
`;

export const ScrollableComment = styled(Comment)`
  overflow-y: auto;
`;

export interface DonationCardCommentBodyProps {
  /**
   * Page the DonationCard is being rendered on, to control different data that
   * gets shown
   */
  page?: FeedPage;
  /**
   * When editing a comment, saves the comment when the comment input loses
   * focus if true
   */
  saveOnBlur?: boolean;
  /**
   * If true, focuses on the comment text box on render automatically
   */
  autoFocus?: boolean;
  /**
   * If true, clicking the comment will not allow you to edit its comment
   */
  commentEditDisabled?: boolean;
  commentInterface: EditableTextInterface;
  initiallyEditingComment?: boolean;
  /**
   * Location to use as link to full donation page, accessed by clicking the
   * Read More link
   *
   * If not present, Read More link will not be rendered
   */
  donationPageLinkInfo: {
    url: string;
    data: ViewDonationData | null;
  } | null;
  shortDescription?: boolean;
}

export const CommentContainer = styled.section`
  position: relative;
`;

export const readMoreOverlayLinkCss = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

/**
 * Card that displays a donation.
 */

export const DonationCard = (props: DonationCardProps) => {
  return (
    <ViewDonationProvider>
      <DonationCardImp {...props} />
    </ViewDonationProvider>
  );
};

const DonationCardImp: React.FCC<DonationCardProps> = (props) => {
  const {
    frequency,
    footer,
    hideNonprofitDescription = false,
    removeTimestamp,
    createdAt,
    isPending,
    feedId,
    donationId,
    donationToJoinId,
    userToJoinId,
    donationPageLinkInfo,
    className,
    fromFundraiserId,
    nonprofit,
    ...rest
  } = props;

  const { setDonationData = null } = useContext(ViewDonationContext) || {};
  const storeDonationDataInContext = useCallback(() => {
    if (!setDonationData || !donationPageLinkInfo?.data) {
      return;
    }
    setDonationData(donationPageLinkInfo.data);
  }, [donationPageLinkInfo?.data, setDonationData]);

  if (nonprofit.id === SPECIAL_FUNDRAISER_NONPROFIT_ID && fromFundraiserId) {
    return (
      <SpecialFundraiserDonationCard
        {...props}
        fromFundraiserId={fromFundraiserId}
      />
    );
  }

  return (
    <NewNonprofitCard
      causeCategoryBackgroundColor
      data-feed-feedid={feedId}
      data-feed-itemid={donationId}
      data-feed-itemtype={FeedItemType.USER_DONATION}
      nonprofit={nonprofit}
      shorten={hideNonprofitDescription}
      className={className}
      userToJoinId={userToJoinId}
      customHeader={
        <div css={verticalStackCss.m}>
          <DonationHeaderCard
            {...rest}
            donationData={donationPageLinkInfo?.data}
            frequency={frequency}
            removeTimestamp={removeTimestamp}
            createdAt={createdAt}
            isPending={isPending}
          />
          <DonationCardCommentBody
            donationPageLinkInfo={donationPageLinkInfo}
            {...rest}
          />
        </div>
      }
      customLink={
        rest.commentInterface.text &&
        donationPageLinkInfo && (
          <Link
            title={`Feed donation card link to ${donationPageLinkInfo.url}`}
            className={FULL_SIZE_LINK_CLASS}
            data-tname={"feedDonationCardLink"}
            to={donationPageLinkInfo.url}
            onClick={storeDonationDataInContext}
          >
            Feed donation card link to ${donationPageLinkInfo.url}
          </Link>
        )
      }
      customFooter={footer}
    />
  );
};
