import { ButtonSize, ButtonRole } from "@components/Button";
import { FeedCard } from "@components/feed/shared";
import {
  SkeletonAnimationOverlay,
  SkeletonUserSupported,
  SkeletonComment,
  SkeletonImageCover,
  SkeletonButton,
} from "@components/skeleton/SkeletonComponents";
import { css } from "@emotion/react";
import React, { useId } from "react";

import { seededRandomMinMax } from "@every.org/common/src/helpers/number";

import { horizontalStackCss } from "src/theme/spacing";

export const SkeletonDonationCard = React.memo(
  function SkeletonDonationCardImpl() {
    const seed = useId();
    const showSecondaryButton = !!seededRandomMinMax(seed, 0, 2);

    return (
      <FeedCard
        css={css`
          position: relative;
          overflow: hidden;
        `}
      >
        <SkeletonAnimationOverlay />
        <SkeletonUserSupported />
        <SkeletonComment />
        <SkeletonImageCover />
        <div
          css={[
            horizontalStackCss.m,
            css`
              margin-left: auto;
              align-items: center;
            `,
          ]}
        >
          {showSecondaryButton && (
            <SkeletonButton
              size={ButtonSize.MEDIUM}
              role={ButtonRole.TEXT_ONLY}
            />
          )}
          <SkeletonButton size={ButtonSize.MEDIUM} role={ButtonRole.PRIMARY} />
        </div>
      </FeedCard>
    );
  }
);
