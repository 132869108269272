import { Big } from "big.js";

/**
 * Abbreviates positive numbers with its SI symbol, truncating to the number of
 * significant digits.
 *
 * Usage:
 * abbreviateNumber(1234, 1) = 1.2k
 * abbreviateNumber(9999999, 2) = 9.9m
 *
 * @param number Number to abbreviate.
 * @param significantDigits Number of digits after the decimal point. It truncates the number instead of rounding.
 */
export const abbreviateNumber = (
  n: number,
  significantDigits?: number
): string => {
  if (n < 1e3) {
    return n.toString();
  }
  let symbol = "";
  let denom = 1;
  const RoundDownBig = Big();
  RoundDownBig.RM = 0; // Set rounding mode to round down.
  if (n >= 1e3 && n < 1e6) {
    denom = 1e3;
    symbol = "k";
  } else if (n >= 1e6 && n < 1e9) {
    denom = 1e6;
    symbol = "m";
  } else if (n >= 1e9 && n < 1e12) {
    denom = 1e9;
    symbol = "b";
  } else if (n >= 1e12) {
    denom = 1e12;
    symbol = "t";
  }
  if (significantDigits === undefined) {
    return (
      new RoundDownBig(n / denom).toFixed(3).replace(/\.?0+$/, "") + symbol
    );
  }

  return new RoundDownBig(n / denom).toFixed(significantDigits) + symbol;
};

export function getRoundedPerc(amount: number, perc: number) {
  return Math.round((amount * perc) / 100);
}

export function errorIfNotPositiveInteger(amount: number) {
  if (!Number.isInteger(amount)) {
    throw Error(`${amount} is not an integer`);
  }
  if (amount <= 0) {
    throw Error(`${amount} is not positive`);
  }
  if (amount > Number.MAX_SAFE_INTEGER) {
    throw Error(`${amount} is larger than Number.MAX_SAFE_INTEGER`);
  }
}

/**
 * Returns an array of integers of length size starting from min (inclusive)
 */
export function intRange(params: { min: number; length: number }): number[] {
  return [...Array(params.length).keys()].map((i) => i + params.min);
}

/**
 * Converts negative numbers to wrapped-around indexes in a collection with the given size
 *
 * @example -1 maps to the last element, -2 maps to the second to last element
 */
export function wrapIndex(params: { index: number; numItems: number }) {
  const mod = params.index % params.numItems;
  return mod < 0 ? mod + params.numItems : mod;
}

/**
 * Rounds an array of numbers using [Largest Remainder Method](https://en.wikipedia.org/wiki/Largest_remainder_method).
 * This is useful when trying to round a set of numbers that should add to up 100 ([see S/O question](https://stackoverflow.com/a/13483710))
 *
 * @param numbers The set of numbers to round
 * @param targetValue What we want the numbers to add up to (i.e. 100)
 * @returns The original set of numbers, either rounded up or down, so that the sum is equal to `targetValue`. Original sorting is maintained.
 */
export function largestRemainderRounding(
  numbers: number[],
  targetValue: number
) {
  // Step 1: Find the difference between 100 and the sum of all values rounded
  const roundedDifference =
    targetValue - numbers.reduce((a, b) => a + Math.round(b), 0);

  // Step 2: Make a data structure where we can modify the values, but keep track of the original sort indices
  const valuesWithIndices: [number, number][] = numbers.map((value, index) => [
    value,
    index,
  ]);

  // Step 3: Sort the values by largest to smallest remainder after rounding
  const sortedByRemainders = valuesWithIndices.sort(([value, index]) => {
    return Math.round(value) - value;
  });

  // Step 4: Add/subtract 1, up to the value found in Step 1
  const newValues: [number, number][] = sortedByRemainders.map(
    ([value, originalIndex], i) => {
      if (roundedDifference > i) {
        // If the difference (Step 1) is positive, add one to the numbers with the largest remainders
        return [Math.round(value) + 1, originalIndex];
      }

      if (i >= numbers.length + roundedDifference) {
        // If the difference (Step 1) is negative, subtract one from the numbers with the smallest remainders
        return [Math.round(value) - 1, originalIndex];
      }

      // For all other values, just return the rounded value
      return [Math.round(value), originalIndex];
    }
  );

  // Step 5: Revert to the original sorting
  const originalSortNewValues = newValues.sort((a, b) => {
    return a[1] - b[1];
  });

  // Step 6: Discard original index info, just return values
  return originalSortNewValues.map(([value, originalIndex]) => value);
}

// Gotten from chat-gpt
function stringToNumber(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i); // Get the character's Unicode value
    hash = (hash * 31 + charCode) | 0; // Multiply hash by a prime number and add charCode
  }
  return Math.abs(hash); // Ensure non-negative number
}

// Seeded PRNG using a simple linear congruential generator (LCG)
// Gotten from chat-gpt
export function seededRandom(seed: string) {
  let value = stringToNumber(seed) % 2147483647;
  if (value <= 0) {
    value += 2147483646;
  }
  return function () {
    value = (value * 16807) % 2147483647;
    return (value - 1) / 2147483646;
  };
}

// Generate a random number within a given range
export function seededRandomMinMax(seed: string, min: number, max: number) {
  const random = seededRandom(seed);
  return Math.round(min + random() * (max - min));
}

/**
 * Shuffles an array using the Fisher-Yates algorithm and returns a new shuffled array.
 * This ensures the original array remains unchanged.
 * @param {T[]} array - The array to shuffle, where T is the type of elements in the array.
 * @param {() => [0..1]} - A function that returns a random number between 0 and 1
 * @return {T[]} - A new shuffled array.
 */
export function shuffleArray<T>(array: T[], random = Math.random): T[] {
  // Work on a new array derived from the original
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i
    const randomIndex = Math.floor(random() * (i + 1));
    // Swap elements at i and randomIndex
    [shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]];
  }
  return shuffled;
}
