import React from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";

/**
 * Status of a tag being searched for
 */
export enum FundraiserFetchStatus {
  FOUND = "FOUND",
  FETCHING_FUNDRAISER = "FETCHING_FUNDRAISER",
  FUNDRAISER_NOT_FOUND = "FUNDRAISER_NOT_FOUND",
}

export type FundraiserOrFetchStatus =
  | FundraiserResponse
  | Exclude<FundraiserFetchStatus, FundraiserFetchStatus.FOUND>;

/**
 * Current state in browser memory of fetched fundraisers
 */
export interface FundraisersState {
  dispatchFundraiserAction: React.Dispatch<FundraiserAction>;
  fundraisersById: Map<FundraiserResponse["id"], FundraiserResponse>;
  fundraiserFetchStatus: Map<FundraiserResponse["id"], FundraiserFetchStatus>;
}
export type InternalFundraisersState = Omit<
  FundraisersState,
  "dispatchFundraiserAction"
>;

export type FundraiserIdentifier = { id: FundraiserResponse["id"] };

export enum FundraiserActionType {
  FETCHING_FUNDRAISER = "FETCHING_TAG",
  ADD_FUNDRAISER = "ADD_FUNDRAISER",
  ADD_FUNDRAISERS = "ADD_FUNDRAISERS",
  FUNDRAISER_NOT_FOUND = "FUNDRAISER_NOT_FOUND",
}
interface FetchingFundraiserAction {
  type: FundraiserActionType.FETCHING_FUNDRAISER;
  data: FundraiserIdentifier;
}
interface AddFundraiserAction {
  type: FundraiserActionType.ADD_FUNDRAISER;
  data: FundraiserResponse;
}
interface AddFundraisersAction {
  type: FundraiserActionType.ADD_FUNDRAISERS;
  data: FundraiserResponse[];
}
interface FundraiserNotFoundAction {
  type: FundraiserActionType.FUNDRAISER_NOT_FOUND;
  data: FundraiserIdentifier;
}
export type FundraiserAction =
  | AddFundraiserAction
  | AddFundraisersAction
  | FetchingFundraiserAction
  | FundraiserNotFoundAction;
