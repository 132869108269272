import { useLayer, useStatsigUser } from "@statsig/react-bindings";
import Cookies from "js-cookie";
import { useContext, useEffect, useMemo } from "react";
import { v4 as uuidV4 } from "uuid";

import { ABTestingId } from "@every.org/common/src/codecs/entities";
import { CookieKey } from "@every.org/common/src/entity/types/cookies";
import {
  TEST_ID_CACHE_EXPIRY_DAYS,
  chooseABVariant,
} from "@every.org/common/src/helpers/abtesting/index";
import { ABTest } from "@every.org/common/src/helpers/abtesting/types";

import {
  AbTestActionType,
  AbTestContext,
  dispatchAbTestAction,
} from "src/context/AbTestContext";
import { AuthContext } from "src/context/AuthContext";
import { AuthState, AuthStatus } from "src/context/AuthContext/types";
import { trackABTest } from "src/utility/analytics";
import { parseCookieFromString, setCookie } from "src/utility/cookies";
import { getClientBotType } from "src/utility/helpers";

export function getTestingIdFromCookie(): ABTestingId {
  return Cookies.get(CookieKey.TESTING_ID_CACHE) as ABTestingId;
}

export function createTestingId(): ABTestingId {
  // Generates a new uuid to use as the testing id
  return uuidV4() as ABTestingId;
}

export function resetTestingIdInCookie(id: ABTestingId) {
  setCookie(CookieKey.TESTING_ID_CACHE, id, {
    expires: TEST_ID_CACHE_EXPIRY_DAYS,
  });
}

function createTestingIdInCookie(): ABTestingId {
  const newId = createTestingId();
  setCookie(CookieKey.TESTING_ID_CACHE, newId, {
    expires: TEST_ID_CACHE_EXPIRY_DAYS,
  });
  return newId as ABTestingId;
}

export function getTestingIdFromServerCookie(cookie?: string): ABTestingId {
  const cookieObj = parseCookieFromString(cookie);
  return cookieObj[CookieKey.TESTING_ID_CACHE] as ABTestingId;
}

/**
 * Gets the canonical id for the user to ensure they are assigned consistent
 * variants across a session for A/B tests.
 *
 * For any user with a session, the user's ID serves as a stable ID; but logged
 * out users don't have a user ID, so we create a random testing ID and store it
 * in a cookie instead (consider that this id might have been generated on the
 * server rendering side and might not be in a cookie yet).
 */
export function getTestingId({
  authState,
  createIdIfMissing = true,
  ssrTestingId,
}: {
  authState: AuthState | null;
  createIdIfMissing?: boolean;
  ssrTestingId?: string | undefined;
}): ABTestingId | undefined {
  const firstOption =
    authState?.user?.abTestingId ||
    authState?.guestUser?.abTestingId ||
    authState?.abTestingId ||
    getTestingIdFromCookie();
  if (firstOption) {
    return firstOption;
  }
  if (ssrTestingId) {
    setCookie(CookieKey.TESTING_ID_CACHE, ssrTestingId, {
      expires: TEST_ID_CACHE_EXPIRY_DAYS,
    });
    return ssrTestingId as ABTestingId;
  }
  if (createIdIfMissing) {
    return createTestingIdInCookie();
  }
  return undefined;
}

const CONTROL_VARIANT = "CONTROL";

/**
 * Uses a statsig layer experiment.
 */
export function useStatSigLayer(layerName: string, skipAbTest?: boolean) {
  const isBot = !!getClientBotType();
  // Potentially not needed since exposure logs are not logged
  // until layer.getValue is called. So we could just not call that
  const exposureLoggingDisabled = skipAbTest || isBot;

  const layer = useLayer(layerName, {
    disableExposureLog: exposureLoggingDisabled,
  });

  useEffect(() => {
    if (exposureLoggingDisabled) {
      return;
    }
    const layerAndGroup = layer.name + "-group-" + layer.groupName;
    trackABTest(layerName, layerAndGroup);
    // The group is usually "null" unless we have explicitly set it as a parameter.
    // We will have to do that for all groups where we want to log the exposure.
    layer.groupName &&
      dispatchAbTestAction({
        type: AbTestActionType.ADD_EXPOSURE,
        layer: layerAndGroup,
      });
  }, [exposureLoggingDisabled, layerName, layer]);

  return useMemo(
    () => (exposureLoggingDisabled ? null : layer),
    [exposureLoggingDisabled, layer]
  );
}

export function useNonprofitLayer(
  layerName: string,
  nonprofitId?: string,
  skipAbTest?: boolean
) {
  // Sync vs Async functions similarly to client initialization
  // https://docs.statsig.com/client/javascript-sdk/migrating-from-statsig-js#updating-the-user
  // https://docs.statsig.com/client/javascript-sdk/init-strategies#3-asynchronous-initialization---not-awaited
  const { updateUserAsync, user } = useStatsigUser();
  const abTestContext = useContext(AbTestContext);

  useEffect(() => {
    if (user && nonprofitId && user.customIDs?.nonprofitId !== nonprofitId) {
      updateUserAsync((previousUser) => {
        previousUser.customIDs = {
          ...(previousUser.customIDs || {}),
          nonprofitId,
        };
        return previousUser;
      });
      dispatchAbTestAction({
        type: AbTestActionType.SET_NONPROFIT_ID,
        nonprofitId: nonprofitId,
      });
    }
  }, [user, nonprofitId, updateUserAsync]);

  // TODO-question What's the point of this? If we always set the nonprofit_id
  // as part of the useEffect hook, won't this always be true?
  // Even before my changes, we would call updateUser, and then we would
  // update the context in the useEffect hook in _app.ts.
  const nonprofitIdIsSet = abTestContext.nonprofitId === nonprofitId;

  const layer = useStatSigLayer(
    layerName,
    !nonprofitIdIsSet || skipAbTest || !nonprofitId
  );

  return layer;
}
/**
 * Gets the AB Test variant that the current user should fall under
 */
export function useABTestTrack<Variant extends string>(
  abTest: ABTest<Variant>,
  /**
   * Short circuits setting the A/B test. Since we can't call hooks
   * conditionally, set this property if you want don't want to participate in
   * the A/B test.
   */
  skipAbTest?: boolean
): Variant | undefined {
  const authState = useContext(AuthContext);

  return useMemo(() => {
    if (skipAbTest) {
      return undefined;
    }

    if (authState.status === AuthStatus.LOADING) {
      return undefined;
    }

    if (getClientBotType()) {
      return (
        CONTROL_VARIANT in abTest.variants
          ? CONTROL_VARIANT
          : Object.keys(abTest.variants)[0]
      ) as Variant;
    }

    const testingId = getTestingId({ authState }) as ABTestingId;
    const choice = chooseABVariant(abTest, testingId, trackABTest);
    return choice;
  }, [abTest, authState, skipAbTest]);
}

const MAX_VARIANT_LEN = 20;
function variantName(copy: string, isControl: boolean, index: number) {
  return (
    (isControl ? "C" : "E") +
    index +
    " " +
    copy.trim().substr(0, MAX_VARIANT_LEN)
  );
}

/**
 * Use this function to very quickly test out different copy options,
 * for instance on 2021-10-20 if a button currently says "Donate" but
 * you want to try out saying "Give" or "Support" then you could call
 * useABCopy("2021-10-20-DonateOrGive", "Donate", "Give", "Support")
 * and it would make an experiment with three variants
 * "C0 Donate" "E0 Give" "E1 Support" (C for Control, E for Enabled).
 */
export function useABCopy(
  experimentName: string,
  controlCopy: string,
  ...enabledCopy: string[]
) {
  const { variantToCopy, abTest } = useMemo(() => {
    const variantToCopy = Object.fromEntries(
      enabledCopy.map((e, i) => [variantName(e, false, i), e])
    );
    variantToCopy[variantName(controlCopy, true, 0)] = controlCopy;
    const variants = Object.fromEntries(
      Object.keys(variantToCopy).map((variant) => [variant, 1])
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const abTest: ABTest<any> = {
      name: experimentName,
      variants,
    };
    return { variantToCopy, abTest };
  }, [experimentName, controlCopy, enabledCopy]);
  const variant = useABTestTrack(abTest);
  return variantToCopy[variant] ?? controlCopy;
}
