import { AvatarSize, RawAvatar } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { FundraiserCard } from "@components/NewFundraiserCard";
import {
  Chip,
  ExternalCard,
  HeaderChip,
} from "@components/donate/DonateV3/NonprofitInfo/styles";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { NonprofitCard } from "@components/feed/NewNonprofitCard";
import {
  FundraiserTitleSection,
  TitleSection,
} from "@components/feed/NewNonprofitCard/TitleSection";
import { nameCss } from "@components/feed/NewNonprofitCard/TitleSection/styles";
import { css } from "@emotion/react";
import React, { useMemo } from "react";
import { useMatch } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { SPECIAL_FUNDRAISER_NONPROFIT_ID } from "@every.org/common/src/entity/constants";
import { FundraiserType } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

interface NonprofitInfoProps {
  nonprofit: ContextNonprofit;
  customNonprofitDescription?: string;
  fundraiser?: FundraiserResponse;
  formContext: DonateFormContext;
}

export const NonprofitInfo = (props: NonprofitInfoProps) => {
  return (
    <React.Fragment>
      <div
        css={css`
          display: block;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "none" },
          })}
        `}
      >
        <NonprofitInfoMediumScreen {...props} />
      </div>
      <div
        css={css`
          display: none;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "block" },
          })}
        `}
      >
        <NonprofitInfoLargeScreen {...props} />
      </div>
    </React.Fragment>
  );
};

const NonprofitInfoLargeScreen = ({
  nonprofit: initialNonprofit,
  customNonprofitDescription,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  const { amountBig } = formContext;
  const isShareMatchPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.SHARE_MATCH]
  );
  const isThankYouPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
  );
  const isDonationComplete = isShareMatchPage || isThankYouPage;
  const nonprofit = useMemo((): ContextNonprofit => {
    return {
      ...initialNonprofit,
      description: customNonprofitDescription ?? initialNonprofit.description,
    };
  }, [initialNonprofit, customNonprofitDescription]);

  if (formContext.isExternal) {
    return (
      <ExternalCard>
        <div css={horizontalStackCss.s}>
          <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.MEDIUM} />
          <h5 css={textSizeCss.s}>
            {nonprofit.name}{" "}
            <span css={{ fontWeight: FontWeight.REGULAR }}>
              is using Every.org to accept this donation
            </span>
          </h5>
        </div>
        <p>Every.org is a nonprofit dedicated to philanthropy for everyone.</p>
        {customNonprofitDescription && <p>{customNonprofitDescription}</p>}
      </ExternalCard>
    );
  }

  if (fundraiser) {
    return (
      <FundraiserCard
        nonprofit={nonprofit}
        fundraiser={fundraiser}
        showActions={false}
        showHeader
        clickable={false}
        incomingDonationAmount={isDonationComplete ? amountBig : undefined}
      />
    );
  }

  if (nonprofit.id === SPECIAL_FUNDRAISER_NONPROFIT_ID) {
    return null;
  }

  return (
    <NonprofitCard
      nonprofit={nonprofit}
      showButtons={false}
      causeCategoryBackgroundColor
      clickable={false}
    />
  );
};

const NonprofitInfoMediumScreen = ({
  nonprofit,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  if (fundraiser?.type === FundraiserType.MULTIPLE_NONPROFITS) {
    const name = fundraiser.title;
    const avatarCloudinaryId = fundraiser.metadata?.avatarCloudinaryId;

    return (
      <Chip>
        {avatarCloudinaryId && (
          <RawAvatar
            cloudinaryId={avatarCloudinaryId}
            size={AvatarSize.SMALL}
          />
        )}
        <h5>{name}</h5>
      </Chip>
    );
  }

  return (
    <Chip>
      <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.SMALL} />
      {formContext.isExternal ? (
        <h5 css={textSizeCss.s}>
          {nonprofit.hasAdmin
            ? `${nonprofit.name} is using Every.org to accept this donation`
            : `Every.org created this page for ${nonprofit.name}`}
        </h5>
      ) : fundraiser ? (
        <FundraiserTitleSection nonprofit={nonprofit} fundraiser={fundraiser} />
      ) : (
        <TitleSection nonprofit={nonprofit} />
      )}
    </Chip>
  );
};

export const NonprofitInfoHeaderSmallScreen = ({
  nonprofit,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  if (fundraiser?.type === FundraiserType.MULTIPLE_NONPROFITS) {
    const name = fundraiser.title;
    const avatarCloudinaryId = fundraiser.metadata?.avatarCloudinaryId;

    return (
      <HeaderChip>
        {avatarCloudinaryId && (
          <RawAvatar
            cloudinaryId={avatarCloudinaryId}
            size={AvatarSize.X_SMALL}
          />
        )}
        <h3 css={textSizeCss.s}>{name}</h3>
      </HeaderChip>
    );
  }

  return (
    <HeaderChip>
      <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.SMALL} />
      <h3 css={nameCss}>
        {fundraiser ? fundraiser?.title || nonprofit.name : nonprofit.name}
      </h3>
    </HeaderChip>
  );
};
