import Thermometer, { ThermometerProps } from "@components/Thermometer";
import { RaisedAmountTooltip } from "@components/Thermometer/RaisedAmountTooltip";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { forwardRef } from "react";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

// TODO #8478: parametrize currency
export const LabeledThermometer = forwardRef<HTMLDivElement, ThermometerProps>(
  function LabeledTermometerImpl({ labelPosition = "bottom", ...props }, ref) {
    const {
      className,
      currency,
      goalLabel = "Goal",
      progressLabel = "Amount raised",
    } = props;

    const monthlyAnnualTotal =
      !props.dataMissing && typeof props.monthly?.times === "function"
        ? { currency, amount: new Big(props.monthly.times(12).toFixed(0)) }
        : undefined;

    const totalAmount = props.dataMissing
      ? new Big(0)
      : new Big(props.currentAmount).add(props.raisedOffline ?? 0);

    const raisedOffline =
      !props.dataMissing && props.raisedOffline
        ? { currency, amount: new Big(props.raisedOffline.toFixed(0)) }
        : undefined;

    const raisedMatches =
      !props.dataMissing && props.raisedMatches
        ? { currency, amount: new Big(props.raisedMatches.toFixed(0)) }
        : undefined;

    return (
      <div ref={ref} className={className} css={verticalStackCss.xs}>
        {labelPosition === "top" && <Thermometer {...props} />}
        <DefinitionList>
          <div>
            <dt>
              <span>{progressLabel}</span>
            </dt>
            <dd css={[horizontalStackCss.xxs, { alignItems: "center" }]}>
              <div>
                {props.dataMissing
                  ? "-"
                  : displayCurrencyValueInUserLocale({
                      currencyValue: {
                        currency,
                        amount: new Big(totalAmount.toFixed(0)),
                      },
                    })}
              </div>
              <RaisedAmountTooltip
                raisedOffline={raisedOffline}
                raisedMatches={raisedMatches}
                monthlyAnnualTotal={monthlyAnnualTotal}
              />
            </dd>
          </div>
          <div css={{ textAlign: "right" }}>
            <dt>{goalLabel}</dt>
            <dd>
              {props.dataMissing
                ? "-"
                : displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency,
                      amount: new Big(props.goalAmount.toFixed(0)),
                    },
                  })}
            </dd>
          </div>
        </DefinitionList>
        {labelPosition === "bottom" && <Thermometer {...props} />}
      </div>
    );
  }
);

const DefinitionList = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column-reverse;
  }
  dt {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss.s};
  }
  dd {
    color: var(${colorCssVars.text.body});
    font-weight: ${FontWeight.BOLD};
    ${textSizeCss.s};
  }
`;
