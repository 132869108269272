import { Card } from "@components/Card";
import { ErrorMessage } from "@components/ErrorMessage";
import {
  donationFlowPaymentOptionToPaymentProcessRouteNameMap,
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
  donateRoutePathCorrectionMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import {
  createGuestUserIfNeeded,
  useGivelistRedirectAbTest,
} from "@components/donate/DonateV3/PaymentProcess/helpers";
import { CryptoV2Page } from "@components/donate/DonateV3/PaymentProcess/pages/CryptoV2";
import { CryptoV2Confirmation } from "@components/donate/DonateV3/PaymentProcess/pages/CryptoV2/CryptoV2Confirmation";
import { DAFV2Page } from "@components/donate/DonateV3/PaymentProcess/pages/DAFV2";
import { DonatePage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonationConfirmation } from "@components/donate/DonateV3/PaymentProcess/pages/DonationConfirmation";
import { DonationThankYou } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou";
import { GiftCard } from "@components/donate/DonateV3/PaymentProcess/pages/GiftCard";
import { ManualDAFPage } from "@components/donate/DonateV3/PaymentProcess/pages/ManualDAF";
import { ManualDAFInstructions } from "@components/donate/DonateV3/PaymentProcess/pages/ManualDAFInstructions";
import { DonationShareMatch } from "@components/donate/DonateV3/PaymentProcess/pages/ShareMatch";
import { StockPage } from "@components/donate/DonateV3/PaymentProcess/pages/Stocks";
import { StockInstructions } from "@components/donate/DonateV3/PaymentProcess/pages/Stocks/StockInstructions";
import {
  getValidCommentText,
  validateAmountAndFrequency,
  validateCryptoAmountAndCurrency,
  validateStockSymbolAndAmount,
  validateCommentText,
  validateUserEmail,
  validateFullNameText,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  CreateOrUpdateDonationResult,
  DonateModalAction,
  DonateFormType,
  DONATE_FORM_ERROR,
  PaymentProcessProps,
} from "@components/donate/DonateV3/types";
import { redirectToSuccessUrl } from "@components/donate/helpers";
import { fetchNotifications } from "@components/notifications/useNotifications";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import { currencyValueCodec } from "@every.org/common/src/codecs/currency";
import {
  EntityName,
  PersonalDonationChargeResponse,
  PersonalDonationResponse,
} from "@every.org/common/src/codecs/entities";
import { decodeOrThrow } from "@every.org/common/src/codecs/index";
import {
  IntegerFromString,
  coerceToSafeIntOrThrow,
} from "@every.org/common/src/codecs/number";
import { spacing } from "@every.org/common/src/display/spacing";
import {
  DOUBLE_DONATION_ERROR_NAME,
  GIFT_CARDS_NONPROFIT_ID,
} from "@every.org/common/src/entity/constants";
import {
  DonationFlowPaymentOption,
  Currency,
  DonationFrequency,
  NonprofitType,
  PaymentMethod,
  PaymentSourceType,
  NonprofitAdminStatus,
  PaymentRequestType,
} from "@every.org/common/src/entity/types";
import { GivelistRedirectTestVariant } from "@every.org/common/src/helpers/abtesting/GivelistRedirect";
import {
  ClientRouteName,
  DONATE_HASH,
  getRoutePath,
  LEGACY_DONATE_CRYPTO_HASH,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { currencyValueToMinimumDenominationAmount } from "@every.org/common/src/helpers/currency";
import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";
import { DonationMatchData } from "@every.org/common/src/routes/donate";

import { Loading } from "src/components/LoadingIndicator";
import { AbTestContext } from "src/context/AbTestContext";
import { updateAuthState } from "src/context/AuthContext/actions";
import {
  useABTestingId,
  useIsLoggedIn,
  useLoggedInOrGuestUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { isLoggedInUser } from "src/context/AuthContext/selectors";
import { createDonation } from "src/context/DonationsContext/actions";
import { updateMyDonation } from "src/context/MyDonationsContext/actions";
import { PaymentRequestReadyStatus } from "src/context/PaymentRequestContext";
import { TurnstileContext } from "src/context/TurnstileContext";
import { getMessageForError } from "src/errors";
import { ApiError } from "src/errors/ApiError";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useNonprofitAdminData } from "src/hooks/useNonprofitAdminData";
import {
  cssForMediaSize,
  MediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import { HttpStatus } from "src/utility/httpStatus";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const REDIRECT_LAST_DONATION_METHODS = ["CRYPTO", "STOCKS", "DAF", "PAYPAL"];

function Redirect({ to, replace = false }: { to: string; replace?: boolean }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace });
  });
  return null;
}

/**
 * This modal functions as a wrapper around the various pages of the donation
 * flow. The displayed page is controlled by the url path.
 */

const StyledCard = styled(Card)`
  border-radius: 0;
  ${cssForMediaSize({
    min: MediaSize.MEDIUM_LARGE,
    css: css`
      display: flex;
      align-items: stretch;
      border-radius: 16px;
      overflow: hidden;
      padding: 0; ;
    `,
  })}
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      border-radius: 16px;
    `,
  })}
`;

const PaymentProcess = (props: PaymentProcessProps) => {
  const { form, formContext, nonprofit, fundraiser, ...rest } = props;

  const isLargeScreen =
    // eslint-disable-next-line no-restricted-syntax
    useMatchesScreenSize({ min: MediaSize.LARGE }) ?? false;

  const router = useEdoRouter();
  const isLoggedIn = useIsLoggedIn();
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();
  const abTestingId = useABTestingId();
  const abTestContext = useContext(AbTestContext);

  const {
    successUrl,
    redirectUrl,
    createOrUpdateDonationResult,
    setCreateOrUpdateDonationResult,
    paymentFlowOptions,
  } = formContext;

  const givelistRedirectTestVariant = useGivelistRedirectAbTest({
    successUrl,
  });

  const isThankYouPage = useMatch(
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
  );

  const nonprofitAdminData = useNonprofitAdminData(nonprofit.id);
  const isNonprofitAdmin = useMemo(
    () => nonprofitAdminData?.status === NonprofitAdminStatus.CONFIRMED,
    [nonprofitAdminData]
  );

  /**
   * Handles donations that are confirmed (i.e. the donor has "paid"). This
   * specifically waits for things like the crypto and paypal modals to indicate
   * some degree of success, as in those cases creating a donation alone doesn't
   * mean that the donor has completed the donation.
   */
  const handleConfirmedDonation = useCallback(
    (result: {
      donation: PersonalDonationResponse;
      donationCharge?: PersonalDonationChargeResponse;
      // DEPRECATED
      match?: DonationMatchData;
    }) => {
      if (isLoggedInUser(loggedInOrGuestUser)) {
        fetchNotifications();
      }
      if (formContext.giftCardPurchaseInfo) {
        router.push(
          getRoutePath({
            name: ClientRouteName.GIFT_CARD_ORDER,
            tokens: { id: result.donation.id },
            format: URLFormat.RELATIVE,
          })
        );
        return false;
      }
      // Update the last donation to the one used as it is only obtained through
      // /me
      if (
        rest.donateAction === DonateModalAction.DONATE &&
        loggedInOrGuestUser
      ) {
        updateAuthState(
          loggedInOrGuestUser,
          result.donation,
          result.donation.toNonprofitId !== GIFT_CARDS_NONPROFIT_ID &&
            !nonprofit.metadata?.requireDonorInfo
            ? result.donation.shareInfo
            : loggedInOrGuestUser.lastDonationShareInfo,
          result.donation.toNonprofitId !== GIFT_CARDS_NONPROFIT_ID
            ? result.donation.visibility
            : undefined,
          formContext.amountBig.gt(formContext.creditAmount)
            ? loggedInOrGuestUser.paidDonationCount + 1
            : undefined
        );
      }
      if (successUrl) {
        if (
          redirectToSuccessUrl({
            successUrl,
            router,
            isLoggedIn,
            givelistRedirectTestVariant,
          })
        ) {
          return true;
        }
      }
      return false;
    },
    [
      givelistRedirectTestVariant,
      successUrl,
      router,
      formContext.amountBig,
      formContext.creditAmount,
      formContext.giftCardPurchaseInfo,
      rest.donateAction,
      loggedInOrGuestUser,
      nonprofit.metadata,
      isLoggedIn,
    ]
  );

  const turnstileContext = useContext(TurnstileContext);

  const submitDonation = useCallback(
    async (
      formValues: DonateFormType
    ): Promise<CreateOrUpdateDonationResult | undefined> => {
      const {
        paymentMethod,
        paymentSourceType,
        amount,
        frequency,
        paymentSource,
        shareInfo,
        shareInfoWithFundraiserCreator,
        partnerDonationId,
        partnerWebhookToken,
        partnerMetadata,
        currency,
        payerName,
        payerEmail,
        paymentRequestPaymentMethodId,
        firstName,
        lastName,
        email,
        toNonprofits,
        toNonprofitWeights,
        toNonprofitMatchings,
        recurringMatches,
        optInToMailingList,
        amountToEveryOrg,
        paymentTab,
        cryptoCurrency,
        cryptoPledgeAmount,
        stockSymbol,
        stockAmount,
        isPublic,
        designation,
        includePrivateNote,
        includeCommentText,
        // Stock metadata
        brokeragePull,
        brokerageFirm,
        brokerageAccountNumber,
        brokerContactName,
        brokerEmailAddress,
        brokerPhoneNumber,
        signature,
        isDoubleDonation,
        givingCreditToRedeem,
        suggestedTipPercent,
        actualTipPercent,
      } = formValues;
      const {
        nonce,
        amountBig,
        creditAmount,
        donationToJoinId,
        DEPRECATED_matchCampaign: matchCampaign,
        fromFundraiserId,
        shorten,
        minValue,
        maxValue,
        searchMeta,
      } = formContext;

      const privateNote = includePrivateNote
        ? getValidCommentText(formValues.privateNote)
        : undefined;
      const commentText = includeCommentText
        ? getValidCommentText(formValues.commentText)
        : undefined;
      if (!nonce) {
        logger.error({ message: "Couldn't generate donation nonce." });
        form.setError(DONATE_FORM_ERROR, {
          type: "string",
          message:
            "Something went wrong. Please refresh the page and try again.",
        });
        return undefined;
      }

      if (
        !isLoggedIn &&
        !Object.values(PaymentRequestType).includes(
          paymentTab as PaymentRequestType
        ) &&
        !(
          validateFullNameText(
            firstName.trim(),
            "firstName",
            "First name",
            form.setError
          ) &&
          validateFullNameText(
            lastName.trim(),
            "lastName",
            "Last name",
            form.setError
          ) &&
          validateUserEmail(email, form.setError)
        )
      ) {
        return;
      }

      switch (paymentMethod) {
        case PaymentMethod.CRYPTO:
          if (
            !validateCryptoAmountAndCurrency({
              currency: cryptoCurrency,
              amount: cryptoPledgeAmount?.toString() || "",
              shorten,
              setError: form.setError,
            }) ||
            !validateCommentText(
              privateNote,
              form.setError,
              "privateNote",
              nonprofit.metadata?.privateNoteLimit
            )
          ) {
            return;
          }
          break;
        case PaymentMethod.STOCKS:
          if (
            !validateStockSymbolAndAmount({
              stockSymbol,
              stockAmount,
              setError: form.setError,
            })
          ) {
            return;
          }
          break;
        default:
          if (
            !validateAmountAndFrequency({
              frequency,
              amount: amount?.toString() || "",
              minValue,
              maxValue,
              shorten,
              setError: form.setError,
            }) ||
            !validateCommentText(
              privateNote,
              form.setError,
              "privateNote",
              nonprofit.metadata?.privateNoteLimit
            ) ||
            !validateCommentText(commentText, form.setError, "commentText")
          ) {
            return;
          }
          break;
      }

      try {
        //#region UPDATE DONATION
        if (rest.donateAction === DonateModalAction.UPDATE) {
          if (paymentMethod !== PaymentMethod.STRIPE) {
            logger.error({
              message:
                "Attempting to update donation with a non-stripe payment method. This is not currently allowed.",
            });
            return undefined;
          }
          // TODO: remove this *if* once we enable updating paypal donations and showing the user the approval link
          if (paymentSource?.paymentMethod === PaymentMethod.PAYPAL) {
            logger.error({
              message:
                "Attempting to update donation using PayPal. This is not currently allowed.",
            });
            return undefined;
          }
          if (
            !paymentSource ||
            ![
              EntityName.PAYMENT_SOURCE,
              EntityName.PAYPAL_PAYMENT_SOURCE,
            ].includes(paymentSource.entityName) ||
            // TODO: enable updating paypal recurring donations, but doing so requires returning
            // a link from the update route and showing it to the user to go and approve the change
            // ![PaymentMethod.STRIPE, PaymentMethod.PAYPAL].includes(
            //   selectedPaymentSource.paymentMethod
            // )
            paymentSource.paymentMethod !== PaymentMethod.STRIPE
          ) {
            return undefined;
          }
          const result = await updateMyDonation({
            donationId: rest.editDonationId,
            currencyValue: decodeOrThrow(currencyValueCodec, {
              amount: amountBig,
              currency: Currency.USD,
            }),
            frequency:
              frequency !== DonationFrequency.ONCE ? frequency : undefined,
            externalPaymentSourceId: paymentSource?.externalId || undefined,
            shareInfo: !!shareInfo,
            tipAmount: amountToEveryOrg
              ? coerceToSafeIntOrThrow({
                  num: currencyValueToMinimumDenominationAmount({
                    value: { amount: new Big(amountToEveryOrg), currency },
                  }),
                })
              : undefined,
          });
          if (rest.onUpdateComplete) {
            await rest.onUpdateComplete(result);
          }
          setCreateOrUpdateDonationResult({ donation: result });
          return { donation: result };
        }
        //#endregion

        let cfTurnstileToken = turnstileContext.get("turnstile");
        // Note this is new and different between the original donation process
        // and donatev3, because the original donation process had an explicit
        // step for collecting donor info whereas v3 combines everything into
        // one step
        await createGuestUserIfNeeded({
          router,
          abTestingId,
          loggedInOrGuestUser,
          firstName,
          lastName,
          email,
          cfTurnstileToken,
        });

        const newIsPaypal = paymentMethod === PaymentMethod.PAYPAL;
        //   const {  matchCampaign, } = rest;
        cfTurnstileToken = turnstileContext.get("turnstile");

        const manualDafMetadata =
          paymentMethod === PaymentMethod.DAF &&
          // for daf chariot paymentSourceType = PaymentSourceType.CHARIOT
          paymentSourceType === undefined
            ? {
                pledgedAmount: amountBig,

                pledgedTipAmount: amountToEveryOrg
                  ? new Big(amountToEveryOrg)
                  : undefined,
              }
            : {};

        const cryptoMetadata =
          paymentMethod === PaymentMethod.CRYPTO
            ? {
                usdValueAtPledge:
                  formContext.cryptoTokenRate &&
                  formContext.cryptoTokenRate?.rate > 0
                    ? new Big(formContext.cryptoTokenRate?.rate)
                    : undefined,
                pledgedCryptoValue:
                  cryptoCurrency && cryptoPledgeAmount
                    ? {
                        amount: new Big(cryptoPledgeAmount),
                        currency: cryptoCurrency,
                      }
                    : undefined,
                pledgedTipQuantity:
                  cryptoCurrency && amountToEveryOrg
                    ? {
                        amount: new Big(amountToEveryOrg),
                        currency: cryptoCurrency,
                      }
                    : undefined,
              }
            : {};

        const stockMetadata =
          paymentMethod === PaymentMethod.STOCKS
            ? {
                pledgedEquityValue:
                  stockSymbol && stockAmount
                    ? {
                        symbol: stockSymbol,
                        amount: stockAmount,
                      }
                    : undefined,
                pledgedTipQuantity:
                  stockSymbol && amountToEveryOrg
                    ? {
                        amount: parseFloat(amountToEveryOrg),
                        symbol: stockSymbol,
                      }
                    : undefined,
                // Stocks info:
                brokerage: brokeragePull
                  ? removeUndefinedValues({
                      firstName,
                      lastName,
                      email,
                      stockSymbol,
                      stockAmount,
                      brokeragePull,
                      brokerageFirm,
                      brokerageAccountNumber,
                      brokerContactName,
                      brokerEmailAddress,
                      brokerPhoneNumber,
                      signature,
                    })
                  : undefined,
              }
            : {};

        const result = await createDonation({
          paymentTab,
          ...// Handle crypto donation
          (paymentMethod === PaymentMethod.CRYPTO
            ? {
                paymentMethod: PaymentMethod.CRYPTO,
                value: undefined,
                frequency: DonationFrequency.ONCE,
                creditAmount: undefined,
                // pledgedCryptoValue is added to metadata down below
              }
            : // Handle daf donation
            paymentMethod === PaymentMethod.DAF
            ? {
                paymentMethod: PaymentMethod.DAF,
                paymentSourceType,
                frequency: DonationFrequency.ONCE,
                creditAmount: coerceToSafeIntOrThrow({
                  num: 0,
                }),
                value: decodeOrThrow(currencyValueCodec, {
                  amount: amountBig,
                  currency: Currency.USD,
                }),
              }
            : // Handle paypal donation
            newIsPaypal
            ? {
                paymentMethod: PaymentMethod.PAYPAL,
                value: decodeOrThrow(currencyValueCodec, {
                  amount: amountBig,
                  // Why does currency need to be hard-coded for PayPal?
                  currency: Currency.USD,
                }),
                creditAmount: coerceToSafeIntOrThrow({
                  num: currencyValueToMinimumDenominationAmount({
                    value: {
                      amount: creditAmount,
                      // Why does currency need to be hard-coded for PayPal?
                      currency: Currency.USD,
                    },
                  }),
                }),
                frequency,
              }
            : paymentMethod === PaymentMethod.STOCKS
            ? {
                paymentMethod: PaymentMethod.STOCKS,
                value: undefined,
                frequency: DonationFrequency.ONCE,
                creditAmount: undefined,
              }
            : // Handle normal donations
              {
                // TODO eventually we'll need to be able to handle other types
                // of donations
                paymentMethod: PaymentMethod.STRIPE,
                value: decodeOrThrow(currencyValueCodec, {
                  amount: amountBig,
                  currency,
                }),
                creditAmount: coerceToSafeIntOrThrow({
                  num: currencyValueToMinimumDenominationAmount({
                    value: {
                      amount: creditAmount,
                      currency,
                    },
                  }),
                }),
                frequency: frequency || DonationFrequency.ONCE,
                paymentSourceId: paymentSource?.id || undefined,
              }),
          toNonprofitId: nonprofit.id,
          donationToJoinId,
          nonce,
          externalPaymentSourceId:
            paymentRequestPaymentMethodId ||
            paymentSource?.externalId ||
            undefined,
          partnerDonationId,
          partnerWebhook: partnerWebhookToken
            ? { token: partnerWebhookToken }
            : undefined,
          shareInfo,
          isPublic,
          matchCampaign,
          fromFundraiserId,
          giftCardPurchaseInfo: formContext.giftCardPurchaseInfo,
          privateNote,
          commentText,
          designation,
          metadata: removeUndefinedValues({
            payToNonprofits: toNonprofits,
            payToNonprofitWeights: toNonprofitWeights,
            payToNonprofitMatchings: toNonprofitMatchings,
            recurringMatches: recurringMatches as unknown as IntegerFromString,
            optInToMailingList,
            shareInfoWithFundraiserCreator,
            version: "V3",
            pledgedEquityValue:
              stockSymbol && stockAmount
                ? {
                    symbol: stockSymbol,
                    amount: stockAmount,
                  }
                : undefined,
            searchMeta,
            // Stocks info:
            brokerage:
              paymentMethod === PaymentMethod.STOCKS && brokeragePull
                ? removeUndefinedValues({
                    firstName,
                    lastName,
                    email,
                    stockSymbol,
                    stockAmount,
                    brokeragePull,
                    brokerageFirm,
                    brokerageAccountNumber,
                    brokerContactName,
                    brokerEmailAddress,
                    brokerPhoneNumber,
                    signature,
                  })
                : undefined,
            partnerMetadata,
            ...manualDafMetadata,
            ...cryptoMetadata,
            ...stockMetadata,
            abTests: Array.from(abTestContext.exposures),
            suggestedTipPercent,
            actualTipPercent,
          }),
          ...(!isLoggedIn ? { payerName, payerEmail } : {}),
          // TODO #9620: Default to the only parent fiscal sponsor for projects with a single sponsor
          ...(nonprofit.type === NonprofitType.PROJECT &&
          nonprofit.eligibleDonationRecipientNonprofitIds?.length === 1
            ? {
                payToNonprofitId:
                  nonprofit.eligibleDonationRecipientNonprofitIds[0],
              }
            : {}),
          tipAmount:
            paymentMethod !== PaymentMethod.CRYPTO &&
            paymentMethod !== PaymentMethod.STOCKS &&
            amountToEveryOrg
              ? coerceToSafeIntOrThrow({
                  num: currencyValueToMinimumDenominationAmount({
                    value: { amount: new Big(amountToEveryOrg), currency },
                  }),
                })
              : undefined,
          cfTurnstileToken,
          allowDoubleDonations: isDoubleDonation,
          givingCreditToRedeem: givingCreditToRedeem?.token,
        });

        if (
          [PaymentMethod.PAYPAL, PaymentMethod.STRIPE].includes(
            paymentMethod
          ) &&
          !frequency
        ) {
          logger.error({
            message:
              "Unexpected missing frequency. The donation was made with 'one-time' frequency",
            data: { donationId: result.donation.id, paymentMethod },
          });
        }

        setCreateOrUpdateDonationResult(result);
        return result;
      } catch (e) {
        if (e instanceof ApiError) {
          if (e.httpStatus === HttpStatus.CONFLICT) {
            form.setError(DONATE_FORM_ERROR, {
              type: "string",
              message:
                "Looks like this donation may have already been completed. Please check your profile.",
            });
            return undefined;
          } else if (e.httpStatus === HttpStatus.BAD_REQUEST) {
            // Refresh the nonce so user can fix the request.
            await formContext.refreshNonce();
          }
        }
        if (e instanceof Error) {
          if (e["data"]?.data?.name === DOUBLE_DONATION_ERROR_NAME) {
            form.setValue("isDoubleDonation", true);
            form.setValue("doubleDonationMessage", getMessageForError(e));
          } else {
            logger.error({
              message: "Unknown error occurred during donation",
              error: e,
            });
            form.setError(DONATE_FORM_ERROR, {
              type: "string",
              message: getMessageForError(e),
            });
          }
        }
        return undefined;
      }
    },
    [
      router,
      form,
      formContext,
      isLoggedIn,
      loggedInOrGuestUser,
      nonprofit,
      rest,
      setCreateOrUpdateDonationResult,
      abTestingId,
      turnstileContext,
      abTestContext,
    ]
  );

  const { paymentSources, defaultPaymentSource } = formContext;

  const isPaymentRequestAvailable =
    paymentFlowOptions.has(DonationFlowPaymentOption.PAYMENT_REQUEST) &&
    formContext.paymentRequestInitializer.readyStatus ===
      PaymentRequestReadyStatus.READY;

  const defaultPaymentFlow: DonationFlowPaymentOption =
    loggedInOrGuestUser?.lastDonation &&
    REDIRECT_LAST_DONATION_METHODS.includes(
      loggedInOrGuestUser.lastDonation.paymentMethod
    )
      ? DonationFlowPaymentOption[
          loggedInOrGuestUser.lastDonation.paymentMethod
        ]
      : defaultPaymentSource
      ? defaultPaymentSource.type === PaymentSourceType.CARD &&
        loggedInOrGuestUser &&
        loggedInOrGuestUser.lastDonation !== null &&
        loggedInOrGuestUser.lastDonation.externalPaymentSourceId?.startsWith(
          "pm_"
        )
        ? DonationFlowPaymentOption.PAYMENT_REQUEST
        : defaultPaymentSource.type === PaymentSourceType.ACH_DEBIT ||
          (loggedInOrGuestUser &&
            loggedInOrGuestUser.lastDonation !== null &&
            loggedInOrGuestUser.lastDonation.externalPaymentSourceId?.startsWith(
              "ba_"
            ))
        ? DonationFlowPaymentOption.BANK
        : DonationFlowPaymentOption.CREDIT_CARD
      : isPaymentRequestAvailable && !isLargeScreen
      ? DonationFlowPaymentOption.PAYMENT_REQUEST
      : DonationFlowPaymentOption.CREDIT_CARD;

  const location = useLocation();

  if (paymentFlowOptions.size < 1) {
    return (
      <StyledCard css={{ height: "100%", alignItems: "center" }}>
        <ErrorMessage css={{ padding: spacing.m }}>
          Our site is currently experiencing some issues with these payment
          methods, please try again later
        </ErrorMessage>
      </StyledCard>
    );
  }

  // Select either the defaultPaymentFlow or the first allowed paymentFlowOption
  // if the default isn't currently allowed.
  const defaultRedirectRouteName =
    donationFlowPaymentOptionToPaymentProcessRouteNameMap[
      paymentFlowOptions.has(defaultPaymentFlow) && !isNonprofitAdmin
        ? defaultPaymentFlow
        : [...paymentFlowOptions.values()][0]
    ];

  const defaultRedirectRoutePath = `${paymentProcessRouteNameToPathMap[defaultRedirectRouteName]}${location.search}`;

  const showLoading = !paymentSources;

  if (showLoading) {
    return (
      <StyledCard css={{ height: "100%", alignItems: "center" }}>
        <Loading />
      </StyledCard>
    );
  }

  const cardCss =
    isThankYouPage &&
    cssForMediaSize({ max: MediaSize.MEDIUM_LARGE, css: { paddingTop: 0 } });

  return (
    <StyledCard css={cardCss}>
      <Routes>
        <Route
          path={LEGACY_DONATE_CRYPTO_HASH}
          element={<RedirectLegacyCryptoHash />}
        />
        <Route
          path={DONATE_HASH}
          element={<Redirect to={defaultRedirectRoutePath} replace />}
        />
        <Route path="/donate">
          {paymentFlowOptions.has(DonationFlowPaymentOption.CREDIT_CARD) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CREDIT_CARD
                  ]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={formContext}
                    paymentOption={DonationFlowPaymentOption.CREDIT_CARD}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_CARD
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.CREDIT_CARD}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.BANK) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[PaymentProcessRouteName.BANK]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={formContext}
                    paymentOption={DonationFlowPaymentOption.BANK}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_BANK
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.BANK}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.PAYPAL) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.PAYPAL
                  ]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={formContext}
                    paymentOption={DonationFlowPaymentOption.PAYPAL}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_PAYPAL
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.PAYPAL}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.VENMO) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.VENMO
                  ]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={formContext}
                    paymentOption={DonationFlowPaymentOption.VENMO}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_VENMO
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.VENMO}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {
            <Route
              path={
                paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.GIFT_CARD
                ]
              }
              element={
                <GiftCard
                  nonprofit={nonprofit}
                  form={form}
                  formContext={formContext}
                />
              }
            />
          }
          {paymentFlowOptions.has(
            DonationFlowPaymentOption.PAYMENT_REQUEST
          ) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.APPLE_PAY
                  ]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={formContext}
                    paymentOption={DonationFlowPaymentOption.PAYMENT_REQUEST}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_APPLE_PAY
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.PAYMENT_REQUEST}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.CRYPTO) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CRYPTO
                  ]
                }
                element={
                  <CryptoV2Page
                    form={form}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    formContext={formContext}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_CRYPTO
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.CRYPTO}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CRYPTO_COMPLETE
                  ]
                }
                element={
                  <CryptoV2Confirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    paymentOption={DonationFlowPaymentOption.CRYPTO}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.STOCKS) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.STOCKS
                  ]
                }
                element={
                  <StockPage
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    submitDonation={submitDonation}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.STOCKS_CONFIRM
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.STOCKS}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.STOCKS_INSTRUCTIONS
                  ]
                }
                element={
                  <StockInstructions
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          {paymentFlowOptions.has(DonationFlowPaymentOption.DAF) && (
            <React.Fragment>
              <Route
                path={
                  paymentProcessRouteNameToPathMap[PaymentProcessRouteName.DAF]
                }
                element={
                  <DAFV2Page
                    nonprofit={nonprofit}
                    formContext={formContext}
                    form={form}
                    submitDonation={submitDonation}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.DAF_MANUAL
                  ]
                }
                element={
                  <ManualDAFPage
                    nonprofit={nonprofit}
                    formContext={formContext}
                    fundraiser={fundraiser}
                    form={form}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.DAF_MANUAL_CONFIRM
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.DAF}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                    isManualDaf
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.DAF_MANUAL_INSTRUCTIONS
                  ]
                }
                element={
                  <ManualDAFInstructions
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    formContext={formContext}
                    form={form}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                    handleConfirmedDonation={handleConfirmedDonation}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.DAF_CHARIOT
                  ]
                }
                element={
                  <DonatePage
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    form={form}
                    formContext={{
                      ...formContext,
                      paymentSourceType: PaymentSourceType.CHARIOT,
                      minValue: { currency: Currency.USD, amount: new Big(50) },
                    }}
                    paymentOption={DonationFlowPaymentOption.DAF}
                  />
                }
              />
              <Route
                path={
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.CONFIRM_DAF_CHARIOT
                  ]
                }
                element={
                  <DonationConfirmation
                    form={form}
                    formContext={formContext}
                    nonprofit={nonprofit}
                    fundraiser={fundraiser}
                    paymentOption={DonationFlowPaymentOption.DAF}
                    submitDonation={submitDonation}
                    handleConfirmedDonation={handleConfirmedDonation}
                    createOrUpdateDonationResult={createOrUpdateDonationResult}
                  />
                }
              />
            </React.Fragment>
          )}
          <Route
            path={
              paymentProcessRouteNameToPathMap[
                PaymentProcessRouteName.THANK_YOU
              ]
            }
            element={
              <DonationThankYou
                donateModalProps={{ ...props, redirectUrl }}
                form={form}
                formContext={formContext}
                goToGivelistUrl={
                  (givelistRedirectTestVariant ===
                    GivelistRedirectTestVariant.BEFORE &&
                    successUrl) ||
                  null
                }
                createOrUpdateDonationResult={createOrUpdateDonationResult}
              />
            }
          />
          <Route
            path={
              paymentProcessRouteNameToPathMap[
                PaymentProcessRouteName.THANK_YOU
              ]
            }
            element={
              <DonationShareMatch
                donateModalProps={props}
                formContext={formContext}
                createOrUpdateDonationResult={createOrUpdateDonationResult}
              />
            }
          />
        </Route>
        <Route path="*" element={<WarnOrRedirect />} />
      </Routes>
    </StyledCard>
  );
};

function RedirectLegacyCryptoHash() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.set("method", DonationFlowPaymentOption.CRYPTO);
  const path = `${
    paymentProcessRouteNameToPathMap[PaymentProcessRouteName.CRYPTO]
  }?${params.toString()}`;

  return <Redirect to={path} replace />;
}

function WarnOrRedirect() {
  const location = useLocation();
  const correctPath = donateRoutePathCorrectionMap.get(location.pathname);

  if (correctPath) {
    return <Redirect to={`${correctPath}${location.search}`} replace />;
  }

  return <WarnOnUnknownRoute />;
}

/**
 * Note, because we won't even render a route-type if it's not allowed (because
 * we check paymentFlowOptions before rendering the route), this will get
 * triggered if we attempt to render a valid route that isn't allowed. The bare
 * /donate route is expected to handle properly redirecting to an allowed
 * payment option in that case.
 */
function WarnOnUnknownRoute() {
  const location = useLocation();
  const window = getWindow();
  if (!window?.location.hash) {
    return null;
  }

  logger.error({
    message: "Reached unknown donation route",
    data: { pathname: location.pathname, fullURL: window?.location.href },
  });

  return <Redirect to={`${DONATE_HASH}${location.search}`} replace />;
}

export default PaymentProcess;
