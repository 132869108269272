import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { DonationCard } from "@components/DonationCard";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import {
  createShareLink,
  shouldUseNativeShare,
  XButtonElem,
} from "@components/ShareButton";
import { SocialIdpButton } from "@components/SocialLoginButton";
import { TestimonialPromt } from "@components/TestimonialPromt";
import {
  CreateOrUpdateDonationResult,
  DonateModalAction,
  DonateModalProps,
} from "@components/donate/DonateV3/types";
import { useShareData } from "@components/feed/DonationShareButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";
import {
  GuestUserResponse,
  NonEmptyMatchingCampaignResponse,
  PersonalDonationChargeResponse,
  PersonalDonationResponse,
  PersonalUserResponse,
} from "@every.org/common/src/codecs/entities";
import { commentTextCodec } from "@every.org/common/src/codecs/text";
import { spacing } from "@every.org/common/src/display/spacing";
import { Currency, ShareMedium } from "@every.org/common/src/entity/types";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";
import { trackShareRouteSpec } from "@every.org/common/src/routes/donation";

import {
  getSaveCommentCallback,
  SaveTextActionResult,
  SaveTextActionStatus,
  useEditableText,
} from "src/hooks/useEditableText";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";
import { useStatSigLayer } from "src/utility/abtesting";
import { trackShareButtonClick } from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const DEFAULT_PROMPT_TEXT =
  "Now\u00A0**share why you donated**\u00A0and turn this moment into a movement\u00A0🌟";

/**
 * Track when a share button is clicked after a donation is completed.
 */
function trackPostDonationShareClick({
  shareMedium,
  donationId,
}: {
  shareMedium: ShareMedium;
  donationId: PersonalDonationResponse["id"];
}) {
  queryApi(trackShareRouteSpec, {
    routeTokens: { id: donationId },
    body: { shareMedium },
    queryParams: {},
  });
}

const Confirmation = styled.div`
  ${verticalStackCss.l}
`;

const StyledDonationCard = styled(DonationCard)`
  box-shadow: 0px 8px 32px -8px rgba(46, 52, 52, 0.2);

  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      max-width: unset;
      margin: unset;
    `,
  })}
`;

const Buttons = styled.div`
  ${verticalStackCss.xs};
  > button {
    width: 100%;
  }
`;
const CardAndButtonsConatainer = styled.div`
  ${verticalStackCss.xxl}

  --parentPadding: ${spacing.l};

  ${cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      max-width: unset;
      margin: unset;
      position: relative;
      width: calc(100% + var(--parentPadding) * 2);
      left: calc(var(--parentPadding) * -1);
      padding: var(--parentPadding) 0;
      background: var(${colorCssVars.background.faded});

      ${Buttons} {
        margin: 0 var(--parentPadding);
      }

      ${StyledDonationCard} {
        margin: 0 calc(var(--parentPadding) - ${spacing.s});
      }
    `,
  })}
`;

export interface AddCommentStepProps {
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  nonprofit: DonateModalProps["nonprofit"];
  fundraiser: DonateModalProps["fundraiser"];
  defaultComment: DonateModalProps["defaultComment"];
  donateAction: DonateModalProps["donateAction"];
  user?: PersonalUserResponse | GuestUserResponse;
  shorten: boolean;
  postDonationAction: () => void;
  goToGivelistUrl: string | null;
  matchingCampaign?: NonEmptyMatchingCampaignResponse | undefined;
}

export const AddCommentStep = ({
  createOrUpdateDonationResult,
  nonprofit,
  fundraiser,
  defaultComment,
  donateAction,
  user,
  shorten,
  postDonationAction,
  goToGivelistUrl,
  matchingCampaign,
}: AddCommentStepProps) => {
  function saveComment(
    toSaveCommentText: string | null
  ): Promise<SaveTextActionResult> {
    if (!createOrUpdateDonationResult) {
      return Promise.resolve({
        status: SaveTextActionStatus.ERROR,
        errorMessage: "Something went wrong, please try again.",
      });
    }
    return getSaveCommentCallback(
      createOrUpdateDonationResult.donation.id,
      createOrUpdateDonationResult.donationCharge
    )(toSaveCommentText);
  }

  // Comment text
  const maxUserMatch = matchingCampaign?.maxPerUserMatchAmount
    ? displayCurrencyValueInUserLocale({
        minDenomCurrencyValue: {
          amountInMinDenom: matchingCampaign?.maxPerUserMatchAmount,
          currency: matchingCampaign?.currency || Currency.USD,
        },
      })
    : null;
  const shareMatchComment = maxUserMatch
    ? `Join me and help ${matchingCampaign?.title} - double your donation up to ${maxUserMatch} here:`
    : undefined;
  const shareMatchPostscript =
    shareMatchComment && commentTextCodec.is(shareMatchComment)
      ? shareMatchComment
      : undefined;
  const commentInterface = useEditableText({
    saveAction: saveComment,
    logTag: "DonateModal-Comment",
    initialText: defaultComment,
  });
  const { hasTextChanged, saveText } = commentInterface;

  const ShareButtons: React.FCC<{
    donation: PersonalDonationChargeResponse;
  }> = ({ donation }) => {
    const commentText = commentTextCodec.is(commentInterface.text)
      ? commentInterface.text
      : null;

    const shareData = useShareData(
      {
        ...donation.donation,
        commentText,
      },
      { postscript: shareMatchPostscript }
    );

    const window = getWindow();
    const useNativeShare = window && shouldUseNativeShare(window.navigator);
    const shareLink = createShareLink(ShareMedium.LINK, shareData);

    if (window && useNativeShare) {
      return (
        <Button
          data-tname="nativeShareButton"
          role={ButtonRole.PRIMARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: async () => {
              // Await before leaving the site to another app to share
              await Promise.all([
                queryApi(trackShareRouteSpec, {
                  routeTokens: { id: donation.donation.id },
                  body: { shareMedium: ShareMedium.NATIVE },
                  queryParams: {},
                }),
                saveText(),
              ]);
              try {
                await window.navigator.share(
                  typeof shareData === "function"
                    ? shareData(ShareMedium.NATIVE)
                    : shareData
                );
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                if (error && "name" in error && error.name !== "AbortError") {
                  logger.warn({
                    message: "Error sharing native donation",
                    data: { shareError: error },
                  });
                }
              }
            },
          }}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/ShareIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          }
        >
          Share
        </Button>
      );
    }

    return (
      <React.Fragment>
        <SocialIdpButton
          idp={IdentityProvider.FACEBOOK}
          data-tname="shareFacebookButton"
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: async () => {
              const shareLink = createShareLink(
                ShareMedium.FACEBOOK,
                shareData
              );
              trackShareButtonClick({
                medium: ShareMedium.FACEBOOK,
                shareData,
              });
              trackPostDonationShareClick({
                donationId: donation.donation.id,
                shareMedium: ShareMedium.FACEBOOK,
              });
              window?.open(shareLink, "shareFacebook");
              await saveText();
            },
          }}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/FacebookIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          }
        >
          Share&nbsp;on&nbsp;Facebook
        </SocialIdpButton>
        <XButtonElem
          data-tname="shareXButton"
          role={ButtonRole.SECONDARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: async () => {
              const shareLink = createShareLink(ShareMedium.TWITTER, shareData);
              trackShareButtonClick({ medium: ShareMedium.TWITTER, shareData });
              trackPostDonationShareClick({
                donationId: donation.donation.id,
                shareMedium: ShareMedium.TWITTER,
              });
              window?.open(shareLink, "shareX");
              await saveText();
            },
          }}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/XIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          }
        >
          Post&nbsp;on&nbsp;X
        </XButtonElem>
        <CopyToClipboardButton
          data-tname="shareLinkButton"
          textToCopy={shareLink}
          role={ButtonRole.PRIMARY}
          onCopied={async () => {
            trackShareButtonClick({ medium: ShareMedium.LINK, shareData });
            trackPostDonationShareClick({
              donationId: donation.donation.id,
              shareMedium: ShareMedium.LINK,
            });
            await saveText();
          }}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/LinkIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          }
        />
      </React.Fragment>
    );
  };

  const promptText =
    useStatSigLayer(
      "testimonial_sharing",
      !createOrUpdateDonationResult || !user
    )?.get<string>("last_step") ?? DEFAULT_PROMPT_TEXT;

  if (!createOrUpdateDonationResult || !user) {
    return null;
  }

  return (
    <Confirmation>
      <TestimonialPromt string={promptText} nonprofitName={nonprofit.name} />
      <CardAndButtonsConatainer css={verticalStackCss.xxl}>
        <StyledDonationCard
          createdAt={null}
          isPending={false}
          frequency={createOrUpdateDonationResult.donation.frequency}
          donationPageLinkInfo={null}
          nonprofit={nonprofit}
          fromFundraiserId={fundraiser?.id}
          useNonprofitChip
          name={getUserFullNameOrPlaceholder(user)}
          username={user.username}
          hideNonprofitDescription
          profileImageCloudinaryId={user.profileImageCloudinaryId}
          commentEditDisabled={false}
          commentInterface={commentInterface}
          initiallyEditingComment
          footer={null}
          // this may be somewhat confusing for visually impaired users, but
          // there should be sufficient context and it has a significant
          // impact on whether or not users leave comments.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!shorten}
          saveOnBlur
        />
        <Buttons>
          {createOrUpdateDonationResult?.donationCharge && (
            <ShareButtons
              donation={createOrUpdateDonationResult.donationCharge}
            />
          )}
          {goToGivelistUrl && (
            <Button
              data-tname="goToGivelistButton"
              onClick={{
                kind: ButtonTargetKind.LINK,
                to: goToGivelistUrl,
              }}
              role={ButtonRole.TEXT_ONLY}
            >
              Return to giveli.st
            </Button>
          )}
          <Button
            data-tname={
              donateAction === DonateModalAction.UPDATE
                ? "updatedDonation-finish"
                : "donationCompleted-skipShare"
            }
            role={
              donateAction === DonateModalAction.UPDATE
                ? ButtonRole.PRIMARY
                : ButtonRole.TEXT_SECONDARY
            }
            css={
              !goToGivelistUrl &&
              css`
                padding: ${spacing.m} 0 0;
              `
            }
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: async () => {
                if (hasTextChanged) {
                  await saveText();
                }
                postDonationAction();
              },
            }}
          >
            {donateAction === DonateModalAction.UPDATE
              ? "Finish"
              : "Skip for now"}
          </Button>
        </Buttons>
      </CardAndButtonsConatainer>
    </Confirmation>
  );
};
