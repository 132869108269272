import {
  FeedItemResponse,
  FundraiserRaisedResponse,
  NonprofitResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import {
  FundraiserResponse,
  getFundraiserFeedRouteSpec,
  getFundraiserRaisedRouteSpec,
  getFundraiserRouteSpec,
} from "@every.org/common/src/routes/fundraiser";
import { StaticApiRouteSpec } from "@every.org/common/src/routes/index";

import { serversideStaticQueryApi } from "src/utility/apiClient";

type getFundraiserPageInitialPropsType = (params: {
  fundraiserSlug: string;
  nonprofitSlug: string;
}) => Promise<{
  initialData: {
    users: UserResponse[];
    nonprofits: NonprofitResponse[];
  };
  fundraiserData: FundraiserResponse;
  raisedData: FundraiserRaisedResponse;
  fundraiserFeed: FeedItemResponse[];
  fundraiserFeedHasMore: boolean;
}>;

export const getFundraiserPageInitialProps: getFundraiserPageInitialPropsType =
  async ({ fundraiserSlug, nonprofitSlug }) => {
    const fundraiserDataQuery = serversideStaticQueryApi(
      getFundraiserRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: {
          fundraiserIdentifier: fundraiserSlug,
          nonprofitIdentifier: nonprofitSlug,
        },
        queryParams: {},
      }
    );

    const fundraiserRaisedDataQuery = serversideStaticQueryApi(
      getFundraiserRaisedRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: {
          fundraiserIdentifier: fundraiserSlug,
          nonprofitIdentifier: nonprofitSlug,
        },
        queryParams: {},
      }
    );

    const [fundraiserResponse, fundraiserRaisedDataResponse] =
      await Promise.all([fundraiserDataQuery, fundraiserRaisedDataQuery]);

    const fundraiserFeedData = await serversideStaticQueryApi(
      getFundraiserFeedRouteSpec as StaticApiRouteSpec,
      {
        routeTokens: { id: fundraiserResponse?.fundraiser.id },
        queryParams: { skip: 0, take: 6 },
      }
    );

    return {
      initialData: {
        users: [
          ...(fundraiserResponse.users || []),
          ...(fundraiserFeedData.users || []),
        ],
        nonprofits: [
          ...(fundraiserResponse.nonprofits || []),
          ...(fundraiserFeedData.nonprofits || []),
        ],
        tags: [
          ...(fundraiserResponse.tags || []),
          ...(fundraiserFeedData.tags || []),
        ],
      },
      fundraiserData: fundraiserResponse,
      raisedData: fundraiserRaisedDataResponse,
      fundraiserFeed: fundraiserFeedData.items,
      fundraiserFeedHasMore: fundraiserFeedData.hasMore,
    };
  };
