import { AvatarSize, avatarSizeToPx } from "@components/Avatar";
import { ButtonSize, ButtonRole } from "@components/Button";
import { MIN_CARD_WIDTH_RAW } from "@components/DonationCard/types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useId } from "react";

import { seededRandomMinMax } from "@every.org/common/src/helpers/number";

import { primaryButtonPaddingCss } from "src/styles/button";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";

export enum SkeletonTextHeight {
  SMALL = 8,
  MEDIUM = 16,
  LARGE = 20,
}

export const SkeletonAnimationOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 500px;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );

  transform: translateX(-100%);
  animation: skeletonShimmer 1s ease-in-out infinite;

  @keyframes skeletonShimmer {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
`;

export const SkeletonAvatar: React.FCC<{ size: AvatarSize }> = ({ size }) => {
  return (
    <div
      css={css`
        background: #eaeded;
        border-radius: 100%;
        height: ${avatarSizeToPx[size]}px;
        width: ${avatarSizeToPx[size]}px;
        min-width: ${avatarSizeToPx[size]}px;
      `}
    />
  );
};

// memo since this renders a lot and random integers dont need to trigger a
// rerender
export const SkeletonComment: React.FCC = React.memo(
  function SkeletonCommentImpl() {
    const seed1 = useId();
    const seed2 = useId();
    const minTextLines = 2;
    const maxTextLines = 4;
    const minTextWidth = 130;
    const maxTextWidth = MIN_CARD_WIDTH_RAW;

    const numLines = seededRandomMinMax(seed1, minTextLines, maxTextLines + 1);

    const lines = new Array(numLines).fill(null).map((_, index) => {
      const width = seededRandomMinMax(seed2, minTextWidth, maxTextWidth + 1);

      return (
        <SkeletonText
          key={index}
          css={css`
            max-width: ${width}px;
            width: 100%;
          `}
          height={SkeletonTextHeight.MEDIUM}
        />
      );
    });
    return (
      <div css={verticalStackCss.xs}>
        {lines}
        {numLines === maxTextLines && (
          <SkeletonText
            css={css`
              width: 50px;
            `}
            height={SkeletonTextHeight.SMALL}
          />
        )}
      </div>
    );
  }
);

export const SkeletonUserSupported: React.FCC = () => {
  return (
    <div
      css={[
        horizontalStackCss.s,
        css`
          align-items: center;
        `,
      ]}
    >
      <SkeletonAvatar
        size={AvatarSize.SMALL}
        css={css`
          flex-shrink: 0;
        `}
      />
      <div
        css={[
          verticalStackCss.xs,
          css`
            flex: 1 1 120px;
          `,
        ]}
      >
        <SkeletonText
          css={css`
            max-width: 120px;
            width: 100%;
          `}
        />
        <SkeletonText
          css={css`
            max-width: 50px;
            width: 100%;
          `}
        />
      </div>
      <SkeletonAvatar
        size={AvatarSize.SMALL}
        css={css`
          flex-shrink: 0;
          margin-left: auto;
        `}
      />
    </div>
  );
};

export const SkeletonText: React.FCC<{
  height?: SkeletonTextHeight;
  className?: string;
}> = ({ height = SkeletonTextHeight.SMALL, className }) => {
  return (
    <div
      className={className}
      css={css`
        background: #eaeded;
        border-radius: 2px;
        height: ${height}px;
      `}
    />
  );
};

export const SkeletonImageCover: React.FCC = () => {
  return (
    <div
      css={css`
        background: #eaeded;
        border-radius: 8px;
        height: 120px;
        width: 100%;
      `}
    />
  );
};

const skeletonButtonStyle = (role: ButtonRole) => {
  switch (role) {
    case ButtonRole.PRIMARY:
      return css`
        background: #eaeded;
      `;
    case ButtonRole.SECONDARY:
      return css`
        border: 2px solid #eaeded;
      `;
    default:
      return;
  }
};
export const SkeletonButton: React.FCC<{
  size: ButtonSize;
  role: ButtonRole;
  textSize?: number;
  className?: string;
}> = ({ size, role, className, textSize = SkeletonTextHeight.SMALL }) => {
  return (
    <div
      className={className}
      css={css`
        border-radius: 500px;
        ${skeletonButtonStyle(role)}
        ${role !== ButtonRole.UNSTYLED ? primaryButtonPaddingCss[size] : null};
      `}
    >
      <SkeletonText
        css={css`
          width: 50px;
        `}
        height={textSize}
      />
    </div>
  );
};
