import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import { Loading } from "@components/LoadingIndicator";
import { RecentContributor } from "@components/RecentContributor";
import { Feed, FeedDisplayProps } from "@components/feed";
import { DonateButton } from "@components/layout/DonateButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, forwardRef, useContext } from "react";

import {
  DonationChargeResponse,
  FeedItemResponse,
  FundraiserResponse,
  PersonalDonationChargeResponse,
} from "@every.org/common/src/codecs/entities";
import { FeedItemType, FeedPage } from "@every.org/common/src/entity/types";
import { dateSortComparisonBaseEntity } from "@every.org/common/src/helpers/date";
import { ListParams } from "@every.org/common/src/routes/index";

import RecentSupportersPlaceholder from "src/assets/illustrations/recent_supporters_placeholder.svg";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { MyDonationsContext } from "src/context/DonationsContext";
import { fetchNonprofitFeed } from "src/context/DonationsContext/actions";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { roundedBorder } from "src/theme/common";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { textSizeCss, TextSize } from "src/theme/text";
import { logger } from "src/utility/logger";

const BE_FIRST_PUBLIC = "Become a supporter!";

const transformCreatedAtDate = <T extends { createdAt: Date | string }>(
  obj: T
) => ({
  ...obj,
  createdAt:
    obj.createdAt instanceof Date ? obj.createdAt : new Date(obj.createdAt),
});

const RecentContributorsListDisplay = forwardRef<
  HTMLDivElement,
  FeedDisplayProps
>(function RecentContributorsListWithRef(
  {
    className,
    feedItems,
    moreItemsToFetch,
    fetchMoreItems,
    fetching,
    placeholder,
    excludeDonationIds,
    nonprofit,
    sortChronologically,
  },
  ref
) {
  const myDonations = useContext(MyDonationsContext);
  const loggedInUserId = useLoggedInUserOrUndefined()?.id;
  if (!nonprofit) {
    return null;
  }
  const donationChargeFeedId = new Map();
  const filteredDonationsMap: Map<
    string,
    DonationChargeResponse | PersonalDonationChargeResponse
  > = new Map();

  for (const feedItem of feedItems) {
    switch (feedItem.type) {
      case FeedItemType.USER_DONATION: {
        if (
          !!feedItem.donationCharge.donation &&
          !excludeDonationIds?.has(feedItem.donationCharge.donation.id)
        ) {
          donationChargeFeedId.set(feedItem.donationCharge.id, feedItem.feedId);
          filteredDonationsMap.set(
            feedItem.donationCharge.id,
            feedItem.donationCharge
          );
        }
        break;
      }
      default:
        logger.warn({
          message: `Unexpected feed item type in nonprofit recent contributors feed: ${feedItem.type}`,
        });
    }
  }
  const filteredMyDonations = myDonations.filter(
    (i) =>
      i.toNonprofitId === nonprofit.id &&
      !excludeDonationIds?.has(i.donation.id)
  );
  for (const donationCharge of filteredMyDonations) {
    filteredDonationsMap.set(donationCharge.id, donationCharge);
  }

  const donationsArray = [...filteredDonationsMap.values()].map(
    transformCreatedAtDate
  );
  const finalDonations = sortChronologically
    ? donationsArray.sort(dateSortComparisonBaseEntity)
    : donationsArray;

  return (
    <div
      data-feed-page={FeedPage.NONPROFIT_PROFILE}
      data-feed-user={loggedInUserId}
      css={css`
        > :not(:last-child) {
          padding-bottom: ${spacing.l};
          border-bottom: 1px solid var(${colorCssVars.dividerSoft});
        }
        /*
         * Moves the loading indicator / View More button down into the padding
         * on the card.
         */
        margin-bottom: -${spacing.xs};
      `}
      className={className}
      ref={ref}
    >
      {finalDonations.length > 0 && (
        <ul
          css={css`
            /*
             * If the loading indicator / View More button are not present,
             * properly positions this element to respect card padding.
             */
            &:last-child {
              margin-bottom: ${spacing.xs};
            }
          `}
        >
          {finalDonations.map((donationCharge) => {
            if (donationCharge) {
              return (
                <RecentContributor
                  donation={donationCharge.donation}
                  feedId={donationChargeFeedId[donationCharge.id]}
                  key={donationCharge.id}
                />
              );
            }
            return null;
          })}
        </ul>
      )}
      {fetching ? (
        <Loading
          css={css`
            margin-top: ${spacing.m};
          `}
        />
      ) : finalDonations.length === 0 ? (
        placeholder
      ) : (
        moreItemsToFetch && (
          <Button
            css={css`
              margin-top: ${spacing.s};
            `}
            data-tname="RecentContributors--ViewMore"
            role={ButtonRole.TEXT_ONLY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: fetchMoreItems,
            }}
            disabled={fetching}
          >
            View more
          </Button>
        )
      )}
    </div>
  );
});

interface RecentContributorsCardProps {
  className?: string;
  nonprofit: ContextNonprofit;
  /**
   * Optional Set of donation IDs to exclude.
   */
  excludeDonationIds?: Set<string>;
  fundraiserId?: FundraiserResponse["id"];
  sortChronologically?: boolean;
  nonprofitFeed?: FeedItemResponse[];
  hasMore?: boolean;
}

/**
 * Widget of recent supporters nonprofit.
 */
export const RecentContributorsCard: React.FCC<RecentContributorsCardProps> = ({
  className,
  nonprofit,
  excludeDonationIds,
  fundraiserId,
  sortChronologically,
  hasMore,
  nonprofitFeed = [],
}) => {
  const fetchFeed = useCallback(
    ({ skip, take }: ListParams) => {
      return fetchNonprofitFeed({
        skip,
        take,
        nonprofitId: nonprofit.id,
        fundraiserId,
      });
    },
    [nonprofit.id, fundraiserId]
  );

  const placeholder = (
    <Placeholder
      nonprofit={nonprofit}
      className={className}
      fundraiserId={fundraiserId}
    />
  );

  return (
    <Card css={verticalStackCss.l} className={className}>
      <Title>Donors</Title>
      <Feed
        initialItems={nonprofitFeed}
        initialHasMore={hasMore}
        page={FeedPage.RECENT_CONTRIBUTORS}
        loadMoreItems={fetchFeed}
        FeedDisplayComponent={RecentContributorsListDisplay}
        take={12}
        placeholder={placeholder}
        nonprofit={nonprofit}
        excludeDonationIds={excludeDonationIds}
        sortChronologically={sortChronologically}
      />
    </Card>
  );
};

const Title = styled.h2`
  ${textSizeCss[TextSize.m]};
  margin-bottom: ${spacing.xs};
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 200px;
  padding: ${spacing.xl};
  background-image: url(${RecentSupportersPlaceholder});
  ${roundedBorder};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-right: 0;
    margin-left: 0;
    &:not(:last-child) {
      margin-right: ${spacing.m};
    }
  }
`;

const Placeholder: React.FCC<{
  nonprofit: ContextNonprofit;
  fundraiserId?: FundraiserResponse["id"];
  className?: string;
}> = ({ className, fundraiserId, nonprofit }) => {
  return (
    <div css={verticalStackCss.s} className={className}>
      <PlaceholderContainer>
        {/* DonateButton receives different values on mobile and on desktop */}
        <div
          css={[
            horizontalStackCss.s,
            css`
              align-items: center;
              display: flex;
            `,
            cssForMediaSize({
              max: MediaSize.SMALL,
              css: css`
                display: none;
              `,
            }),
          ]}
        >
          <h3>{BE_FIRST_PUBLIC}</h3>
          <DonateButton
            isDisbursable={nonprofit.isDisbursable}
            primarySlug={nonprofit.primarySlug}
            data-tname="recentContributors--donateFirst"
            fromFundraiserId={fundraiserId}
            toNonprofitPage={!fundraiserId}
          />
        </div>
      </PlaceholderContainer>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > *:not(:last-child) {
            margin-right: ${spacing.s};
          }
          ${cssForMediaSize({
            min: MediaSize.MEDIUM_SMALL,
            css: css`
              display: none;
            `,
          })}
        `}
      >
        <h4>{BE_FIRST_PUBLIC}</h4>
        <DonateButton
          isDisbursable={nonprofit.isDisbursable}
          primarySlug={nonprofit.primarySlug}
          data-tname="recentContributors--donateFirst"
          toNonprofitPage
        />
      </div>
    </div>
  );
};
