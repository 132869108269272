import * as t from "io-ts";

import { PersonalDonationResponse } from "@every.org/common/src/codecs/entities";
import { RecurringStatus } from "@every.org/common/src/entity/types";
import { getMyDonationsRouteSpec } from "@every.org/common/src/routes/me";
import {
  updateRecurringDonationRouteSpec,
  getRecurringDonationsRouteSpec,
} from "@every.org/common/src/routes/recurringDonation";

import { dispatchFundraisersAction } from "src/context/FundraisersContext";
import { FundraiserActionType } from "src/context/FundraisersContext/types";
import { dispatchMyDonationsAction } from "src/context/MyDonationsContext/";
import { MyDonationsActionType } from "src/context/MyDonationsContext/types";
import { addNonprofits } from "src/context/NonprofitsContext";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

type UpdateMyDonationBody = t.TypeOf<
  typeof updateRecurringDonationRouteSpec.bodyCodec
>;

export async function updateMyDonation(body: UpdateMyDonationBody) {
  if (!dispatchMyDonationsAction) {
    throw new Error("Dispatch is undefined for MyDonationsContext");
  }
  const data = await queryApi(updateRecurringDonationRouteSpec, {
    body,
    queryParams: {},
    routeTokens: {},
  });
  dispatchMyDonationsAction({
    type: MyDonationsActionType.UPDATE_DONATION,
    data,
  });
  return data;
}

export async function resumeMyDonation(
  donationId: PersonalDonationResponse["id"]
) {
  const response = await updateMyDonation({
    donationId: donationId,
    status: RecurringStatus.ACTIVE,
  });
  dispatchMyDonationsAction({
    type: MyDonationsActionType.UPDATE_DONATION,
    data: response,
  });
}

export async function pauseMyDonation(
  donationId: PersonalDonationResponse["id"]
) {
  const response = await updateMyDonation({
    donationId,
    status: RecurringStatus.PAUSED,
  });
  dispatchMyDonationsAction({
    type: MyDonationsActionType.UPDATE_DONATION,
    data: response,
  });
}

export async function cancelMyDonation(
  donationId: PersonalDonationResponse["id"]
) {
  const response = await updateMyDonation({
    donationId,
    status: RecurringStatus.CANCELLED,
  });
  dispatchMyDonationsAction({
    type: MyDonationsActionType.CANCEL_DONATION,
    data: response,
  });
}

/**
 * Fetch data for all non-cancelled recurring donations from the API and
 * store them in the local MyDonationsContext
 */
export async function fetchMyDonations() {
  if (!dispatchMyDonationsAction) {
    logger.warn({ message: "Dispatch is undefined for MyDonationsContext" });
  }
  try {
    const { recurringDonations, nonprofits, fundraisers } = await queryApi(
      getRecurringDonationsRouteSpec,
      {
        body: {},
        queryParams: {},
        routeTokens: {},
      }
    );
    dispatchMyDonationsAction({
      type: MyDonationsActionType.ADD_DONATIONS,
      data: recurringDonations,
    });
    dispatchMyDonationsAction({ type: MyDonationsActionType.SET_LOADED });
    addNonprofits(nonprofits);
    fundraisers &&
      dispatchFundraisersAction({
        type: FundraiserActionType.ADD_FUNDRAISERS,
        data: fundraisers,
      });
    return recurringDonations;
  } catch (e) {
    logger.error({
      message: "An error occurred fetching recurring donations.",
      error: e,
    });
  }
  return [];
}

export async function fetchMyDonationCharges() {
  const responseData = await queryApi(getMyDonationsRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: {},
  });
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  responseData.fundraisers &&
    dispatchFundraisersAction({
      type: FundraiserActionType.ADD_FUNDRAISERS,
      data: responseData.fundraisers,
    });
  return responseData.donations;
}
