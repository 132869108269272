import { ButtonRole, ButtonSize } from "@components/Button";
import { DonationCardCommentBody } from "@components/DonationCard/DonationCardCommentBody";
import { DonationHeaderCard } from "@components/DonationCardHeader";
import { ShareAndLikeContainer } from "@components/feed/DonationCardActions";
import { DonationShareButton } from "@components/feed/DonationShareButton";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { FeedCard } from "@components/feed/shared";
import { css } from "@emotion/react";
import { UUID } from "io-ts-types/UUID";
import React, { useMemo } from "react";

import { FeedUserDonationResponse } from "@every.org/common/src/codecs/entities";
import {
  FeedItemType,
  LikeableType,
  FeedPage,
} from "@every.org/common/src/entity/types";
import { URLFormat } from "@every.org/common/src/helpers/clientRoutes";
import { getUserDonationShareUrl } from "@every.org/common/src/helpers/share";
import { getUserFullNameOrPlaceholder } from "@every.org/common/src/helpers/username";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { useUser } from "src/context/UsersContext/hooks";
import { userOrUndefined } from "src/context/UsersContext/selectors";
import {
  useEditableText,
  getSaveCommentCallback,
} from "src/hooks/useEditableText";
import { spacing } from "src/theme/spacing";
import { ClickAction } from "src/utility/analytics";

export const FundFeedDonationCard: React.FCC<{
  item: FeedUserDonationResponse;
  feedId?: UUID;
}> = ({ item, feedId }) => {
  const { donationCharge } = item;
  const { createdAt, donation, fromUserId, toNonprofitId } = donationCharge;

  const fromUser = userOrUndefined(useUser({ id: fromUserId }));
  const nonprofit = nonprofitOrUndefined(useNonprofit({ id: toNonprofitId }));
  const loggedInUser = useLoggedInUserOrUndefined();

  const isOwnDonation = !!loggedInUser && loggedInUser.id === fromUserId;

  const saveComment = useMemo(
    () => getSaveCommentCallback(donation.id),
    [donation.id]
  );
  const commentInterface = useEditableText({
    initialText: donation.commentText,
    saveAction: saveComment,
    logTag: "FundFeedDonationCard-Comment",
  });

  const donationPageLinkInfo =
    fromUser && fromUser.username && nonprofit
      ? {
          url: getUserDonationShareUrl({
            username: fromUser.username,
            nonprofitSlug: nonprofit.primarySlug,
            donationId: item.donationCharge.donation.id,
            donationShortId: item.donationCharge.donation.shortId,
            format: URLFormat.RELATIVE,
          }),
          data: {
            donationCharge: item.donationCharge,
            boost: item.boost,
            user: fromUser,
            nonprofit,
          },
        }
      : null;

  return nonprofit && fromUser ? (
    <FeedCard
      data-feed-feedid={feedId}
      data-feed-itemid={nonprofit.id}
      data-feed-itemtype={FeedItemType.CREATED_FUND}
    >
      <DonationHeaderCard
        profileImageCloudinaryId={fromUser.profileImageCloudinaryId}
        name={getUserFullNameOrPlaceholder(fromUser)}
        username={fromUser.username}
        frequency={donation.frequency}
        timesCharged={donationCharge.donation.timesCharged}
        createdAt={createdAt}
      />
      <DonationCardCommentBody
        commentEditDisabled={!isOwnDonation}
        commentInterface={commentInterface}
        page={FeedPage.FUND}
        donationPageLinkInfo={donationPageLinkInfo}
        saveOnBlur
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <ShareAndLikeContainer css={{ marginLeft: "auto" }}>
          <DonationShareButton
            aria-label="Share this donation"
            donation={donation}
            data-action={ClickAction.SHARE}
            buttonCss={{ padding: spacing.xxs }}
          />
          <LikeButtonWithCounter
            aria-label="Like this donation"
            data-tname="likeButton"
            data-action={ClickAction.LIKE}
            role={ButtonRole.TEXT_ONLY}
            size={ButtonSize.SMALL}
            type={LikeableType.DONATION}
            id={donationCharge.donation.id}
            likeCount={donationCharge.donation.likesInfo.count}
            loggedInUserLikes={
              donationCharge.donation.likesInfo.hasLoggedInUserLiked
            }
          />
        </ShareAndLikeContainer>
      </div>
    </FeedCard>
  ) : null;
};
