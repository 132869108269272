import { useCallback, useContext, useState } from "react";

import {
  FundraiserResponse,
  MatchingCampaignResponse,
  NonEmptyMatchingCampaignResponse,
} from "@every.org/common/src/codecs/entities";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import { getNonprofitMatchingCampaignAndCheckDisbursabilityRouteSpec } from "@every.org/common/src/routes/nonprofit";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

function nonEmptyMatchingCampaign(
  campaign: MatchingCampaignResponse
): NonEmptyMatchingCampaignResponse | undefined {
  if (Object.keys(campaign).length) {
    return campaign as NonEmptyMatchingCampaignResponse;
  }
  return undefined;
}

export function useNonprofitMatchingCampaign({
  nonprofitId,
  fundraiserId,
  disbursabilityCheck,
}: {
  nonprofitId?: ContextNonprofit["id"];
  fundraiserId?: FundraiserResponse["id"];
  disbursabilityCheck?: boolean;
}) {
  const authStatus = useContext(AuthContext).status;

  const [nonprofitMatchCampaign, setNonprofitMatchCampaign] =
    useState<NonEmptyMatchingCampaignResponse>();

  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      return authStatus !== AuthStatus.LOADING && nonprofitId
        ? queryApi(
            getNonprofitMatchingCampaignAndCheckDisbursabilityRouteSpec,
            {
              routeTokens: { nonprofitId },
              queryParams: removeUndefinedOrNullValues({
                disbursabilityCheck,
                fundraiserId,
              }),
              body: {},
            }
          )
        : undefined;
    }, [nonprofitId, disbursabilityCheck, fundraiserId, authStatus]),
    handleResponse: useCallback(
      (response: MatchingCampaignResponse | undefined) => {
        const matchingCampaignResponse =
          response && nonEmptyMatchingCampaign(response);

        if (
          matchingCampaignResponse?.matchGuests ||
          authStatus === AuthStatus.LOGGED_IN
        ) {
          setNonprofitMatchCampaign(matchingCampaignResponse);
        }
      },
      [authStatus]
    ),
    handleError: useCallback(
      (error) => {
        logger.error({
          error,
          message: `Error getting Matching Campaign for nonprofit id: ${nonprofitId}`,
          data: { nonprofitId: nonprofitId },
        });
      },
      [nonprofitId]
    ),
  });

  return nonprofitMatchCampaign;
}
