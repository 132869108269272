import { PageContainer } from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { ShareMatch } from "@components/donate/DonateV3/PaymentProcess/components/ShareMatch";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  CreateOrUpdateDonationResult,
  PaymentProcessProps,
} from "@components/donate/DonateV3/types";
import { usePostDonationAction } from "@components/donate/DonateV3/usePostDonationAction";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { spacing } from "@every.org/common/src/display/spacing";
import { DONATE_HASH } from "@every.org/common/src/helpers/clientRoutes";

import { useIsLoggedIn } from "src/context/AuthContext/hooks";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { getWindow } from "src/utility/window";

export type ShareMatchProps = {
  donateModalProps: PaymentProcessProps;
  formContext: DonateFormContext;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
};

export const DonationShareMatch = ({
  donateModalProps: { nonprofit, onComplete, fundraiser },
  formContext,
  createOrUpdateDonationResult,
}: ShareMatchProps) => {
  const isLoggedIn = useIsLoggedIn();

  const { nonprofitMatchCampaign, fromFundraiserId, redirectUrl } = formContext;

  const postDonationAction = usePostDonationAction({
    redirectUrl,
    onComplete,
    nonprofit,
    fundraiser,
  });

  const navigate = useNavigate();

  if (!createOrUpdateDonationResult) {
    // TODO it would be cool if we could actually load this donation and show
    // the confirmation page
    navigate(DONATE_HASH, { replace: true });
    return null;
  }

  const location = getWindow()?.location;
  // Always share fundraiser URLs
  const overrideUrl =
    fromFundraiserId && location
      ? location.protocol + "//" + location.host + location.pathname
      : undefined;

  return (
    <PageContainer>
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: css`
              padding: ${spacing.xxl};
            `,
          })}
        `}
      >
        <ShareMatch
          nonprofit={nonprofit}
          donation={createOrUpdateDonationResult?.donation}
          matchingCampaign={nonprofitMatchCampaign}
          postDonationAction={
            !isLoggedIn
              ? () =>
                  navigate(
                    paymentProcessRouteNameToPathMap[
                      PaymentProcessRouteName.THANK_YOU
                    ]
                  )
              : postDonationAction
          }
          overrideUrl={overrideUrl}
        />
      </div>
    </PageContainer>
  );
};
