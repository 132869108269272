import {
  AddressSearchBadge,
  SizeSearchBadge,
  TagSearchBadge,
} from "@components/SearchResults/SearchBadges";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import getUserLocale from "get-user-locale";
import { UUID } from "io-ts-types/UUID";
import React, { useContext, useMemo, useCallback } from "react";

import { NonprofitResponse } from "@every.org/common/src/codecs/entities";
import { numberOfNonprofitsVariableSpec } from "@every.org/common/src/entity/types/configVariable";
import { splitCauses } from "@every.org/common/src/helpers/causes";

import {
  UseConfigVariableStatus,
  useConfigVariable,
} from "src/context/ConfigVariableContext";
import { SearchContext } from "src/context/SearchContext";
import { useSearchOptionsFromUrl } from "src/context/SearchContext/helpers";
import { ContextSearchParams } from "src/context/SearchContext/types";
import { TagsContext } from "src/context/TagContext";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, spacing } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

const Comma = styled.span`
  &:before {
    content: ", ";
  }
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: { display: "none" },
  })}
`;

const Headline = styled.span`
  ${horizontalStackCss.xxs};
  ${textSizeCss.l};
  font-weight: ${FontWeight.REGULAR};
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      & > * {
        margin-bottom: ${spacing.xs};
      }
    `,
  })}
`;

function formatedNonprofitsNumber(num?: number) {
  const locale = getUserLocale();
  return num
    ? new Intl.NumberFormat(locale, {
        style: "decimal",
      }).format(num)
    : "";
}

function useAllNonpofitsHaveAllCauses(
  nonprofits: NonprofitResponse[],
  causes: string[]
) {
  const { tagsByTagName } = useContext(TagsContext);

  const causesIds = useMemo(
    () =>
      causes
        .map((cause) => tagsByTagName.get(cause)?.id)
        .filter((id): id is UUID => !!id),
    [causes, tagsByTagName]
  );

  const findAllCauses = useCallback(() => {
    if (
      !nonprofits.length ||
      !causesIds.length ||
      causesIds.length !== causes.length
    ) {
      return false;
    }

    for (const nonprofit of nonprofits) {
      if (!nonprofit.tags || !nonprofit.tags.length) {
        return false;
      }

      for (const causeId of causesIds) {
        if (!nonprofit.tags.includes(causeId)) {
          return false;
        }
      }
    }

    return true;
  }, [causes.length, causesIds, nonprofits]);

  return findAllCauses();
}
interface SearchHeaderProps {
  urlSearchOptions?: ContextSearchParams;
}

export const SearchHeader = ({
  urlSearchOptions: customUrlSearchOptions,
}: SearchHeaderProps) => {
  const urlSearchOptions = useSearchOptionsFromUrl();
  const searchState = useContext(SearchContext);
  const prevSearchResults = searchState.prevSearchResults;
  const hasMore = prevSearchResults?.results.hasMore;
  const matchedNonprofits = searchState.totalAmount
    ? Math.max(
        searchState.totalAmount,
        prevSearchResults?.results.totalEstimatedMatchedNonprofits || 0
      )
    : prevSearchResults?.results.totalEstimatedMatchedNonprofits || 0;
  const { address, query, size, causes } =
    customUrlSearchOptions || urlSearchOptions;

  const nonprofits = prevSearchResults?.results.nonprofits.map(
    ({ nonprofit }) => nonprofit
  );

  const numberOfNonprofitsConfigVariable = useConfigVariable(
    numberOfNonprofitsVariableSpec
  );

  const numberOfNoprofitsConfigValue =
    numberOfNonprofitsConfigVariable.status === UseConfigVariableStatus.SUCCESS
      ? numberOfNonprofitsConfigVariable.value
      : undefined;

  const showAnd = useAllNonpofitsHaveAllCauses(
    nonprofits || [],
    splitCauses(causes) || []
  );

  const addressElem = address && (
    <React.Fragment>
      <span>near </span>
      <AddressSearchBadge value={address} />
    </React.Fragment>
  );

  const queryElem = query && (
    <React.Fragment>
      <span>for</span>
      <span
        css={css`
          font-weight: ${FontWeight.BOLD};
        `}
      >
        {query}
      </span>
    </React.Fragment>
  );

  const causesElem = causes && (
    <React.Fragment>
      {splitCauses(causes)?.map((tagName, index, array) => {
        const showComma = array.length - 1 !== index && array.length !== 2;
        const showAndOr = array.length - 2 === index;

        return (
          <React.Fragment key={tagName}>
            <span css={{ display: "flex" }}>
              <TagSearchBadge value={tagName} />
              {showComma && <Comma />}
            </span>
            {showAndOr ? showAnd ? <span>and</span> : <span>or</span> : null}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );

  const isShowMatchedNonprofitsValue = causes || size || address || query;
  const sizeElem = size && <SizeSearchBadge value={size} />;
  const prefix = `${formatedNonprofitsNumber(
    isShowMatchedNonprofitsValue
      ? matchedNonprofits
      : numberOfNoprofitsConfigValue
  )}${
    hasMore && !searchState.totalAmount && isShowMatchedNonprofitsValue
      ? "+"
      : ""
  }`;
  const justOne = isShowMatchedNonprofitsValue && matchedNonprofits === 1;
  const prefixElem = <span>{prefix}</span>;
  const nonprofitsElem = <span>nonprofit{justOne ? "" : "s"}</span>;

  if (!causes && !numberOfNoprofitsConfigValue) {
    return null;
  }

  return (
    <Headline>
      {prefixElem} {sizeElem} {causesElem} {nonprofitsElem} {addressElem}{" "}
      {queryElem}
    </Headline>
  );
};
