import React, { useReducer, createContext } from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";

import {
  fundraiserOrUndefined,
  getFundraiser,
} from "src/context/FundraisersContext/selectors";
import {
  FundraisersState,
  FundraiserAction,
  FundraiserFetchStatus,
  FundraiserActionType,
  FundraiserIdentifier,
  InternalFundraisersState,
} from "src/context/FundraisersContext/types";

const INITIAL_FUNDRAISER_STATE: Omit<
  FundraisersState,
  "dispatchFundraiserAction"
> = {
  fundraisersById: new Map(),
  fundraiserFetchStatus: new Map(),
};

function updateStateWithArrayOfFundraisers(
  state: InternalFundraisersState,
  fundraisers: FundraiserResponse[]
): InternalFundraisersState {
  const incomingFundraisersById = new Map(
    fundraisers.map((fundraiser) => [fundraiser.id, fundraiser])
  );
  const fundraisersById = new Map([
    ...state.fundraisersById,
    ...incomingFundraisersById,
  ]);

  const incomingFundraisersFetchStatus = new Map();

  fundraisers.forEach(({ id }) => {
    incomingFundraisersFetchStatus.set(id, FundraiserFetchStatus.FOUND);
  });

  const fundraiserFetchStatus = new Map([
    ...state.fundraiserFetchStatus,
    ...incomingFundraisersFetchStatus,
  ]);

  return { ...state, fundraisersById, fundraiserFetchStatus };
}

/**
 * Update fetched status of a fundraiser currently being fetched.
 */
function updateStateWithStatus(
  state: InternalFundraisersState,
  identifier: FundraiserIdentifier,
  status: FundraiserFetchStatus
): InternalFundraisersState {
  const { fundraiserFetchStatus: prevFundraiserFetchStatus, ...rest } = state;
  const fundraiser = fundraiserOrUndefined(getFundraiser(state, identifier));
  if (fundraiser) {
    return state;
  }
  const fundraiserFetchStatus = new Map(prevFundraiserFetchStatus);
  fundraiserFetchStatus.set(identifier.id, status);
  return {
    ...rest,
    fundraiserFetchStatus,
  };
}

/**
 * Adds a fundraiser to the store of fundraiser, when one has been successfully
 * fetched.
 */
function updateStateWithFundraiser(params: {
  state: InternalFundraisersState;
  fundraiser: FundraiserResponse;
}): InternalFundraisersState {
  const { state, fundraiser } = params;
  const fundraisersById = new Map(state.fundraisersById);
  const fundraiserFetchStatus = new Map(state.fundraiserFetchStatus);

  // When we're using the trending tag response, do not override existing tags
  // in the store, as they are more up-to-date. (The trending response is cached
  // for 15 min.) ... A more robust solution would be to include and compare
  // timestamps in the responses
  if (!fundraisersById.get(fundraiser.id)) {
    fundraisersById.set(fundraiser.id, fundraiser);
    fundraiserFetchStatus.set(fundraiser.id, FundraiserFetchStatus.FOUND);
  }

  return {
    fundraisersById,
    fundraiserFetchStatus,
  };
}

function fundraisersReducer(
  state: InternalFundraisersState,
  action: FundraiserAction
): InternalFundraisersState {
  switch (action.type) {
    case FundraiserActionType.FETCHING_FUNDRAISER:
      return updateStateWithStatus(
        state,
        action.data,
        FundraiserFetchStatus.FETCHING_FUNDRAISER
      );
    case FundraiserActionType.FUNDRAISER_NOT_FOUND:
      return updateStateWithStatus(
        state,
        action.data,
        FundraiserFetchStatus.FUNDRAISER_NOT_FOUND
      );
    case FundraiserActionType.ADD_FUNDRAISER:
      return updateStateWithFundraiser({
        state: state,
        fundraiser: action.data,
      });
    case FundraiserActionType.ADD_FUNDRAISERS:
      return updateStateWithArrayOfFundraisers(state, action.data);
    default:
      throw new Error(`Fundraiser action with unknown type: ${action}`);
  }
}

export let dispatchFundraisersAction: React.Dispatch<FundraiserAction>;

export const FundraisersContext = createContext<FundraisersState>(
  // technically this is unsound since `dispatchFundraisersAction` will not be present,
  // but we always provide it in the provider below so for ergonomics we take
  // this risk
  INITIAL_FUNDRAISER_STATE as FundraisersState
);

export const FundraisersProvider: React.FCC<{
  initialData?: FundraiserResponse[];
}> = ({ children, initialData }) => {
  const [fundraisersState, fundraisersDispatcher] = useReducer(
    fundraisersReducer,
    initialData
      ? updateStateWithArrayOfFundraisers(INITIAL_FUNDRAISER_STATE, initialData)
      : INITIAL_FUNDRAISER_STATE
  );

  dispatchFundraisersAction = fundraisersDispatcher;
  return (
    <FundraisersContext.Provider
      value={{
        ...fundraisersState,
        dispatchFundraiserAction: fundraisersDispatcher,
      }}
    >
      {children}
    </FundraisersContext.Provider>
  );
};
