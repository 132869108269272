import { stringEnumCodec } from "../codecs";

/**
 * Supported currencies, by ISO codes.
 *
 * https://en.wikipedia.org/wiki/List_of_circulating_currencies
 */
export enum Currency {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
  HKD = "HKD",
  CAD = "CAD",
  JPY = "JPY",
  CHF = "CHF",
  NZD = "NZD",
}

export enum Country {
  US = "US",
}

export enum FlagReason {
  NOT_INTERESTED = "NOT_INTERESTED",
  SPAM_OR_FRAUD = "SPAM_OR_FRAUD",
  ABUSIVE_OR_HARMFUL = "ABUSIVE_OR_HARMFUL",
  NAUGHTY_CONTENT = "NAUGHTY_CONTENT",
  MISLEADING = "MISLEADING",
  SUICIDAL = "SUICIDAL",
  CN_ADVISORY = "CN_ADVISORY",
}

/**
 * NOTE: This type/field is used for accounting purposes to determine (i.e. how much of a grant we're using)
 * Avoid changing/adding and let the team know so they can update accounting processes
 */
export enum GivingCreditType {
  /**
   * Anything promotional, such as incentives or referral codes.
   */
  PROMO = "PROMO",
  /**
   * Gift card sale.
   */
  SALE = "SALE",
}

/**
 * Status of a follow request.
 */
export enum FollowRequestStatus {
  /**
   * There is no existing follow request. Server can return this instead of REJECTED.
   */
  NONE = "none",
  /**
   * The user being followed has not yet responded to this request.
   */
  UNSET = "unset",
  /**
   * The user being followed has accepted this request.
   */
  ACCEPTED = "accepted",
  /**
   * The user being followed has rejected this request.
   */
  REJECTED = "rejected",
}

export enum CreateGuestUserResponseStatus {
  FOUND = "FOUND",
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  LOGGED_IN_USER_FOUND = "LOGGED_IN_USER_FOUND",
}

export const CURRENCY_PRECISION = 32; // total number of digits
export const CURRENCY_SCALE = 18; // number of digits to the right of the decimal place

/**
 * An email address.
 */
export type EmailAddress = string;

export type NteeCode = string;

export enum NonprofitType {
  /**
   * A nonprofit
   */
  NONPROFIT = "NONPROFIT",
  /**
   * A collection of nonprofits that can be donated to at once curated by a domain expert.
   * Money is disbursed to the fund if it has a valid EIN, otherwise we hold it until the fund has one.
   */
  FUND = "FUND",
  /**
   * A group of users; not actually something you can donate to at the moment
   */
  COMMUNITY = "COMMUNITY",
  /**
   * A project run by some entity; nonprofits it can be paid out to must be
   * specified
   */
  PROJECT = "PROJECT",
  /**
   * A project run by some entity that has its own disbursement method.
   * Currently supporting stripe disbursement only.
   */
  PROJECT_WITH_DISBURSEMENT = "PROJECT_WITH_DISBURSEMENT",
  /**
   * A collection of nonprofits that are not curated by a domain expert.
   * Or a dynamic collection of nonprofits that can be chosen by the partner forwarding the user to every.org.
   * Donations are split among the nonprofits and disbursed to them directly.
   */
  LIST = "LIST",
}

/**
 * This determines which page will be used in the frontend to display the nonprofit
 */
export enum NonprofitDisplayType {
  NONPROFIT = "NONPROFIT",
  FUND = "FUND",
  COMMUNITY = "COMMUNITY",
  DIRECTORY = "DIRECTORY",
}

export enum FocusArea {
  /**
   * One or two counties
   */
  LOCAL = "LOCAL",
  /**
   * Many counties/states
   */
  REGIONAL = "REGIONAL",
  /**
   * A single country
   */
  NATIONAL = "NATIONAL",
  /**
   * Multiple countries
   */
  GLOBAL = "GLOBAL",
}

/**
 * Broad categories that encompass a wide range of nonprofits that work in the
 * same general field.
 */
export enum CauseCategory {
  ANIMALS = "ANIMALS",
  ARTS_CULTURE_HUMANITIES = "ARTS_CULTURE_HUMANITIES",
  EDUCATION = "EDUCATION",
  ENVIRONMENT = "ENVIRONMENT",
  HEALTH = "HEALTH",
  HUMAN_AND_CIVIL_RIGHTS = "HUMAN_AND_CIVIL_RIGHTS",
  HUMAN_SERVICES = "HUMAN_SERVICES",
  RESEARCH_AND_PUBLIC_POLICY = "RESEARCH_AND_PUBLIC_POLICY",
  RELIGION = "RELIGION",
  UNKNOWN = "UNKNOWN",
}
export type ValidCauseCategory = Exclude<CauseCategory, CauseCategory.UNKNOWN>;

interface CauseMetadataContent {
  /**
   * Human-readable title describing the cause.
   */
  title: string;

  /**
   * Short one-liner to describe the cause.
   */
  shortDescription: string;

  /**
   * Cloudinary ID of Opengraph image
   *
   * Sources are SVGs which allow for arbitrary scaling
   */
  ogImageCloudinaryId: string;
}

export const DEFAULT_OPENGRAPH_CLOUDINARY_ID = "og3_uueyx1";

/**
 * Cause category title and shortDescription alphabetically ordered by title.
 */
export const CauseMetadata: { [key in CauseCategory]: CauseMetadataContent } = {
  [CauseCategory.ANIMALS]: {
    title: "Animals",
    shortDescription: "Animal welfare, rights, and diversity",
    ogImageCloudinaryId: "og-ani_tl9w8f",
  },
  [CauseCategory.ARTS_CULTURE_HUMANITIES]: {
    title: "Culture",
    shortDescription: "Cultivation of arts, music, and the humanities",
    ogImageCloudinaryId: "og-cul_xxnqdt",
  },
  [CauseCategory.EDUCATION]: {
    title: "Education",
    shortDescription: "Accessible and effective education for all",
    ogImageCloudinaryId: "og-edu_xi1mez",
  },
  [CauseCategory.ENVIRONMENT]: {
    title: "Environment",
    shortDescription: "Conservation and sustainability",
    ogImageCloudinaryId: "og-env_crlftv",
  },
  [CauseCategory.HEALTH]: {
    title: "Health",
    shortDescription: "Cures, support, and public awareness",
    ogImageCloudinaryId: "og-hea_xnefu1",
  },
  [CauseCategory.HUMAN_SERVICES]: {
    title: "Humans",
    shortDescription: "Poverty relief and community development",
    ogImageCloudinaryId: "og-hum_mpwowl",
  },
  [CauseCategory.HUMAN_AND_CIVIL_RIGHTS]: {
    title: "Justice",
    shortDescription: "Civil and human rights advocacy and social action",
    ogImageCloudinaryId: "og-jus_oqpzle",
  },
  [CauseCategory.RELIGION]: {
    title: "Religion",
    shortDescription: "Religious activity and worship",
    ogImageCloudinaryId: "og-rel_rdm69d",
  },
  [CauseCategory.RESEARCH_AND_PUBLIC_POLICY]: {
    title: "Research",
    shortDescription: "Scientific research and public policy",
    ogImageCloudinaryId: "og-res_dieaib",
  },
  [CauseCategory.UNKNOWN]: {
    title: "Uncategorized",
    shortDescription: "Nonprofits without a specific cause category",
    // Generic Every.org one - should never be used
    ogImageCloudinaryId: DEFAULT_OPENGRAPH_CLOUDINARY_ID,
  },
};

export const TagNameByCauseCategory: { [key in ValidCauseCategory]: string } = {
  [CauseCategory.ANIMALS]: "animals",
  [CauseCategory.ARTS_CULTURE_HUMANITIES]: "culture",
  [CauseCategory.EDUCATION]: "education",
  [CauseCategory.ENVIRONMENT]: "environment",
  [CauseCategory.HEALTH]: "health",
  [CauseCategory.HUMAN_SERVICES]: "humans",
  [CauseCategory.HUMAN_AND_CIVIL_RIGHTS]: "justice",
  [CauseCategory.RELIGION]: "religion",
  [CauseCategory.RESEARCH_AND_PUBLIC_POLICY]: "research",
};

export const ValidCauseCategories = Object.keys(CauseMetadata).filter(
  (x) => x !== CauseCategory.UNKNOWN
) as ValidCauseCategory[];

export function validCauses(causes: CauseCategory[]) {
  return causes.filter(
    (c) => c !== CauseCategory.UNKNOWN
  ) as ValidCauseCategory[];
}

/**
 * Category of payment.
 */
export enum PaymentMethod {
  STRIPE = "STRIPE",
  STOCKS = "STOCKS",
  CRYPTO = "CRYPTO",
  DAF = "DAF",
  MUTUAL_FUND = "MUTUAL_FUND",
  CHECK = "CHECK", // Includes ACH and wire
  PAYPAL = "PAYPAL",
  // Hedado uses Stripe Connect to transfer funds to us. This happens in batch
  // transfers and async to any specific donations.
  STRIPE_CONNECT = "STRIPE_CONNECT",
  // Match from a company, where the employee doesn't pay
  COMPANY_MATCH = "COMPANY_MATCH",
}

/**
 * Class of donations that are considered property or assets. For these donations, we should
 * qualify donation receipt language to make sure it's not a fixed value but some estimation
 * (such as fair market value FMV)
 */
export const ASSET_DONATION_PAYMENT_METHODS = [
  PaymentMethod.CRYPTO,
  PaymentMethod.STOCKS,
  PaymentMethod.MUTUAL_FUND,
];

export enum RecurringStatus {
  /**
   * This recurring donation is active and will be regularly executed
   */
  ACTIVE = "ACTIVE",
  /**
   * This recurring donation has been cancelled by the user
   */
  CANCELLED = "CANCELLED",
  /**
   * This recurring donation has been paused by the user
   */
  PAUSED = "PAUSED",
  /**
   * This donation was a one-time donation and therefore won't be repeated
   */
  ONE_TIME = "ONE_TIME",
  /**
   * This donation has been aborted automatically after failing on creation
   */
  ABORTED = "ABORTED",
}

export const DonationStatusToUserFacingTextMap: {
  [key in RecurringStatus]: string;
} = {
  [RecurringStatus.ACTIVE]: "Active",
  [RecurringStatus.CANCELLED]: "Cancelled",
  [RecurringStatus.PAUSED]: "Paused",
  [RecurringStatus.ONE_TIME]: "One-time",
  [RecurringStatus.ABORTED]: "Aborted",
};

export enum DonationVisibility {
  // Follow the default profile visibility settings. Hopefully we'll be able to
  // deprecate this in the future.
  DEFAULT = "DEFAULT",
  // Shown to everyone
  EVERYONE = "EVERYONE",
  // Same functionality as the `isArchived` flag - does not appear on any feeds,
  // nor does it show up on their public profile.
  // TODO - should we show it on their own feed? Currently we redirect to the
  // home profile, and it would be weird if the donation was not shown there.
  // Do we currently show private donors' own donations on the nonprofit's feed?
  NO_ONE = "NO_ONE",
}

export enum DonationFrequency {
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  ONCE = "ONCE",
}

export const DonationFrequencyToUserFacingTextMap: {
  [key in DonationFrequency]: string;
} = {
  [DonationFrequency.MONTHLY]: "Monthly",
  [DonationFrequency.WEEKLY]: "Weekly",
  [DonationFrequency.ONCE]: "One-time",
};

export function lowercaseUserFacingFrequency(frequency: DonationFrequency) {
  return DonationFrequencyToUserFacingTextMap[frequency].toLowerCase();
}

export const FREQUENCY_MULTIPLIER: { [key in DonationFrequency]: number } = {
  [DonationFrequency.ONCE]: 1,
  [DonationFrequency.MONTHLY]: 12,
  [DonationFrequency.WEEKLY]: 52,
};

/**
 * Possible steps that a donation can be in as it is executed
 */
export enum DonationChargeStatus {
  /**
   * Created on our side, but not yet executed (i.e. sent to payment/donation
   * processors)
   */
  CREATED = "CREATED",
  /**
   * Attempting to complete payment.
   */
  PAYING = "PAYING",
  /**
   * This state is used for donations which are pending manual verification of a
   * bank account.
   */
  PENDING_MANUAL_VERIFICATION = "PENDING_MANUAL_VERIFICATION",
  /**
   * For asynchronous payments - waiting on Stripe to tell us that
   * the payment has completed.
   */
  PENDING = "PENDING",
  /**
   * For asynchronous payments that have expired.
   */
  EXPIRED = "EXPIRED",
  /**
   * For when human or automated intervention is required.
   * This currently happens for crypto * payments when the
   * crypto has been transferred and needs to be sold for USD.
   */
  MANUAL_ACTION_REQUIRED = "MANUAL_ACTION_REQUIRED",
  PENDING_FIAT_CONVERSION = "PENDING_FIAT_CONVERSION",
  /**
   * Successfully completed payment.
   */
  PAID = "PAID",
  /**
   * Failed to execute the payment
   */
  ERROR = "ERROR",
  /**
   * Refunded payment on Stripe or original payment record.
   */
  REFUNDED = "REFUNDED",
  /**
   * Refunded charge that has was disbursed (we received the money, disbursed it and then a refund was issued)
   */
  REFUNDED_LOSS = "REFUNDED_LOSS",
  /**
   * No payment necessary. Only current reason for this would be that the
   * entire amount of the donation is covered by giving credit.
   */
  NO_PAYMENT_NECESSARY = "NO_PAYMENT_NECESSARY",
  /**
   * Payment was made using giving credits that are pending (the charge that purchased the giving credits is pending)
   */
  PENDING_CREDITS = "PENDING_CREDITS",
}

export enum UserFacingDonationChargeStatus {
  PENDING_MANUAL_VERIFICATION = "Pending manual verification",
  PENDING = "Pending",
  SUCCESS = "Success",
  ERROR = "Error",
  EXPIRED = "Expired",
  RETRIED = "Retried",
  REFUNDED = "Refunded",
}

/**
 * Payment Source types
 * https://any-api.com/stripe_com/stripe_com/docs/Definitions/Source
 */
export enum PaymentSourceType {
  ACH_DEBIT = "ach_debit",
  CARD = "card",
  THE_GIVING_BLOCK = "the_giving_block",
  /* Deprecated */
  DEPRECATED_COINBASE_COMMERCE = "coinbase_commerce",
  DEPRECATED_WYRE = "wyre",
  MANUAL = "manual",
  CHARIOT = "chariot",
}

export enum PaymentSourceStatus {
  /**
   * Payment source is not yet ready to be charged.
   */
  PENDING = "pending",
  /**
   * Payment source is connected, and for bank accounts, verified.
   */
  CONNECTED = "connected",
  /**
   * Payment source has expired and user needs to re-authenticate.
   */
  EXPIRED = "expired",
  /**
   * User exhausted attempts to verify and needs to retry.
   */
  FAILED = "failed",
  /**
   * Unknown status.
   */
  UNKNOWN = "unknown",
}

/**
 * Payout source representing where the money came from
 */
export enum PayoutSource {
  DONATION_CHARGE = "donation_charge",
  CREDITS = "credits",
  MATCH = "match",
  OTHER = "other",
  NONPROFIT_CHARGE = "nonprofit_charge",
}

export enum MediaType {
  IMAGE = "image",
  VIDEO = "video",
}

/**
 * Feed item types
 */
export enum FeedItemType {
  USER_DONATION,
  NONPROFIT_RECOMMENDATION,
  CREATED_FUND,
  WHO_TO_FOLLOW,
  TRENDING_FUNDRAISER_EVENT,
  FUNDRAISER,
}

/**
 * Feed page
 */
export enum FeedPage {
  ADMIN = "ADMIN",
  USER_PROFILE = "USER_PROFILE",
  RECENT_CONTRIBUTORS = "RECENT_CONTRIBUTORS",
  RELATED_NONPROFITS = "RELATED_NONPROFITS",
  USER_LIKES = "USER_LIKES",
  HOME = "HOME",
  LANDING = "LANDING",
  TAG_NONPROFITS = "TAG_NONPROFITS",
  TAG_GIFTS = "TAG_GIFTS",
  DONATION_MODAL = "DONATION_MODAL",
  FUND = "FUND",
  COMMUNITY = "COMMUNITY",
  NONPROFIT_PROFILE = "NONPROFIT_PROFILE",
  USER_NONPROFITS_RECOMMENDATIONS = "USER_NONPROFITS_RECOMMENDATIONS",
  NTEE_CATEGORY = "NTEE_CATEGORY",
  USER_JOINS = "USER_JOINS",
  SEARCH_FILTERS = "SEARCH_FILTERS",
}

/**
 * Feed item recommendation reasons.
 * The lower on the list the more relevant the reason to display when explaining.
 */
export enum FeedItemRecommendationReason {
  FEED_MATCH,
  NONPROFIT_DESCRIPTION,
  NONPROFIT_SHARED_SUPPORTERS,
  NONPROFIT_SIMILAR_DONATIONS,
  USER_DONATIONS,
  NONPROFIT_ENDORSED,
}

/**
 * Recommendation kinds
 */
export enum RecomendationType {
  SIMILAR_NONPROFIT = "SIMILAR_NONPROFIT",
  NONPROFIT_DONATIONS = "NONPROFIT_GIFTS",
  NONPROFIT_SHARED_SUPPORTERS = "NONPROFIT_SUPPORTERS",
  USER_DONATIONS = "USER_GIFTS",
  NONPROFIT_ENDORSED = "NONPROFIT_ENDORSED",
}

/**
 * Likeable item types
 */
export enum LikeableType {
  DONATION,
  FUND,
  NONPROFIT,
  TAG,
}

/**
 * Admin levels for edit access
 *
 * A simple hierarchy, with SUPER_ADMINs at the top and RESTRICTED users at the
 * bottom
 */
export enum AdminLevel {
  /**
   * Can do everything, including editing the landing page via /cheesecake and
   * special cause pages like /covid19.
   */
  SUPER_ADMIN = "SUPER_ADMIN",

  /**
   * Can apply/reject suggested changes and edit nonprofit profiles.
   */
  MODERATOR = "MODERATOR",

  /**
   * Has access to beta features.
   */
  BETA = "BETA",

  /**
   * Can suggest edits to nonprofit profiles
   */
  DEFAULT = "DEFAULT",

  /**
   * Can't suggest edits to nonprofit profiles
   */
  RESTRICTED = "RESTRICTED",
}

/**
 * Verified statuses for edit access
 */
export enum VerifiedStatus {
  /**
   * Influencers and other prominent individuals created their own accounts
   */
  VERIFIED = "VERIFIED",

  /**
   * Any other users
   */
  NOT_VERIFIED = "NOT_VERIFIED",

  /**
   * Account that was created based on publicly available information, not by the individual themselves.
   */
  PUBLIC_INFO = "PUBLIC_INFO",
}

/**
 * Status of a user's account on Every.org.
 */
export enum AccountStatus {
  /**
   * User is an active user, and this is the primary User representing that user.
   */
  ACTIVE = "ACTIVE",

  /**
   * Account has been linked into another User entity.
   */
  LINKED = "LINKED",

  /**
   * User has deactivated their account, but it can be restored.
   */
  DEACTIVATED = "DEACTIVATED",

  /**
   * User has deleted their account, and we erased all PII.
   */
  DELETED = "DELETED",
}

/**
 * Status of a nonprofit edit trequest.
 */
export enum NonprofitEditStatus {
  /**
   * Edit before an admin has acted on it.
   */
  NEW = "NEW",
  /**
   * An admin as accepted and applied the edit.
   */
  ACCEPTED = "ACCEPTED",
  /**
   * An admin has rejected the edit.
   */
  REJECTED = "REJECTED",
}

/**
 * Status of a nonprofit tag operation edit request.
 */
export enum NonprofitTagNonprofitsNonprofitEditOperation {
  /**
   * The user has requested to tag the nonprofit with the tag.
   */
  TAG = "TAG",
  /**
   * The user has requested to untag the nonprofit with the tag.
   */
  UNTAG = "UNTAG",
}

/**
 * Status of email verification
 */
export enum VerifyEmailStatus {
  /**
   * Verification code has expired.
   */
  EXPIRED = "EXPIRED",
  /**
   * Unknown nonce.
   */
  INVALID = "INVALID",
  /**
   * Email was verified.
   */
  VERIFIED = "VERIFIED",
}

export enum GetUnclaimedGivingCreditStatus {
  FOUND = "FOUND",
  INVALID_CODE = "INVALID_CODE",
  ALREADY_CLAIMED = "ALREADY_CLAIMED",
  EXPIRED = "EXPIRED",
}

export enum FundCreatingEntityType {
  NONPROFIT = "NONPROFIT",
  USER = "USER",
}

export enum NonprofitRevenueSize {
  SMALL = "Small",
  MEDIUM = "Mid-sized",
  LARGE = "Large",
}
export const nonprofitRevenueSizeCodec = stringEnumCodec({
  name: "NonprofitRevenueSize",
  enumObject: NonprofitRevenueSize,
});

export enum NonprofitAdminStatus {
  /**
   * The user has requested to be an admin of this nonprofit. This request must
   * be confirmed before they are granted admin privileges.
   */
  REQUESTED = "REQUESTED",
  /**
   * The user has been denied permission to be an admin of this nonprofit.
   */
  DENIED = "DENIED",
  /**
   * The user has been confirmed as an admin of this nonprofit.
   */
  CONFIRMED = "CONFIRMED",
  /**
   * The user had admin access but it was revoked
   */
  REVOKED = "REVOKED",
}

export enum NonprofitAdminDonationNotificationSetting {
  DO_NOT_SEND = "DO_NOT_SEND",
  SEND_PRIVATE_NOTE_ONLY = "SEND_NOTES",
  SEND_ALL = "SEND_ALL",
}

export enum NonprofitMembershipStatus {
  /**
   * The user has requested to be a member of this nonprofit.
   */
  REQUESTED = "REQUESTED",
  /**
   * The user has been denied permission to be a member of this nonprofit.
   */
  DENIED = "DENIED",
  /**
   * The user has been confirmed as a member of this nonprofit.
   */
  CONFIRMED = "CONFIRMED",
}

/**
 * Relative order of admin levels, from least privileged to most.
 * One directional only, meaning ADMIN can do everything that DEFAULT can, and
 * SUPER_ADMIN can do everything ADMIN and DEFAULT can.
 *
 * Useful for comparator methods; must be kept in sync with the enum
 * above if it changes
 */
// TODO: How to guarantee each one appears in array exactly once? Read-only?
const ADMIN_LEVEL_ORDER = [
  AdminLevel.RESTRICTED,
  AdminLevel.DEFAULT,
  AdminLevel.BETA,
  AdminLevel.MODERATOR,
  AdminLevel.SUPER_ADMIN,
];

/**
 * Compares a AdminLevel against another to determine whether it is as
 * privileged as that level.
 */
export function atLeastAsPrivilegedAs(props: {
  level?: AdminLevel;
  compareAgainst: AdminLevel;
}): boolean {
  let level = props.level;
  if (!level) {
    level = ADMIN_LEVEL_ORDER[0];
  }
  const levelIndex = ADMIN_LEVEL_ORDER.indexOf(level);
  const compareAgainstIndex = ADMIN_LEVEL_ORDER.indexOf(props.compareAgainst);
  if (levelIndex < 0 || compareAgainstIndex < 0) {
    throw new Error("Admin level can't be determined");
  }
  return levelIndex >= compareAgainstIndex;
}

/**
 * When a user deactivates their account, we log their reasoning if provided.
 */
export enum DeactivateReason {
  BREAK_FROM_DONATION = "BREAK_FROM_DONATION",
  TOO_MANY_EMAILS = "TOO_MANY_EMAILS",
  PRIVACY = "PRIVACY",
  OTHER = "OTHER",
  TESTING = "TESTING",
  MULTIPLE_ACCOUNTS = "MULTIPLE_ACCOUNTS",
}

export enum SearchQueryParam {
  SEARCH_TERM = "q",
  CAUSES = "causes",
  LAT = "lat",
  LNG = "lng",
  DISTANCE = "distance",
  ORGANIZATION_SIZE = "size",
  ADDRESS = "address",
  INCLUDE_COUNT = "includeCount",
}

export enum DirectoryFilterSearchParam {
  SEARCH_TERM = "q",
  CAUSES = "causes",
  ORGANIZATION_SIZE = "size",
}

export enum DonationsTabQueryParam {
  SEARCH_TERM = "q",
  DISBURSEMENT_ID = "disbursement_id",
}

export enum ShareMedium {
  // Copying some share text
  COPY = "COPY",
  // Copy just the link
  LINK = "LINK",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  WHATSAPP = "WHATSAPP",
  REDDIT = "REDDIT",
  LINKEDIN = "LINKEDIN",
  EMAIL = "EMAIL",
  /**
   * Native share dialogs, like iOS builtin share, where we have less control
   * over the medium of sharing
   */
  NATIVE = "NATIVE",
}

export enum AdminInviteResult {
  OK = "OK",
  EXPIRED = "EXPIRED",
  TOKEN_DOES_NOT_EXIST = "TOKEN_DOES_NOT_EXIST",
  NEEDS_EMAIL_VERIFICATION = "NEEDS_EMAIL_VERIFICATION",
  ALREADY_USED = "ALREADY_USED",
}

/**
 * Api disbursement partners.
 */
export enum ApiDisbursementPartner {
  HEDADO = "hedado",
  COINUP = "coinup",
  LILY = "lily",
  FUNDWURX = "fundwurx",
  HAND = "hand",
  OFFICE_HOURS = "office_hours",
}

export enum ConfigureDonateButtonSrc {
  SEARCH = "search",
  ADMIN = "admin",
}

/**
 * Frontend options for giving.
 */
export enum DonationFlowPaymentOption {
  CREDIT_CARD = "card",
  BANK = "bank",
  PAYPAL = "paypal",
  VENMO = "venmo",
  PAYMENT_REQUEST = "pay",
  GIFT_CARD = "gift",
  CRYPTO = "crypto",
  STOCKS = "stocks",
  DAF = "daf",
}

/**
 * Imaginary options that cannot be selected as a payment method
 */
export enum NominalDonationFlowPaymentOption {
  FUNDRAISER = "fundraiser",
}

export enum ConfirmEmailChangeStatus {
  EXPIRED = "ERROR",
  INVLAID = "INVALID",
  SUCCESS = "SUCCESS",
}

export enum FundraiserGoalType {
  // Set by the user
  CUSTOM = "CUSTOM",
  // Automatically determined by Every.org
  AUTOMATIC = "AUTOMATIC",
}

export enum FundraiserType {
  SINGLE_NONPROFIT = "SINGLE_NONPROFIT",
  MULTIPLE_NONPROFITS = "MULTIPLE_NONPROFITS",
}

export enum ApiKeyStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export const SuccessfulDonationChargeStatus = [
  UserFacingDonationChargeStatus.SUCCESS,
  UserFacingDonationChargeStatus.PENDING,
  UserFacingDonationChargeStatus.PENDING_MANUAL_VERIFICATION,
];

export enum DisbursementType {
  NFG_BATCH_FILE = "NFG_BATCH_FILE",
  BILL_DOT_COM = "BILL_DOT_COM",
  STRIPE_CONNECT = "STRIPE_CONNECT",
  PAYPAL_GRANTS = "PAYPAL_GRANTS",
  /**
   * Represents funds that were made directly to the nonprofit's stripe account
   * as is done for international nonprofits
   */
  STRIPE_DIRECT = "DIRECT",
  /**
   * Manual disbursement made directly to nonprofit, typically through a donation flow on their website.
   */
  MANUAL = "MANUAL",
}
export const disbursementTypeCodec = stringEnumCodec({
  name: "DisbursementType",
  enumObject: DisbursementType,
});

/**
 * The subset of disbursement types that we can disburse via the superadmin
 * interface.
 */
export enum DisbursementTypeUserFacing {
  STRIPE_CONNECT = "Stripe",
  PAYPAL_GRANTS = "PayPal Grants",
  NFG_BATCH_FILE = "Network for Good",
  MANUAL = "manual",
}
export const disbursementTypeUserFacingCodec = stringEnumCodec({
  name: "DisbursementTypeUserFacing",
  enumObject: DisbursementTypeUserFacing,
});

/**
 * Tracks the status of disbursement. Currently this is manually updated by us
 * in all scenarios.
 */
export enum DisbursementStatus {
  INITIATED = "Initiated",
  AWAITING_ACCEPTANCE = "Awaiting acceptance",
  SENT = "Sent",
  REPROCESSING = "Re-processing",
}

export enum NonprofitDisbursementCheck {
  // We are verifying disbursement by checking against NetworkForGood.
  NETWORK_FOR_GOOD = "NETWORK_FOR_GOOD",
  // We are relying on PaypalGrants for disbursement eligibility.
  PAYPAL_GRANTS = "PAYPAL_GRANTS",
  // We have manually specified disbursement eligibility.
  MANUAL = "MANUAL",
}
export const disbursementCheckCodec = stringEnumCodec({
  name: "DisbursementCheck",
  enumObject: NonprofitDisbursementCheck,
});

export enum Crm {
  SALESFORCE = "SALESFORCE",
}

export enum CrmState {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  PAUSED = "PAUSED",
  CONNECTED = "CONNECTED",
}

export type StatsigTier = "production" | "staging" | "development" | undefined;

export enum PaymentRequestType {
  APPLE_PAY = "APPLE_PAY",
  GOOGLE_PAY = "GOOGLE_PAY",
}
