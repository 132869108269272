import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyCodec } from "../codecs/currency";
import {
  userResponseCodec,
  userFollowResponseCodec,
  followInfoResponseCodec,
  feedItemResponseCodec,
  nonprofitResponseCodec,
  tagResponseCodec,
  userTagResponseCodec,
  fundraiserCodec,
} from "../codecs/entities";
import { safeIntCodec } from "../codecs/number";
import { usernameCodec } from "../codecs/username";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec, listParamsCodec, listResponseCodec } from ".";

const userCountsCodec = t.union([
  t.type({}),
  t.type({
    fundCount: t.number,
    giftCount: t.number,
    likeCount: t.number,
    joinCount: t.number,
  }),
]);
const userTotalDonatedCodec = t.partial({
  totalDonated: t.type({
    amountInMinDenom: safeIntCodec,
    currency: currencyCodec,
  }),
});
const getUserParamsCodec = t.type({});
const getUserBodyCodec = t.type({});
const getUserResponseBodyCodec = t.intersection([
  t.type({
    user: userResponseCodec,
    followInfo: followInfoResponseCodec,
  }),
  userCountsCodec,
  userTotalDonatedCodec,
]);
export const getUserRouteSpec = makeRouteSpec({
  path: "/users/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: t.union([uuidCodec, usernameCodec]) }),
  paramsCodec: getUserParamsCodec,
  bodyCodec: getUserBodyCodec,
  responseBodyCodec: getUserResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export type UserProfileResponse = t.TypeOf<typeof getUserResponseBodyCodec>;

const getUserFundsParamsCodec = listParamsCodec;
const getUserFundsBodyCodec = t.type({});
const getUserFundsResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    fundraisers: t.array(fundraiserCodec),
  }),
  // TODO: move the tags to the type above after deploying
  t.partial({
    tags: t.array(tagResponseCodec),
  }),
  listResponseCodec,
]);
export const getUserFundsRouteSpec = makeRouteSpec({
  path: "/users/:id/lists",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: t.union([uuidCodec, usernameCodec]) }),
  paramsCodec: getUserFundsParamsCodec,
  bodyCodec: getUserFundsBodyCodec,
  responseBodyCodec: getUserFundsResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getUserFeedParamsCodec = listParamsCodec;
const getUserFeedBodyCodec = t.type({});
const getUserFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    nonprofitTags: t.array(tagResponseCodec),
    fundraisers: t.array(fundraiserCodec),
  }),
  listResponseCodec,
]);
export const getUserFeedRouteSpec = makeRouteSpec({
  path: "/users/:id/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: t.union([uuidCodec, usernameCodec]) }),
  paramsCodec: getUserFeedParamsCodec,
  bodyCodec: getUserFeedBodyCodec,
  responseBodyCodec: getUserFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export const getUserJoinsFeedRouteSpec = makeRouteSpec({
  path: "/users/:id/joins",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: t.union([uuidCodec, usernameCodec]) }),
  paramsCodec: getUserFeedParamsCodec,
  bodyCodec: getUserFeedBodyCodec,
  responseBodyCodec: getUserFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getUserLikesParamsCodec = listParamsCodec;
const getUserLikesBodyCodec = t.type({});
const getUserLikesResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
    nonprofitTags: t.array(tagResponseCodec),
    fundraisers: t.array(fundraiserCodec),
  }),
  listResponseCodec,
]);
export const getUserLikesRouteSpec = makeRouteSpec({
  path: "/users/:id/likes",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: t.union([uuidCodec, usernameCodec]) }),
  paramsCodec: getUserLikesParamsCodec,
  bodyCodec: getUserLikesBodyCodec,
  responseBodyCodec: getUserLikesResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getUserCausesParamsCodec = listParamsCodec;
const getUserCausesBodyCodec = t.type({});
const getUserCausesResponseBodyCodec = t.intersection([
  t.type({
    nonprofitTags: t.array(tagResponseCodec),
    tags: t.array(userTagResponseCodec),
  }),
  listResponseCodec,
]);
export const getUserCausesRouteSpec = makeRouteSpec({
  path: "/users/:id/causes",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getUserCausesParamsCodec,
  bodyCodec: getUserCausesBodyCodec,
  responseBodyCodec: getUserCausesResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getUserFollowsParamsCodec = t.type({});
const getUserFollowsBodyCodec = t.type({});
const getUserFollowsResponseBodyCodec = t.type({
  following: t.array(userFollowResponseCodec),
  followers: t.array(userFollowResponseCodec),
  users: t.array(userResponseCodec),
});
export const getUserFollowsRouteSpec = makeRouteSpec({
  path: "/users/:id/follows",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getUserFollowsParamsCodec,
  bodyCodec: getUserFollowsBodyCodec,
  responseBodyCodec: getUserFollowsResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});
