import { Big } from "big.js";

import { FundraiserResponse } from "../codecs/entities";
import { SafeInt } from "../codecs/number";
/**
 * Generated milestones follow this sequence:
 * 100, 150, 250, 400, 750, 1000, 1500 ...
 */
const MILESTONE_MULTIPLES = [1.5, 5 / 3, 1.6, 1.875, 4 / 3];

/**
 * Generates a milestone given the current giving total,
 * such that the milestone is always at least 100 and
 * always greater than the currentTotal.
 */
export function roundToMilestone(currentTotal: Big): SafeInt {
  if (currentTotal.lt(10000)) {
    return 10000 as SafeInt;
  }
  // initial goal amount is a power of 10 less than or equal to the current
  // total, we will iterate to find the smallest pretty goal amount that is
  // sufficiently larger than the current total
  let goalAmount = new Big(10).pow(
    Math.max(currentTotal.toFixed(0).length - 1, 1) // min goal amt $10
  );
  for (let i = 0; goalAmount.lte(currentTotal); ++i) {
    goalAmount = goalAmount
      .times(MILESTONE_MULTIPLES[i % MILESTONE_MULTIPLES.length])
      .round();
  }
  return goalAmount.toNumber() as SafeInt;
}

export function isOfficialFundraiser(
  fundraiser: Pick<FundraiserResponse, "nonprofitId" | "creatorNonprofitId">
): boolean {
  return fundraiser.nonprofitId === fundraiser.creatorNonprofitId;
}
