import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { PasswordInput } from "@components/PasswordInput";
import { QuestionAnswerLink } from "@components/QuestionAnswerLink";
import { SocialLoginButton } from "@components/SocialLoginButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import { ErrorMessage } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { ThankYouMessage } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou";
import { LabeledInputContainer } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou/Signup";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { IdentityProvider } from "@every.org/common/src/codecs/auth";
import { DonationFrequency } from "@every.org/common/src/entity/types";
import { ClientRouteName } from "@every.org/common/src/helpers/clientRoutes";

import {
  emailAuth0Login,
  EmailAuth0LoginStatus,
} from "src/context/AuthContext/actions";
import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { getMessageForError } from "src/errors";
import { colorCssVars, lightBgThemeCss } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";

export const DonationLogin = ({
  form,
  postDonationAction,
}: {
  form: UseFormReturn<DonateFormType>;
  postDonationAction: () => void;
}) => {
  const { handleSubmit } = form;
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();

  const frequency = form.watch("frequency");
  const email = form.watch("email");
  const paymentMethod = form.watch("paymentMethod");

  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>();

  const submit = handleSubmit(async (formValues) => {
    try {
      const auth0Result = await emailAuth0Login({
        email,
        password,
        redirectUrl: undefined,
        loginAfterDonationFlow: true,
      });
      if (auth0Result.status !== EmailAuth0LoginStatus.SUCCESS) {
        setError(auth0Result.errorMessage);
        return false;
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(getMessageForError(e));
      }
      return false;
    }
    return true;
  });

  const identityProvider = loggedInOrGuestUser?.identityProvider;

  return (
    <PageContainer>
      <ProcessContainer>
        <form onSubmit={submit} css={{ width: "100%" }}>
          <div
            css={[
              lightBgThemeCss,
              css`
                ${verticalStackCss.xxl};
                position: relative;
                ${cssForMediaSize({
                  min: MediaSize.LARGE,
                  css: css`
                    padding: ${spacing.l} ${spacing.none};
                  `,
                })};
              `,
            ]}
          >
            <div css={verticalStackCss.m}>
              <ThankYouMessage paymentMethod={paymentMethod} />
              <h3
                css={css`
                  font-weight: ${FontWeight.BOLD};
                  ${textSizeCss.m}
                `}
              >
                Log in to connect your donation to your existing account{" "}
                {identityProvider === IdentityProvider.USERNAME_PASSWORD &&
                  `(${email})`}
              </h3>
              {identityProvider === IdentityProvider.GOOGLE ||
              identityProvider === IdentityProvider.FACEBOOK ? (
                <div
                  css={css`
                    ${verticalStackCss.m}
                    text-align: center;
                  `}
                >
                  <h4
                    css={css`
                      font-weight: ${FontWeight.REGULAR};
                      ${textSizeCss.s}
                    `}
                  >
                    You previously logged in with:
                  </h4>
                  <div
                    css={[
                      verticalStackCss.xl,
                      css`
                        align-items: center;
                      `,
                    ]}
                  >
                    <SocialLoginButton
                      idp={
                        identityProvider === IdentityProvider.GOOGLE
                          ? IdentityProvider.GOOGLE
                          : IdentityProvider.FACEBOOK
                      }
                      data-tname={
                        identityProvider === IdentityProvider.GOOGLE
                          ? "loginGoogle"
                          : "loginFacebook"
                      }
                      loginAfterDonationFlow
                      css={css`
                        width: 160px;
                        height: 56px;
                      `}
                    >
                      {identityProvider === IdentityProvider.GOOGLE
                        ? "Google"
                        : "Facebook"}
                    </SocialLoginButton>
                    <Button
                      data-tname="donate--skipSocialLogin"
                      role={ButtonRole.TEXT_ONLY}
                      size={ButtonSize.MEDIUM}
                      onClick={{
                        kind: ButtonTargetKind.FUNCTION,
                        action: postDonationAction,
                      }}
                      css={css`
                        width: 160px;
                        align-self: end;
                      `}
                    >
                      Skip
                    </Button>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <LabeledInputContainer>
                    <h5
                      css={css`
                        font-weight: ${FontWeight.BOLD};
                        ${textSizeCss.s}
                      `}
                    >
                      Password
                    </h5>
                    <PasswordInput
                      autoComplete="password"
                      name="password"
                      data-tname="password"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      required
                      collapseDescriptionSpace
                    />
                  </LabeledInputContainer>
                  <QuestionAnswerLink
                    contentCss={css`
                      text-align: center;
                    `}
                    question="Forgot password?"
                    answer="Reset"
                    data-tname="resetPasswordPrompt"
                    routeName={ClientRouteName.RESET_PASSWORD}
                  />
                  <div
                    css={[verticalStackCss.s, { justifyContent: "flex-end" }]}
                  >
                    <Button
                      data-tname="donate--existingUserLogin"
                      role={ButtonRole.PRIMARY}
                      size={ButtonSize.MEDIUM}
                      onClick={{
                        kind: ButtonTargetKind.SUBMIT,
                      }}
                      disabled={form.formState.isSubmitting}
                      submitting={form.formState.isSubmitting}
                      css={css`
                        width: 130px;
                        align-self: end;
                      `}
                    >
                      Log in
                    </Button>
                    <Button
                      data-tname="donate--skipExistingUserLogin"
                      role={ButtonRole.TEXT_ONLY}
                      size={ButtonSize.MEDIUM}
                      onClick={{
                        kind: ButtonTargetKind.FUNCTION,
                        action: postDonationAction,
                      }}
                      css={css`
                        width: 130px;
                        align-self: end;
                      `}
                    >
                      Skip
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {error && (
                <div css={horizontalStackCss.xs}>
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/AlertIcon")
                    }
                    size={IconSize.MEDIUM}
                    display={IconDisplay.ERROR}
                  />
                  <ErrorMessage>{error}</ErrorMessage>
                </div>
              )}
              {frequency === DonationFrequency.MONTHLY && (
                <div css={horizontalStackCss.xs}>
                  <Icon
                    iconImport={() =>
                      import("@components/Icon/icons/AlertIcon")
                    }
                    size={IconSize.MEDIUM}
                    display={IconDisplay.SECONDARY}
                    css={css`
                      min-width: 24px;
                    `}
                  />
                  <p
                    css={css`
                      color: var(${colorCssVars.text.secondary});
                      ${textSizeCss.s}
                    `}
                  >
                    If you do not link this monthly donation to your existing
                    account you will need to contact customer service in order
                    to change or cancel it.
                  </p>
                </div>
              )}
            </div>
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
