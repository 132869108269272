import { IconSize } from "@components/Icon";
import { PopoverInfoButton } from "@components/PopoverInfoButton";
import {
  AdditiveAmountsPicker,
  AmountTextInput,
  AvailableCreditDescription,
} from "@components/TextInput/AmountTextInput";
import { useMaxMatchingAvailable } from "@components/donate/DonateV3/PaymentProcess/useMaxMatchingAvailable";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { useEffect } from "react";
import { FieldError } from "react-hook-form";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import {
  FundraiserResponse,
  NonEmptyMatchingCampaignResponse,
  NonprofitResponse,
  PaymentSourceResponse as PaymentSource,
} from "@every.org/common/src/codecs/entities";
import {
  DonationFlowPaymentOption,
  DonationFrequency,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import { CustomEvent } from "@every.org/common/src/helpers/analytics";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { KNOWN_MULTI_NONPROFIT_FUNDRAISERS } from "src/utilities/ssr/getFundraiserInitialProps";
import { trackEvent } from "src/utility/analytics";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

export const StyledLabel = styled.label`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

export function useDonationAmounts({
  nonprofit,
  fundraiser,
  minValue,
  suggestedAmountsFromUrl,
  paymentOption,
}: {
  nonprofit: NonprofitResponse | undefined;
  fundraiser?: FundraiserResponse;
  minValue: CurrencyValue;
  availableGivingCredit?: Big;
  suggestedAmountsFromUrl?: number[];
  paymentOption?: DonationFlowPaymentOption;
}): number[] {
  const isDafPaymentOption =
    paymentOption && paymentOption === DonationFlowPaymentOption.DAF;

  if (suggestedAmountsFromUrl?.length) {
    return suggestedAmountsFromUrl;
  }

  const donationAmounts =
    fundraiser?.metadata?.suggestedAmounts ??
    nonprofit?.metadata?.customDonationAmounts;

  if (donationAmounts) {
    return donationAmounts;
  }

  const defaultDonationAmounts = isDafPaymentOption
    ? [
        minValue.amount.times(20),
        minValue.amount.times(200),
        minValue.amount.times(2000),
      ]
    : [
        minValue.amount.times(4),
        minValue.amount.times(10),
        minValue.amount.times(25),
      ];
  return defaultDonationAmounts.map((n) => Number(n));
}

export const Amount = ({
  shorten,
  availableGivingCredit,
  minDonationValue,
  maxDonationValue,
  amount,
  amountBig,
  onChange,
  fieldError,
  nonprofit,
  fundraiser,
  selectedPaymentSource,
  setAmountErrorMessage,
  amountErrorMessage,
  frequency,
  paymentOption,
  paymentMethod,
  suggestedAmountsFromUrl,
  nonprofitMatchCampaign,
  toNonprofits,
  toNonprofitWeights,
}: {
  // Shorten error messages
  shorten?: boolean;
  availableGivingCredit?: Big;
  minDonationValue: CurrencyValue;
  maxDonationValue: CurrencyValue;
  amount: string;
  amountBig: Big;
  onChange: (amount: string) => void;
  fieldError?: FieldError;
  nonprofit?: ContextNonprofit;
  fundraiser?: FundraiserResponse;
  selectedPaymentSource?: PaymentSource;
  setAmountErrorMessage: (message: string | undefined) => void;
  amountErrorMessage: string | undefined;
  frequency?: DonationFrequency;
  paymentOption: DonationFlowPaymentOption;
  paymentMethod: PaymentMethod;
  suggestedAmountsFromUrl?: number[];
  nonprofitMatchCampaign?: NonEmptyMatchingCampaignResponse;
  toNonprofits?: string[];
  toNonprofitWeights?: number[];
}) => {
  const { matchingAmount, maxMatchingAvailable } = useMaxMatchingAvailable({
    amountBig,
    frequency,
    nonprofitMatchCampaign,
    toNonprofitId: nonprofit?.id,
    toNonprofits,
    toNonprofitWeights,
  });

  const donationAmounts = useDonationAmounts({
    nonprofit,
    fundraiser,
    minValue: minDonationValue,
    suggestedAmountsFromUrl,
    paymentOption,
  });

  useEffect(() => {
    setAmountErrorMessage(fieldError ? fieldError.message : undefined);
    fieldError && trackEvent(CustomEvent.AMOUNT_ERROR, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldError]);

  function setDonationAmount(newAmount: string) {
    onChange(newAmount);
  }

  function addDonationAmount(newAmount: number) {
    const increasedAmount = ((parseInt(amount) || 0) + newAmount).toString();
    onChange(increasedAmount);
  }

  const AmountPickerDescriptionElement = () => {
    return (
      <React.Fragment>
        <AdditiveAmountsPicker
          amounts={donationAmounts}
          addAmount={addDonationAmount}
          setAmountErrorMessage={setAmountErrorMessage}
          amountErrorMessage={amountErrorMessage}
        />
        {availableGivingCredit && (
          <AvailableCreditDescription
            availableGivingCredit={availableGivingCredit}
            setAmount={setDonationAmount}
            paymentMethod={paymentMethod}
          />
        )}
        {matchingAmount &&
          nonprofitMatchCampaign &&
          nonprofitMatchCampaign.availableForMatching > 0 && (
            <div
              css={[
                horizontalStackCss.xxs,
                css`
                  align-items: center;
                  margin-top: ${spacing.s};
                `,
              ]}
            >
              <h4
                css={css`
                  color: var(${colorCssVars.text.body});
                `}
              >
                <span
                  css={css`
                    color: var(${colorCssVars.accent.large});
                  `}
                >
                  +
                </span>{" "}
                {displayCurrencyValueInUserLocale({
                  currencyValue: {
                    currency: nonprofitMatchCampaign.currency,
                    amount: matchingAmount,
                  },
                })}
                {" / "}
                {displayCurrencyValueInUserLocale({
                  currencyValue: {
                    currency: nonprofitMatchCampaign.currency,
                    amount: maxMatchingAvailable,
                  },
                })}
                {" matched"}
              </h4>
              {fundraiser &&
                KNOWN_MULTI_NONPROFIT_FUNDRAISERS.has(fundraiser.slug) && (
                  <PopoverInfoButton
                    text="Pivotal will match donations 1:1 (up to $20,000 per donor) through noon ET Friday, December 13th. Total matching funds for each organization are capped at $500,000."
                    imgSize={IconSize.SMALL}
                  />
                )}
            </div>
          )}
      </React.Fragment>
    );
  };

  return (
    <fieldset css={verticalStackCss.s}>
      <StyledLabel htmlFor="donateAmountInput">Donation amount</StyledLabel>
      <AmountTextInput
        data-tname="donateAmountTextInputV2"
        id="donateAmountInput"
        name="donateAmountInput"
        shorten={!!shorten}
        setAmount={setDonationAmount}
        minValue={minDonationValue}
        maxValue={maxDonationValue}
        amount={amount}
        amountErrorMessage={fieldError?.message || amountErrorMessage}
        setAmountErrorMessage={setAmountErrorMessage}
        descriptionElement={<AmountPickerDescriptionElement />}
      />
    </fieldset>
  );
};
