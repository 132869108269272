import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  DonateInstructions,
  DonateInstructionsType,
} from "@components/donate/DonateV3/PaymentProcess/components/DonateInstructions";
import { DonationSummary } from "@components/donate/DonateV3/PaymentProcess/components/DonationSummary";
import { GoBackButton } from "@components/donate/DonateV3/PaymentProcess/components/GoBackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  StepperType,
  useStepper,
} from "@components/donate/DonateV3/PaymentProcess/useStepper";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
} from "@components/donate/DonateV3/types";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { getBrokerageDtcInfo } from "@every.org/common/src/helpers/brokerage";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";

function getInstructions({
  stockSymbol,
  stockAmount,
}: {
  stockSymbol: string;
  stockAmount: number;
}): DonateInstructionsType {
  const brokerageDtcInfo = getBrokerageDtcInfo(stockSymbol);
  return [
    ["Share total", `${stockAmount} shares (${stockSymbol})`],
    ["Deliver to", brokerageDtcInfo.institution],
    ["DTC number", brokerageDtcInfo.dtc],
    ["Account number", brokerageDtcInfo.account],
    ["Account name", brokerageDtcInfo.title],
  ];
}

interface StockInstructionsProps {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
}

export function StockInstructions({
  form,
  formContext,
  nonprofit,
  createOrUpdateDonationResult,
  handleConfirmedDonation,
}: StockInstructionsProps) {
  const navigate = useNavigate();

  const handleDonationComplete = useCallback(() => {
    if (
      createOrUpdateDonationResult &&
      !handleConfirmedDonation(createOrUpdateDonationResult)
    ) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.THANK_YOU]
      );
    }
  }, [createOrUpdateDonationResult, handleConfirmedDonation, navigate]);

  const stepper = useStepper(StepperType.STOCKS, 1);

  const stockSymbol = form.watch("stockSymbol");
  const stockAmount = form.watch("stockAmount");
  const totalStockAmount = formContext.totalStockAmount;

  if (!stockSymbol || !stockAmount || !totalStockAmount) {
    navigate(paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS], {
      replace: true,
    });
    return null;
  }

  return (
    <PageContainer>
      <GoBackButton form={form} formContext={formContext} />
      <ProcessContainer css={verticalStackCss.l}>
        {stepper}
        <DonateInstructions
          instructions={getInstructions({
            stockSymbol,
            stockAmount: totalStockAmount.toNumber(),
          })}
        />
        <DonationSummary
          form={form}
          formContext={formContext}
          paymentOption={DonationFlowPaymentOption.STOCKS}
          nonprofit={nonprofit}
        />
        <Button
          data-tname="donate-stock-instructions"
          css={{ alignSelf: "flex-end" }}
          role={ButtonRole.PRIMARY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: handleDonationComplete,
          }}
        >
          I will notify my broker
        </Button>
      </ProcessContainer>
    </PageContainer>
  );
}
