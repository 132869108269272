import { Card } from "@components/Card";
import { DonationCardCommentBody } from "@components/DonationCard/DonationCardCommentBody";
import { DonationCardProps } from "@components/DonationCard/types";
import { DonationHeaderCard } from "@components/DonationCardHeader";
import { Link } from "@components/Link";
import { CoverSection } from "@components/NewFundraiserCard/components/CoverSection";
import { TitleSection } from "@components/NewFundraiserCard/components/TitleSection";
import { coverTitleCss } from "@components/NewFundraiserCard/styles";
import {
  InnerLinkContainer,
  InnerSection,
} from "@components/feed/NewNonprofitCard/styles";
import { maxWidthFeedCardCss } from "@components/feed/shared";
import { FULL_SIZE_LINK_CLASS, feedCardCss } from "@components/feed/styles";
import { SkeletonDonationCard } from "@components/skeleton/SkeletonDonationCard";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types";
import React, { useCallback, useState } from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import {
  FundraiserFetchStatus,
  fetchFundraiser,
} from "src/pages/Fundraiser/fetchFundraiser";
import { logger } from "src/utility/logger";

export const SpecialFundraiserCard = styled(Card)`
  ${feedCardCss};
  ${maxWidthFeedCardCss};
  padding: 0;
  .${FULL_SIZE_LINK_CLASS} {
    z-index: 4;
  }
`;

export const SpecialFundraiserDonationCard: React.FCC<
  DonationCardProps & { fromFundraiserId: string }
> = (props) => {
  const {
    frequency,
    footer,
    removeTimestamp,
    createdAt,
    isPending,
    donationPageLinkInfo,
    fromFundraiserId,
    nonprofit,
    ...rest
  } = props;

  const [fundraiser, setFundraiser] = useState<
    FundraiserResponse | FundraiserFetchStatus
  >(FundraiserFetchStatus.FETCHING_FUNDRAISER);

  useAsyncEffect({
    asyncOperation: useCallback(
      () => fetchFundraiser(fromFundraiserId as UUID, nonprofit.id),
      [fromFundraiserId, nonprofit.id]
    ),
    handleResponse: useCallback(
      (
        response:
          | FundraiserResponse
          | FundraiserFetchStatus.FUNDRAISER_NOT_FOUND
      ) => {
        setFundraiser(response);
      },
      []
    ),
    handleError: useCallback(() => {
      setFundraiser(FundraiserFetchStatus.FUNDRAISER_NOT_FOUND);
    }, []),
  });

  if (fundraiser === FundraiserFetchStatus.FUNDRAISER_NOT_FOUND) {
    logger.error({
      message: "Fundraiser not found in SpecialFundraiserDonationCard",
      data: { fundraiserId: fromFundraiserId },
    });
    return null;
  }

  if (fundraiser === FundraiserFetchStatus.FETCHING_FUNDRAISER) {
    return <SkeletonDonationCard />;
  }

  return (
    <SpecialFundraiserCard>
      <InnerSection>
        <DonationHeaderCard
          {...rest}
          donationData={donationPageLinkInfo?.data}
          frequency={frequency}
          removeTimestamp={removeTimestamp}
          createdAt={createdAt}
          isPending={isPending}
        />
        <DonationCardCommentBody
          donationPageLinkInfo={donationPageLinkInfo}
          {...rest}
        />
      </InnerSection>
      <InnerLinkContainer>
        <CoverSection
          fundraiser={fundraiser}
          nonprofit={nonprofit}
          roundedBottom={!footer}
        >
          <TitleSection fundraiser={fundraiser} css={coverTitleCss} />
        </CoverSection>
        <Link
          title={`Feed donation card link to ${fundraiser.title}`}
          className={FULL_SIZE_LINK_CLASS}
          data-tname={"feedDonationCardLink"}
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.NONPROFIT_OR_CAUSE,
            tokens: {
              nonprofitSlug: fundraiser.slug,
            },
          })}
        >
          Feed donation card link to ${fundraiser.title}
        </Link>
      </InnerLinkContainer>
      {footer && <InnerSection>{footer}</InnerSection>}
    </SpecialFundraiserCard>
  );
};
