import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { ButtonRole, ButtonSize } from "@components/Button";
import { HeaderCardMetadataModalButton } from "@components/Profile/HeaderCardInfo";
import {
  ProfilePageHeaderCard,
  headerMetaWrapperStyles,
} from "@components/ProfilePageHeaderCard";
import {
  ShareButton,
  getSupportNonprofitShareData,
} from "@components/ShareButton";
import { NonprofitSupporters } from "@components/SupportersList/NonprofitSupporters";
import { LikeButtonWithCounter } from "@components/feed/LikeButton";
import { DonateButton } from "@components/layout/DonateButton";
import React from "react";

import {
  LikeableType,
  NonprofitDisplayType,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { displayNonprofitLocation } from "@every.org/common/src/helpers/nonprofitLocation";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { buttonCss as makeButtonCss } from "src/styles/button";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
  INSTAGRAM_POST_DIMENSIONS,
} from "src/utility/opengraph";

interface NonprofitHeaderCardProps {
  nonprofit: ContextNonprofit;
  className?: string;
}

/**
 * Full page width card component that shows information about a nonprofit.
 */
export const NonprofitHeaderCard: React.FCC<NonprofitHeaderCardProps> = ({
  nonprofit,
  className,
}) => {
  const {
    id,
    locationAddress,
    supporterCount,
    // TOOD(#4570) add a nonprofit like button instead (will not show 0)
    // eslint-disable-next-line unused-imports/no-unused-vars
    likesInfo,
    metadata,
  } = nonprofit;

  return (
    <ProfilePageHeaderCard
      className={className}
      title={nonprofit.name}
      subtitle={
        nonprofit.displayType === NonprofitDisplayType.DIRECTORY
          ? (
              <span
                css={{
                  maxWidth: "600px",
                  color: `var(${colorCssVars.text.secondary})`,
                }}
              >
                {nonprofit.description}
              </span>
            ) || undefined
          : locationAddress
          ? displayNonprofitLocation({ locationAddress })
          : undefined
      }
      avatar={(avatarCss) => (
        <NonprofitAvatar
          nonprofit={nonprofit}
          size={AvatarSize.MEDIUM}
          customCSS={avatarCss}
          disableLink
        />
      )}
      metadata={
        <div css={headerMetaWrapperStyles}>
          <div
            css={[
              horizontalStackCss.m,
              cssForMediaSize({
                min: MediaSize.MEDIUM,
                css: horizontalStackCss.xl,
              }),
            ]}
          >
            {supporterCount && (
              <HeaderCardMetadataModalButton
                headerCardMetadataProps={{
                  label: supporterCount > 1 ? "Donors" : "Donor",
                  data: supporterCount,
                  microdataInteractionType: "https://schema.org/DonateAction",
                }}
                modalButtonProps={{
                  buttonProps: {
                    "data-tname": "NonprofitProfile-Following",
                    role: ButtonRole.UNSTYLED,
                  },
                  modalHeader: "Donors",
                  modalContentLabel: "Donors Modal",
                  modalContent: function ModalContent(setIsModalOpen) {
                    return (
                      <NonprofitSupporters
                        nonprofitId={id}
                        supporterCount={supporterCount}
                        onClick={() => {
                          setIsModalOpen(false);
                        }}
                      />
                    );
                  },
                }}
              />
            )}
          </div>
          <div css={[horizontalStackCss.l, { alignItems: "center" }]}>
            <div css={[horizontalStackCss.s, { alignItems: "center" }]}>
              <ShareButton
                aria-label="Share nonprofit"
                data-tname="nonprofitShareButton"
                role={ButtonRole.TEXT_ONLY}
                hideText
                shareData={(medium) =>
                  getSupportNonprofitShareData({
                    medium,
                    nonprofit,
                    body: nonprofit?.description || undefined,
                    url: getRoutePath({
                      name: ClientRouteName.NONPROFIT_OR_CAUSE,
                      tokens: { nonprofitSlug: nonprofit.primarySlug },
                      format: URLFormat.ABSOLUTE,
                    }),
                  })
                }
                instagramImageCloudinaryParams={
                  nonprofitShareImageCloudinaryParams({
                    logoCloudinaryId: nonprofit.logoCloudinaryId,
                    coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
                    imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
                  }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
                }
                nonprofitSlug={nonprofit.primarySlug}
              />
              <LikeButtonWithCounter
                title="Like nonprofit"
                data-tname="likeButton"
                role={ButtonRole.TEXT_ONLY}
                size={ButtonSize.SMALL}
                type={LikeableType.NONPROFIT}
                id={nonprofit.id}
                likeCount={nonprofit.likesInfo?.count || null}
                loggedInUserLikes={
                  nonprofit.likesInfo?.hasLoggedInUserLiked || null
                }
              />
            </div>
            {nonprofit.displayType === NonprofitDisplayType.DIRECTORY &&
            !metadata?.showDonateButtons ? null : (
              <DonateButton
                data-tname="startDonateButton"
                isDisbursable={nonprofit.isDisbursable}
                primarySlug={nonprofit.primarySlug}
                size={ButtonSize.SMALL}
                buttonCss={cssForMediaSize({
                  min: MediaSize.MEDIUM,
                  css: makeButtonCss(ButtonRole.PRIMARY, ButtonSize.MEDIUM),
                })}
                toNonprofitPage
              />
            )}
          </div>
        </div>
      }
    />
  );
};
