import Big from "big.js";
import { useMemo } from "react";

import { NonEmptyMatchingCampaignResponse } from "@every.org/common/src/codecs/entities";
import {
  DonationFrequency,
  PaymentMethod,
} from "@every.org/common/src/entity/types";
import {
  currencyValueToMinimumDenominationAmount,
  minimumDenominationAmountToCurrencyValue,
} from "@every.org/common/src/helpers/currency";
import { calculateDonationMatchAmountValue } from "@every.org/common/src/helpers/donationMatch.js";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

export const useMaxMatchingAvailable = ({
  amountBig,
  nonprofitMatchCampaign,
  toNonprofitId,
  frequency,
  paymentMethod,
  toNonprofits,
  toNonprofitWeights,
}: {
  amountBig: Big;
  nonprofitMatchCampaign?: NonEmptyMatchingCampaignResponse;
  toNonprofitId?: ContextNonprofit["id"];
  frequency?: DonationFrequency;
  paymentMethod?: PaymentMethod;
  toNonprofits?: string[];
  toNonprofitWeights?: number[];
}):
  | {
      matchingAmount: Big;
      maxMatchingAvailable: Big;
      matchingWeights?: number[];
    }
  | {
      matchingAmount: undefined;
      maxMatchingAvailable: undefined;
      matchingWeights: undefined;
    } => {
  return useMemo(() => {
    if (!nonprofitMatchCampaign) {
      return {
        matchingAmount: undefined,
        maxMatchingAvailable: undefined,
        matchingWeights: undefined,
      };
    }
    const amount = currencyValueToMinimumDenominationAmount({
      value: { amount: amountBig, currency: nonprofitMatchCampaign.currency },
    });
    const ret = calculateDonationMatchAmountValue({
      campaign: nonprofitMatchCampaign,
      donationChargeAmount: amount,
      toNonprofitId,
      frequency: frequency ?? DonationFrequency.ONCE,
      paymentMethod: paymentMethod ?? PaymentMethod.STRIPE,
      chargePayToNonprofits: toNonprofits,
      chargePayToNonprofitWeights: toNonprofitWeights,
    });
    const matchingAmount = ret.donationChargeMatchAmount
      ? minimumDenominationAmountToCurrencyValue({
          currency: nonprofitMatchCampaign.currency,
          amount: ret.donationChargeMatchAmount,
        }).amount
      : new Big(0);
    const maxMatchingAvailable = ret.maxMatchingAvailable
      ? minimumDenominationAmountToCurrencyValue({
          currency: nonprofitMatchCampaign.currency,
          amount: ret.maxMatchingAvailable,
        }).amount
      : new Big(0);
    const matchingWeights =
      matchingAmount !== undefined && maxMatchingAvailable !== undefined
        ? ret.payToNonprofitMatches
        : [];
    return {
      matchingAmount,
      maxMatchingAvailable,
      matchingWeights,
    };
  }, [
    amountBig,
    nonprofitMatchCampaign,
    toNonprofitId,
    frequency,
    paymentMethod,
    toNonprofits,
    toNonprofitWeights,
  ]);
};
