import { Big } from "big.js";

import {
  FundraiserResponse,
  FundraiserRaisedResponse,
} from "@every.org/common/src/codecs/entities";
import { Currency, FundraiserType } from "@every.org/common/src/entity/types";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { roundToMilestone } from "@every.org/common/src/helpers/fundraisers";

import { fundraiserCurrentAmount } from "src/pages/Fundraiser";

export function preformRaisedAmounts({
  fundraiser,
  raisedData,
}: {
  fundraiser: FundraiserResponse;
  raisedData: FundraiserRaisedResponse;
}) {
  const {
    raisedMonthly,
    raisedOffline,
    currency: raisedCurrency,
    raisedMatches,
  } = raisedData;

  // Revert after the end of the Pivotal fundraiser (2024-12-15)
  // https://github.com/everydotorg/every.org/issues/17689
  const isMulti = fundraiser.type === FundraiserType.MULTIPLE_NONPROFITS;
  const _currentAmount = fundraiserCurrentAmount(raisedData);
  const _raisedOfflineAmount = raisedOffline
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedOffline,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  const currentAmount = isMulti
    ? _currentAmount.add(_raisedOfflineAmount)
    : _currentAmount;
  const raisedOfflineAmount = isMulti ? new Big(0) : _raisedOfflineAmount;

  const raisedMatchesAmount = raisedMatches
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedMatches,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  const totalAmount = new Big(currentAmount).add(raisedOfflineAmount);
  const totalAmountInMinDenom = new Big(
    raisedData.raised + raisedData.raisedMatches + raisedData.raisedOffline
  );

  const goalAmount =
    fundraiser.goalAmount && fundraiser.goalCurrency
      ? minimumDenominationAmountToCurrencyValue({
          currency: fundraiser.goalCurrency,
          amountInMinDenom: fundraiser.goalAmount,
        }).amount
      : raisedData?.goalAmount && raisedData?.currency
      ? minimumDenominationAmountToCurrencyValue({
          currency: raisedData.currency,
          amountInMinDenom: raisedData.goalAmount,
        }).amount
      : fundraiser.raisedData?.goalAmount && fundraiser.raisedData?.currency
      ? minimumDenominationAmountToCurrencyValue({
          currency: fundraiser.raisedData.currency,
          amountInMinDenom: fundraiser.raisedData.goalAmount,
        }).amount
      : minimumDenominationAmountToCurrencyValue({
          currency: Currency.USD,
          amountInMinDenom: roundToMilestone(totalAmountInMinDenom),
        }).amount;

  const raisedMounthly = raisedMonthly
    ? minimumDenominationAmountToCurrencyValue({
        amountInMinDenom: raisedMonthly,
        currency: raisedCurrency,
      }).amount
    : new Big(0);

  return {
    currentAmount,
    goalAmount,
    raisedMounthly,
    raisedOfflineAmount,
    totalAmount,
    raisedMatchesAmount,
  };
}
