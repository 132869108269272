import { LoadingPage } from "@components/LoadingPage";
import { MobileActionButtonsContainer } from "@components/MobileActionButtonsContainer";
import { Redirect } from "@components/Redirect";
import { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import { Big } from "big.js";
import { NextPage, NextPageContext } from "next";
import React, { useEffect, useMemo, useState } from "react";

import {
  DonationResponse,
  PersonalDonationResponse,
  DonationBoostResponse,
  DonationChargeResponse,
  PersonalDonationChargeResponse,
  NonprofitResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { usernameCodec } from "@every.org/common/src/codecs/username";
import { SHARED_PALETTE } from "@every.org/common/src/display/palette";
import {
  Currency,
  LikeableType,
  MediaType,
} from "@every.org/common/src/entity/types";
import { campaignPreviewVideoVariableEnabledSpec } from "@every.org/common/src/entity/types/configVariable";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";
import { constructCloudinaryUrl } from "@every.org/common/src/helpers/cloudinary";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { roundToMilestone } from "@every.org/common/src/helpers/fundraisers";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import { DonationMatchCampaignIdentifier } from "@every.org/common/src/routes/donate";

import { AvatarSize } from "src/components/Avatar";
import { NonprofitAvatar } from "src/components/Avatar/NonprofitAvatar";
import { UserAvatar } from "src/components/Avatar/UserAvatar";
import {
  ButtonRole,
  ButtonSize,
  fullWidthButtonCss,
} from "src/components/Button";
import { Card, HeaderCard } from "src/components/Card";
import { CardCover } from "src/components/CardCover";
import { ScrollableComment } from "src/components/DonationCard";
import { EditableText } from "src/components/EditableText";
import { FollowButton } from "src/components/FollowButton";
import { IconDisplay, IconSize } from "src/components/Icon";
import { Link } from "src/components/Link";
import { LoadingIcon } from "src/components/LoadingIndicator";
import { NonprofitLink } from "src/components/NonprofitLink";
import { ObservableIntersection } from "src/components/ObservableIntersection";
import { RecentContributorsCard } from "src/components/RecentContributorsCard";
import { ResizingSpan } from "src/components/ResizingSpan";
import { LabeledThermometer } from "src/components/Thermometer/LabeledThermometer";
import { UserLink } from "src/components/UserLink";
import { WebsiteLink } from "src/components/WebsiteLink";
import { NumJoinedButton } from "src/components/feed/DonationCardActions";
import { DonationShareButton } from "src/components/feed/DonationShareButton";
import { LikeButtonWithCounter } from "src/components/feed/LikeButton";
import { DefaultPageLayout } from "src/components/layout/DefaultPageLayout";
import { DonateButton } from "src/components/layout/DonateButton";
import { Grid, Cell } from "src/components/layout/GridSystem";
import {
  DefaultPageSection,
  headerCardPageSectionCss,
  PageSection,
} from "src/components/layout/PageSection";
import { setSignupSharedDonationCookie } from "src/context/AuthContext/actions";
import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import {
  useConfigVariable,
  UseConfigVariableStatus,
} from "src/context/ConfigVariableContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import {
  ViewDonationData,
  ViewDonationProvider,
} from "src/context/ViewDonationContext";
import { isNotFoundApiError } from "src/errors/ApiError";
import {
  GetDonationArgs,
  GetDonationStatus,
  useDonationFromContext,
} from "src/hooks/useDonationFromContext";
import {
  useEditableText,
  getSaveCommentCallback,
} from "src/hooks/useEditableText";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useNonprofitAnnouncement } from "src/hooks/useNonprofitAnnouncement";
import { useNonprofitMatchingCampaign } from "src/hooks/useNonprofitMatchingCampaign";
import {
  RegisteredNonprofit,
  RevenueIndicator,
} from "src/pages/Nonprofit/NonprofitPageV3/NonprofitPageV3AboutCard";
import { NotFoundPage } from "src/pages/NotFound";
import { LinkAppearance } from "src/styles/link";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  verticalStackCss,
  spacing,
} from "src/theme/spacing";
import {
  textSizeCss,
  TextSize,
  FontWeight,
  compositionTextCss,
} from "src/theme/text";
import {
  getUserDonationInitalProps,
  UserDonationsInitialProps,
} from "src/utilities/ssr/getUserDonationInitialProps";
import { useStatSigLayer } from "src/utility/abtesting";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  OPENGRAPH_DIMENSIONS,
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
} from "src/utility/opengraph";

function getPageMetas({
  nonprofit,
  user,
  commentText,
}: {
  nonprofit: ContextNonprofit;
  user: UserResponse;
  commentText: string | null;
}) {
  const { description, coverImageCloudinaryId, logoCloudinaryId } = nonprofit;
  const opengraphImageCloudinaryParams =
    nonprofitShareImageCloudinaryParams({
      logoCloudinaryId,
      userAvatarCloudinaryId: user.profileImageCloudinaryId,
      coverImageCloudinaryId,
      imageHeight: OPENGRAPH_DIMENSIONS.height,
    }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS;
  return removeUndefinedOrNullValues({
    "og:description": commentText || description,
    "og:image": constructCloudinaryUrl({
      ...opengraphImageCloudinaryParams,
      dimensions: OPENGRAPH_DIMENSIONS,
    }),
  });
}

export const AnnouncementHeader: React.FCC<{
  text?: React.ReactNode;
  className?: string;
}> = ({ text, className }) => {
  if (!text) {
    return null;
  }
  return (
    <PageSection className={className} contentCss={compositionTextCss}>
      <h2
        css={css`
          ${textSizeCss[TextSize.l]};
          margin-top: ${spacing.s};
          margin-bottom: ${spacing.s};
        `}
      >
        {text}
      </h2>
    </PageSection>
  );
};

function DonationInteractions({
  boost,
  donation,
  donationCharge,
}: {
  boost?: DonationBoostResponse;
  donation: DonationResponse | PersonalDonationResponse;
  donationCharge: DonationChargeResponse | PersonalDonationChargeResponse;
}) {
  return (
    <div
      css={[
        horizontalStackCss.xs,
        css`
          align-items: center;
          width: 100%;
        `,
      ]}
    >
      {boost && boost.numJoined > 0 ? (
        <NumJoinedButton
          data-tname="UserDonationPage-NumJoinedButton"
          joinedDonationId={donationCharge.donation.id}
          numJoined={boost.numJoined}
        />
      ) : null}
      <LikeButtonWithCounter
        aria-label="Like this donation"
        data-tname="likeButton"
        role={ButtonRole.TEXT_ONLY}
        size={ButtonSize.SMALL}
        type={LikeableType.DONATION}
        id={donation.id}
        likeCount={donationCharge.donation.likesInfo.count}
        loggedInUserLikes={
          donationCharge.donation.likesInfo.hasLoggedInUserLiked
        }
      />
    </div>
  );
}

interface UserDonationPageProps {
  donationArgs: GetDonationArgs;
  donationData?: ViewDonationData;
  matchCampaign?: DonationMatchCampaignIdentifier;
}

const UserDonationPage = (props: UserDonationPageProps) => {
  return (
    <ViewDonationProvider initialData={props.donationData}>
      <UserDonationPageImp {...props} />
    </ViewDonationProvider>
  );
};

function UserDonationPageImp({
  donationArgs,
  matchCampaign,
  donationData: donationDataFromProps,
}: UserDonationPageProps) {
  const router = useEdoRouter();
  const validateNonprofitSlug = donationArgs && "nonprofitSlug" in donationArgs;

  const donationData = useDonationFromContext(
    donationArgs,
    donationDataFromProps
  );
  const loggedInUser = useLoggedInUserOrUndefined();
  const showMobileActionButtons = useStatSigLayer(
    "mobile_donate_button_layer"
  )?.get("showButtons", false);

  useEffect(() => {
    if (donationData?.status === GetDonationStatus.SUCCESS) {
      const userId = donationData.user.id;

      setSignupSharedDonationCookie({
        donation: {
          userId: userId,
          nonprofitSlug: donationData.nonprofit.primarySlug,
          donationChargeId: donationData.donationCharge.id,
        },
      });

      // Replace the username or slug if it was using a casing that was not matching the db casing
      if (validateNonprofitSlug) {
        const regexToLowercase = new RegExp("/@[^/]+/[^/]+(.*)");
        const expectedPathname = router.pathname.replace(
          regexToLowercase,
          `/@${donationData.user.username}/${donationData.nonprofit.primarySlug}$1`
        );
        const params = router.search
          ? `?${new URLSearchParams(router.search).toString()}`
          : "";

        if (router.pathname !== expectedPathname) {
          router.replace(`${expectedPathname}${params}`);
        }
      }
    }
  }, [donationData, validateNonprofitSlug, router]);

  const [showActionsBackground, setShowActionsBackground] = useState(false);
  const nonprofitMatchingCampaign = useNonprofitMatchingCampaign({
    nonprofitId:
      donationData?.status === GetDonationStatus.SUCCESS
        ? donationData.nonprofit.id
        : undefined,
  });
  const nonprofitAnnouncement = useNonprofitAnnouncement(
    donationData && donationData.status === GetDonationStatus.SUCCESS
      ? donationData.nonprofit
      : undefined,
    nonprofitMatchingCampaign
  );

  if (!donationData || donationData.status === GetDonationStatus.LOADING) {
    return <LoadingPage />;
  }
  if (donationData.status === GetDonationStatus.FETCH_ERROR) {
    if (
      donationArgs &&
      "username" in donationArgs &&
      isNotFoundApiError(donationData.error)
    ) {
      return (
        <Redirect
          push={false}
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.USER,
            tokens: { username: donationArgs.username },
          })}
        />
      );
    }
    return <NotFoundPage />;
  }
  const { nonprofit, user, donationCharge } = donationData;
  const donation = donationCharge.donation;
  const { commentText } = donation;
  const { campaignGoalAmount, campaignBaselineAmount } =
    donation.donationCampaignData || {};
  const currentAmount = (
    campaignBaselineAmount
      ? minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: campaignBaselineAmount,
          currency: Currency.USD,
        }).amount
      : new Big(0)
  ).add(donationData.valueRaised?.total.amount || 0);

  // we choose to show one of the two to limit layout shifting
  const valueRaisedLoading = donationData.valueRaised === undefined;
  const thermometer = donationData.valueRaised && (
    <div css={{ position: "relative" }}>
      {valueRaisedLoading && (
        <LoadingIcon
          css={css`
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 50%);
          `}
          size={IconSize.MEDIUM}
          display={IconDisplay.ACCENT}
        />
      )}
      <LabeledThermometer
        // TODO #8478:  different currencies than the parent donation's are filtered. Assume that the total is normalized? Select currency?
        // TODO #8478: use the donation's currency? This requires returning the currency in the donation's data which might be sensitive info.
        // take up space while loading to minimize layout shift
        css={valueRaisedLoading && { visibility: "hidden" }}
        currency={Currency.USD}
        goalLabel={campaignGoalAmount ? "Goal" : "Next milestone"}
        {...(donationData.valueRaised
          ? {
              goalAmount: minimumDenominationAmountToCurrencyValue({
                amountInMinDenom:
                  campaignGoalAmount || roundToMilestone(currentAmount),
                currency: Currency.USD,
              }).amount,
              currentAmount,
              monthly:
                donationData.valueRaised.monthly &&
                donationData.valueRaised.monthly.amount,
            }
          : { dataMissing: true })}
      />
    </div>
  );

  const callToAction = (
    <React.Fragment>
      <h3>Turn this donation into a movement</h3>
      <ActionButtons
        nonprofit={nonprofit}
        donation={donation}
        matchCampaign={matchCampaign}
      />
      {thermometer}
    </React.Fragment>
  );
  // Override the title in the card if it's set in the metadata
  const headerTitle =
    donation.donationCampaignData?.campaignTitle ||
    `Join ${user.firstName} to support ${nonprofit.name}`;

  const HeaderCardContent: React.FCC<{
    content: React.ReactNode;
    customStyle?: CSSInterpolation;
  }> = ({ children, content, customStyle }) => (
    <HeaderCard
      css={[
        css`
          padding: 0;
          overflow: hidden;
        `,
        customStyle,
      ]}
    >
      <HeaderCardAsset nonprofit={nonprofit} donation={donation} />
      <div
        css={[
          verticalStackCss.m,
          css`
            padding: ${spacing.l};
            max-width: 100%;
          `,
        ]}
      >
        {children}
        <section css={verticalStackCss.l}>
          <h1 css={textSizeCss.l}>{headerTitle}</h1>
          {content}
        </section>
      </div>
    </HeaderCard>
  );

  return (
    <DefaultPageLayout
      pageTitle={`Join ${user.firstName} in supporting ${nonprofit.name}`}
      metas={getPageMetas({
        user,
        nonprofit,
        commentText,
      })}
      headerContent={<AnnouncementHeader text={nonprofitAnnouncement} />}
    >
      {/* TODO refactor for reusing components and not having them duplicated and hide */}
      <PageSection
        contentCss={[
          headerCardPageSectionCss,
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "none" },
          }),
        ]}
      >
        <HeaderCardContent content={thermometer}>
          <NonprofitHeading nonprofit={nonprofit} />
        </HeaderCardContent>
      </PageSection>
      <DefaultPageSection>
        <div css={verticalStackCss.m}>
          <Grid>
            <Cell numCols={6} numColsLarge={8}>
              <div css={verticalStackCss.m}>
                <HeaderCardContent
                  content={
                    <React.Fragment>
                      <HighlightedComment
                        donation={donation}
                        user={user}
                        nonprofit={nonprofit}
                      />
                      <DonationInteractions
                        donation={donation}
                        donationCharge={donationCharge}
                        boost={donationData.boost}
                      />
                    </React.Fragment>
                  }
                  customStyle={cssForMediaSize({
                    max: MediaSize.MEDIUM,
                    css: { display: "none" },
                  })}
                >
                  <UserHeading user={user} />
                </HeaderCardContent>
                <div
                  css={[
                    cssForMediaSize({
                      min: MediaSize.LARGE,
                      css: { display: "none" },
                    }),
                    cssForMediaSize({
                      max: MediaSize.MEDIUM,
                      css: verticalStackCss.m,
                    }),
                  ]}
                >
                  <Card css={verticalStackCss.m}>
                    <UserHeading user={user} />
                    <HighlightedComment
                      user={user}
                      donation={donation}
                      nonprofit={nonprofit}
                    />
                    <DonationInteractions
                      donation={donation}
                      donationCharge={donationCharge}
                      boost={donationData.boost}
                    />
                  </Card>
                  <Card css={verticalStackCss.m}>
                    <NonprofitHeading nonprofit={nonprofit} />
                    <NonprofitDetails nonprofit={nonprofit} />
                  </Card>
                </div>
                <RecentContributorsCard
                  nonprofit={nonprofit}
                  excludeDonationIds={new Set([donationCharge.donation.id])}
                  sortChronologically
                />
              </div>
            </Cell>
            <Cell
              numCols={6}
              numColsLarge={4}
              css={cssForMediaSize({
                max: MediaSize.MEDIUM,
                css: { display: "none" },
              })}
            >
              <section css={verticalStackCss.m}>
                <Card css={verticalStackCss.m}>{callToAction}</Card>
                <Card css={verticalStackCss.m}>
                  <NonprofitHeading nonprofit={nonprofit} />
                  <NonprofitDetails nonprofit={nonprofit} />
                </Card>
              </section>
            </Cell>
          </Grid>
          {showMobileActionButtons ? (
            <MobileActionButtonsContainer alwaysShow>
              <div css={horizontalStackCss.s}>
                <DonationShareButton
                  hideText
                  donation={donation}
                  role={ButtonRole.TEXT_ONLY}
                  size={ButtonSize.MEDIUM}
                  isFeedCardShare={false}
                />
                <DonateButton
                  data-tname="startDonateButton"
                  css={fullWidthButtonCss}
                  size={ButtonSize.MEDIUM}
                  isDisbursable={nonprofit.isDisbursable}
                  primarySlug={nonprofit.primarySlug}
                  toNonprofitPage={false}
                  matchCampaign={matchCampaign}
                  donationToJoinId={
                    donation.fromUserId !== loggedInUser?.id
                      ? donation.id
                      : undefined
                  }
                  userToJoinId={
                    donation.fromUserId !== loggedInUser?.id
                      ? donation.fromUserId
                      : undefined
                  }
                >
                  Donate
                </DonateButton>
              </div>
            </MobileActionButtonsContainer>
          ) : (
            <div
              css={[
                css`
                  position: sticky;
                  bottom: 0;
                  background: ${showActionsBackground
                    ? "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%)"
                    : "transparent"};
                  padding: ${showActionsBackground ? spacing.xl : spacing.s}
                    ${spacing.s} ${spacing.s} ${spacing.s};
                  margin: -${spacing.s};
                  z-index: 4;
                  ${cssForMediaSize({
                    min: MediaSize.LARGE,
                    css: { display: "none" },
                  })}
                `,
              ]}
            >
              <ActionButtons
                matchCampaign={matchCampaign}
                donation={donation}
                nonprofit={nonprofit}
                fillShare={showActionsBackground}
                horizontal
              />
            </div>
          )}
          <ObservableIntersection onChange={setShowActionsBackground} />
        </div>
      </DefaultPageSection>
    </DefaultPageLayout>
  );
}

const HeaderCardAsset = ({
  nonprofit,
  donation,
}: {
  nonprofit: NonprofitResponse;
  donation: DonationResponse | PersonalDonationResponse;
}) => {
  const campaignPreviewVideoEnabled = useConfigVariable(
    campaignPreviewVideoVariableEnabledSpec
  );

  if (donation.donationCampaignData?.campaignCoverAsset) {
    const { campaignCoverAsset } = donation.donationCampaignData;
    switch (campaignCoverAsset.mediaType) {
      case MediaType.IMAGE: {
        const { cloudinaryId } = campaignCoverAsset;
        return (
          <CardCover
            coverImageCloudinaryId={cloudinaryId}
            height={160}
            largeScreenHeight={320}
            editable={false}
            priority
          />
        );
      }
      case MediaType.VIDEO: {
        const { videoUrl } = campaignCoverAsset;
        if (
          campaignPreviewVideoEnabled.status ===
            UseConfigVariableStatus.SUCCESS &&
          campaignPreviewVideoEnabled.value
        ) {
          return (
            <Card
              css={css`
                position: relative;
                /* Defaulting to 16:9 ratio */
                padding: 0 0 56.25% 0;
                overflow: hidden;
              `}
            >
              <iframe
                title="embedded-video"
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                `}
                id="smh_player"
                width="100%"
                height="100%"
                src={videoUrl}
                allowFullScreen
                allow="autoplay *; fullscreen *; encrypted-media *"
                frameBorder="0"
              />
            </Card>
          );
        }
      }
    }
  }

  if (nonprofit.coverImageCloudinaryId) {
    return (
      <CardCover
        coverImageCloudinaryId={nonprofit.coverImageCloudinaryId}
        height={160}
        largeScreenHeight={320}
        editable={false}
        priority
        isLargeCard
      />
    );
  }

  return (
    <div
      css={css`
        height: ${spacing.xxxl};
        background-color: ${SHARED_PALETTE.nonprofitProfileHeaderBackground};
      `}
    />
  );
};

function UserHeading({ user }: { user: UserResponse }) {
  return (
    <section css={horizontalStackCss.m}>
      <UserAvatar user={user} size={AvatarSize.MEDIUM} />
      <div
        css={[
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: [
              horizontalStackCss.m,
              css`
                flex-grow: 1;
                align-items: center;
              `,
            ],
          }),
          cssForMediaSize({
            max: MediaSize.MEDIUM,
            css: [
              verticalStackCss.s,
              css`
                align-items: flex-start;
              `,
            ],
          }),
        ]}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {user.username ? (
            <Link
              data-tname="user"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.USER,
                tokens: { username: user.username },
              })}
              appearance={LinkAppearance.UNSTYLED}
            >
              <b
                css={[
                  textSizeCss.xs,
                  css`
                    font-weight: ${FontWeight.MEDIUM};
                    color: var(${colorCssVars.accent.small});
                  `,
                ]}
              >
                @{user.username}
              </b>
            </Link>
          ) : null}
          <UserLink
            user={user}
            appearance={LinkAppearance.HYPERLINK_UNCOLORED}
          />
        </div>

        <FollowButton
          size={ButtonSize.SMALL}
          css={css`
            margin-left: auto;
            ${cssForMediaSize({
              max: MediaSize.MEDIUM,
              css: { display: "none" },
            })}
          `}
          toFollowUserId={user.id}
        />
      </div>
    </section>
  );
}

function HighlightedComment({
  donation,
  user,
  nonprofit,
}: {
  donation: DonationResponse | PersonalDonationResponse;
  user: UserResponse;
  nonprofit: ContextNonprofit;
}) {
  const { commentText: initialText, fromUserId, id } = donation;

  const loggedInUser = useLoggedInUserOrUndefined();
  const isOwnDonation = loggedInUser?.id === fromUserId;

  const saveAction = useMemo(() => getSaveCommentCallback(id), [id]);
  const commentInterface = useEditableText({
    initialText,
    saveAction,
    logTag: "UserDonationPage-Comment",
  });

  return (
    <div css={verticalStackCss.m} id="comment">
      <ScrollableComment>
        <EditableText
          editCommentCss={css`
            max-width: 100%;
          `}
          data-tname="commentInterface"
          textToDisplay={commentInterface.text}
          disabled={!isOwnDonation}
          editableTextInterface={commentInterface}
          saveOnBlur
        />
      </ScrollableComment>
    </div>
  );
}

const ActionButtons: React.FCC<{
  donation: DonationResponse | PersonalDonationResponse;
  nonprofit: ContextNonprofit;
  horizontal?: boolean;
  matchCampaign?: DonationMatchCampaignIdentifier;
  fillShare?: boolean;
}> = ({ donation, nonprofit, horizontal, matchCampaign, fillShare }) => {
  const loggedInUser = useLoggedInUserOrUndefined();

  return (
    <div
      css={[
        horizontal ? horizontalStackCss.s : verticalStackCss.s,
        css`
          align-items: stretch;
          > * {
            display: flex;
            flex-basis: 100%;
          }
        `,
      ]}
    >
      <DonationShareButton
        css={[
          fullWidthButtonCss,
          cssForMediaSize({
            max: MediaSize.X_SMALL,
            css: css`
              svg {
                display: none;
              }
            `,
          }),
          fillShare &&
            css`
              button {
                background-color: var(${colorCssVars.background.normal});
              }
            `,
        ]}
        hideText={false}
        donation={donation}
        role={ButtonRole.SECONDARY}
        size={ButtonSize.MEDIUM}
        isFeedCardShare={false}
      />
      <DonateButton
        data-tname="startDonateButton"
        css={[
          fullWidthButtonCss,
          css`
            span {
              height: 100%;
            }
          `,
        ]}
        size={ButtonSize.MEDIUM}
        isDisbursable={nonprofit.isDisbursable}
        primarySlug={nonprofit.primarySlug}
        toNonprofitPage={false}
        matchCampaign={matchCampaign}
        donationToJoinId={
          donation.fromUserId !== loggedInUser?.id ? donation.id : undefined
        }
        userToJoinId={
          donation.fromUserId !== loggedInUser?.id
            ? donation.fromUserId
            : undefined
        }
      >
        <span
          css={css`
            /* Wrapper container to make it equal height to Share button */
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          Donate
        </span>
      </DonateButton>
    </div>
  );
};

function NonprofitDetails({ nonprofit }: { nonprofit: ContextNonprofit }) {
  return (
    <div css={verticalStackCss.m}>
      <p>{nonprofit.description}</p>
      {nonprofit.revenueAmt && (
        <RevenueIndicator revenueAmt={nonprofit.revenueAmt} />
      )}
      {nonprofit.websiteUrl && (
        <WebsiteLink
          data-tname="nonprofitWebsiteLink"
          websiteUrl={nonprofit.websiteUrl}
        />
      )}
    </div>
  );
}

function NonprofitHeading({ nonprofit }: { nonprofit: ContextNonprofit }) {
  return (
    <section css={verticalStackCss.s}>
      <div
        css={cssForMediaSize({
          min: MediaSize.LARGE,
          css: css`
            ${horizontalStackCss.m};
            justify-content: space-between;
          `,
        })}
      >
        <div
          css={[
            horizontalStackCss.m,
            css`
              align-items: center;
              flex-grow: 1;
              flex-wrap: nowrap;
            `,
          ]}
        >
          <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.MEDIUM} />
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            {/* Set the text size smaller so that the resizing span can dictate height */}
            <h4 css={textSizeCss.s}>
              <NonprofitLink
                appearance={LinkAppearance.UNSTYLED}
                nonprofit={nonprofit}
                data-tname="nonprofitName"
              >
                <ResizingSpan
                  css={{ width: "100%" }}
                  maxTextSize={TextSize.m}
                  minTextSize={TextSize.s}
                  compressor={nonprofit.name.length > 24 ? 2 : 1} // Arbitrarily chose to shrink faster based on what looks good
                >
                  {nonprofit.name}
                </ResizingSpan>
              </NonprofitLink>
            </h4>
            {nonprofit.supporterCount && nonprofit.supporterCount > 1 ? (
              <span
                css={css`
                  font-weight: ${FontWeight.REGULAR};
                  color: var(${colorCssVars.text.secondary});
                `}
              >
                <b
                  css={css`
                    font-weight: ${FontWeight.MEDIUM};
                    color: var(${colorCssVars.text.body});
                  `}
                >
                  {nonprofit.supporterCount}
                </b>{" "}
                supporters
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <RegisteredNonprofit nonprofit={nonprofit} />
    </section>
  );
}

export const DefaultUserDonationPage: NextPage<UserDonationsInitialProps> = ({
  shortId,
  username,
  nonprofitSlug,
  donation,
}) => {
  const usernameLowerCase = username?.toLowerCase();
  const donationArgs = useMemo<GetDonationArgs | null>(
    () =>
      nonprofitSlug && usernameCodec.is(usernameLowerCase)
        ? {
            shortId: shortId || 1,
            nonprofitSlug,
            username: usernameLowerCase,
          }
        : null,
    [nonprofitSlug, shortId, usernameLowerCase]
  );
  if (!donationArgs) {
    return <NotFoundPage />;
  }

  return (
    <UserDonationPage donationArgs={donationArgs} donationData={donation} />
  );
};

export const getServerSideProps = async (ctx: NextPageContext) => {
  const props = await getUserDonationInitalProps(ctx);

  return { props };
};

// Use default export to support React Lazy Loading.
export default DefaultUserDonationPage;
