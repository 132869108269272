import { Icon, IconDisplay, IconSize } from "@components/Icon";
import Popover from "@components/Popover/component";
import styled from "@emotion/styled";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";

import { colorCssVars } from "src/theme/color";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

const StyledRaisedAmountFigure = styled.figure`
  &,
  ul {
    ${verticalStackCss.xxs}
  }
  b {
    font-weight: ${FontWeight.BOLD};
  }
`;

export interface RaisedAmountTooltipProps {
  raisedOffline?: CurrencyValue;
  raisedMatches?: CurrencyValue;
  monthlyAnnualTotal?: CurrencyValue;
}

export const RaisedAmountTooltip = (props: RaisedAmountTooltipProps) => {
  const raisedOfflineDisplay = props.raisedOffline &&
    props.raisedOffline.amount.gt(0) && (
      <li>
        <b>
          {displayCurrencyValueInUserLocale({
            currencyValue: props.raisedOffline,
          })}
        </b>{" "}
        raised offline
      </li>
    );

  const raisedMatchesDisplay = props.raisedMatches &&
    props.raisedMatches.amount.gt(0) && (
      <li>
        <b>
          {displayCurrencyValueInUserLocale({
            currencyValue: props.raisedMatches,
          })}
        </b>{" "}
        in matching funds
      </li>
    );

  const monthlyAnnualTotalDisplay = props.monthlyAnnualTotal &&
    props.monthlyAnnualTotal.amount.gt(0) && (
      <li>
        <b>
          {displayCurrencyValueInUserLocale({
            currencyValue: props.monthlyAnnualTotal,
          })}
        </b>{" "}
        annual recurring total*
        <br />
        <span
          css={{
            color: `var(${colorCssVars.text.secondary})`,
            marginTop: spacing.xxxs,
          }}
        >
          *Total funds that current donations would raise in the next 12 months,
          helping nonprofits operate sustainably and plan ahead
        </span>
      </li>
    );

  if (
    !raisedOfflineDisplay &&
    !raisedMatchesDisplay &&
    !monthlyAnnualTotalDisplay
  ) {
    return null;
  }

  const content = (
    <StyledRaisedAmountFigure>
      <figcaption>Includes:</figcaption>
      <ul>
        {raisedOfflineDisplay}
        {raisedMatchesDisplay}
        {monthlyAnnualTotalDisplay}
      </ul>
    </StyledRaisedAmountFigure>
  );

  return (
    <Popover
      placement="bottom-start"
      css={{ cursor: "pointer" }}
      contentCss={{ maxWidth: "300px" }}
      content={content}
    >
      <Icon
        iconImport={() => import("@components/Icon/icons/InfoIcon")}
        display={IconDisplay.SECONDARY}
        size={IconSize.X_SMALL}
      />
    </Popover>
  );
};
