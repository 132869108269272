import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyCodec, currencyValueCodec } from "../codecs/currency";
import {
  nonprofitResponseCodec,
  userResponseCodec,
  feedItemResponseCodec,
  appliedNonprofitEditResponseCodec,
  nonprofitEditDataCodec,
  personalDonationResponseCodec,
  tagResponseCodec,
  nonprofitAdminResponseCodec,
  giftCardCampaignCodec,
  nonprofitAdminAndInvitesResponseCodec,
  matchingCampaignResponseCodec,
  nonprofitAdminNotificationSettingsResponseCodec,
  nonprofitAdminNotificationSettingsConfigurationCodec,
} from "../codecs/entities";
import { slugCodec, webhookUrlCodec } from "../codecs/nonprofit";
import {
  nonNegativeIntegerFromStringCodec,
  safeIntCodec,
} from "../codecs/number";
import { disbursementTypeCodec } from "../entity/types";
import { HttpMethod } from "../helpers/http";

import { listParamsCodec, listResponseCodec, makeRouteSpec } from ".";

export const likeNonprofitRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/like",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const unlikeNonprofitRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/like",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

const getNonprofitParamsCodec = t.type({});
const getNonprofitBodyCodec = t.type({});
const getNonprofitResponseCodec = t.type({
  nonprofit: nonprofitResponseCodec,
  supporterCount: t.number,
  endorsedNonprofits: t.union([t.array(nonprofitResponseCodec), t.null]),
  createdFunds: t.union([t.array(nonprofitResponseCodec), t.null]),
  endorserNonprofits: t.union([t.array(nonprofitResponseCodec), t.null]),
  eligibleDonationRecipientNonprofits: t.union([
    t.array(nonprofitResponseCodec),
    t.null,
  ]),
  loggedInUserDonations: t.union([
    t.array(personalDonationResponseCodec),
    t.null,
  ]),
  nonprofitTags: t.array(tagResponseCodec),
  fundMetadata: t.union([
    t.partial({
      donationChargesCount: t.number,
      endorsedNonprofitsCount: t.number,
      allTimeRaised: currencyValueCodec,
    }),
    t.null,
  ]),
  giftCardCampaign: t.union([t.null, giftCardCampaignCodec]),
  donatedCurrencies: t.union([t.null, t.array(currencyCodec)]),
  donationCount: t.number,
});
export const getNonprofitRouteSpec = makeRouteSpec({
  path: "/nonprofits/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: getNonprofitParamsCodec,
  bodyCodec: getNonprofitBodyCodec,
  responseBodyCodec: getNonprofitResponseCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getNonprofitFeedParamsCodec = listParamsCodec;
const getNonprofitFeedBodyCodec = t.type({});
const getNonprofitFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
  }),
  listResponseCodec,
]);
export const getNonprofitFeedRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getNonprofitFeedParamsCodec,
  bodyCodec: getNonprofitFeedBodyCodec,
  responseBodyCodec: getNonprofitFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 5,
  },
});

const getFundFeedParamsCodec = listParamsCodec;
const getFundFeedBodyCodec = t.type({});
const getFundFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
  }),
  listResponseCodec,
]);
export const getFundFeedRouteSpec = makeRouteSpec({
  path: "/funds/:id/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getFundFeedParamsCodec,
  bodyCodec: getFundFeedBodyCodec,
  responseBodyCodec: getFundFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

const getNonprofitNeighboursFeedParamsCodec = t.intersection([
  listParamsCodec,
  t.partial({ kinds: t.string }),
]);
const getNonprofitNeighboursFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    nonprofits: t.array(nonprofitResponseCodec),
  }),
  listResponseCodec,
]);

export type NonprofitNeighboursFeedResponse = t.TypeOf<
  typeof getNonprofitNeighboursFeedResponseBodyCodec
>;

export const getNonprofitNeighboursFeedRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/neighbours/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getNonprofitNeighboursFeedParamsCodec,
  bodyCodec: getNonprofitFeedBodyCodec,
  responseBodyCodec: getNonprofitNeighboursFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 60,
    alsoCacheIfAuthenticated: true,
  },
});

const getNonprofitSupportersResponseBodyCodec = t.type({
  users: t.array(userResponseCodec),
  hasMore: t.boolean,
});
export const getNonprofitSupportersRouteSpec = makeRouteSpec({
  path: "/nonprofitSupporters/:id",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: getNonprofitSupportersResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export type GetNonprofitSupportersResponse = t.TypeOf<
  typeof getNonprofitSupportersRouteSpec.responseBodyCodec
>;

const donationsBalanceResponseCodec = t.intersection([
  t.type({
    allTimeBalance: currencyValueCodec,
    allTimeRecurring: currencyValueCodec,
    availableBalance: currencyValueCodec,
    currentBalance: currencyValueCodec,
    annualRecurringRevenue: currencyValueCodec,
    monthlyRecurringRevenue: currencyValueCodec,
    usersFundraising: t.number,
    recurringSupporterCount: t.number,
    giftCount: t.number,
    supporterCount: t.number,
    csvData: t.type({
      // The CSVs data are provided as a dense matrix in which the first row is the header.
      // The columns are not enforced.
      // E.g.: [
      //   ["Name", "Amount"],
      //   ["Mike", "$10.00"],
      //   ["Luna", "$12.00"],
      // ]
      ok: t.array(t.array(t.string)),
      error: t.array(t.array(t.string)),
    }),
  }),
  // TODO: move to the group above and remove the intersection + partial after deploy
  t.partial({
    repeatedSupporterCount: t.number,
  }),
]);
export type DonationsBalanceResponse = t.TypeOf<
  typeof donationsBalanceResponseCodec
>;

const webhookResponseCodec = t.type({
  webhookUrl: t.union([webhookUrlCodec, t.null]),
  authorizationToken: t.union([t.string, t.null]),
});
export type WebhookResponse = t.TypeOf<typeof webhookResponseCodec>;
export const getNonprofitWebhookRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/admin/webhook",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: webhookResponseCodec,
});

export const updateNonprofitWebhookRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/admin/webhook",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({ webhookUrl: t.union([t.string, t.null]) }),
  responseBodyCodec: webhookResponseCodec,
});

export const nonprofitDonationsBalanceRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/admin/donationsBalance",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  // TODO #8478: make this non-optional after deploying
  paramsCodec: t.partial({ currency: currencyCodec }),
  bodyCodec: t.type({}),
  responseBodyCodec: donationsBalanceResponseCodec,
});

const disbursementBreakdownResponseCodec = t.array(
  t.type({
    hash: t.string,
    metadataString: t.union([t.string, t.null]),
    value: currencyValueCodec,
  })
);
export type DisbursementBreakdownResponse = t.TypeOf<
  typeof disbursementBreakdownResponseCodec
>;
export const nonprofitDisbursementBreakdownRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/superAdmin/disbursementBreakdown",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  bodyCodec: t.type({}),
  paramsCodec: t.type({}),
  responseBodyCodec: disbursementBreakdownResponseCodec,
});

export const createDisbursementRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/superAdmin/disburse",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({
    nonprofitId: uuidCodec,
  }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({
    value: currencyValueCodec,
    disbursementType: disbursementTypeCodec,
    disbursementAccountId: t.string,
    disbursementSource: t.union([t.string, t.null]),
  }),
  responseBodyCodec: t.array(t.record(t.string, t.unknown)),
});

const stripeAccountLinkResponseCodec = t.type({
  applicationSubmitted: t.boolean,
  email: t.union([t.string, t.null]),
  isConnected: t.boolean,
  isReadyToBeDisbursed: t.boolean,
  stripeAccountLinkURL: t.string,
});
export type StripeAccountLinkResponse = t.TypeOf<
  typeof stripeAccountLinkResponseCodec
>;
export const stripeAccountLinkGetRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/stripeLink",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: stripeAccountLinkResponseCodec,
});
export const stripeAccountLinkPostRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/stripeLink",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: stripeAccountLinkResponseCodec,
});

export const createNonprofitEditRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/edits",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.intersection([
    nonprofitEditDataCodec,
    t.type({
      tagIdsToAdd: t.array(uuidCodec),
      tagIdsToRemove: t.array(uuidCodec),
    }),
  ]),
  responseBodyCodec: appliedNonprofitEditResponseCodec,
});

export const createNonprofitRouteSpec = makeRouteSpec({
  path: "/nonprofits",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  bodyCodec: t.intersection([
    t.type({
      ein: t.union([t.string, t.null]),
      name: t.string,
      countryCode: t.string,
    }),
    t.partial({
      projectParentNonprofitId: uuidCodec,
      disbursementMetadata: t.record(t.string, t.string),
    }),
  ]),
  responseBodyCodec: nonprofitResponseCodec,
});

export const requestNonprofitAdminAccessRouteSpec = makeRouteSpec({
  path: "/nonprofits/:id/admin/request",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: nonprofitAdminResponseCodec,
});

export const revokeNonprofitAdminAccessRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/admin/:userId",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec, userId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: nonprofitAdminResponseCodec,
});

const getNonprofitMembersResponseBodyCodec = t.type({
  users: t.array(userResponseCodec),
  hasMore: t.boolean,
});
export const getNonprofitMembersRouteSpec = makeRouteSpec({
  path: "/nonprofit/:id/members",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: getNonprofitMembersResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
});

export const emailEmbedDonateButtonRequestRouteSpec = makeRouteSpec({
  path: "/:nonprofitSlug/emailEmbedDonateButtonRequest",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({
    nonprofitSlug: t.string,
  }),
  paramsCodec: t.type({}),
  bodyCodec: t.intersection([
    t.type({
      emailAddress: t.string,
    }),
    t.partial({
      method: t.string,
      donateUrl: t.string,
      format: t.union([t.literal("link"), t.literal("button")]),
      popup: t.boolean,
    }),
  ]),
  responseBodyCodec: t.type({}),
});

export const getNonprofitAdminInvitesRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/admin/invites",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: nonprofitAdminAndInvitesResponseCodec,
});

export const postNonprofitAdminInvitesRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/admin/invites",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({ inviteeIdentifier: t.string }),
  responseBodyCodec: nonprofitAdminAndInvitesResponseCodec,
});

export const postNonprofitAdminResendInviteRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/admin/invites/resend",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({ existingAdminInviteId: uuidCodec }),
  responseBodyCodec: nonprofitAdminAndInvitesResponseCodec,
});

export const deleteNonprofitAdminInvitesRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/admin/invites",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({ inviteeIdentifier: t.string }),
  responseBodyCodec: nonprofitAdminAndInvitesResponseCodec,
});

export const getNonprofitMatchingCampaignAndCheckDisbursabilityRouteSpec =
  makeRouteSpec({
    path: "/nonprofits/:nonprofitId/matchingCampaigns",
    method: HttpMethod.GET,
    authenticated: false,
    tokensCodec: t.type({ nonprofitId: uuidCodec }),
    paramsCodec: t.partial({
      disbursabilityCheck: BooleanFromString,
      fundraiserId: uuidCodec,
    }),
    bodyCodec: t.type({}),
    responseBodyCodec: matchingCampaignResponseCodec,
    publicRoute: {
      publicCacheLengthMinutes: 0.1,
    },
  });

export const getNonprofitAdminNotificationSettingsRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/adminNotificationSettings",
  method: HttpMethod.GET,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: nonprofitAdminNotificationSettingsResponseCodec,
});

export const updateNonprofitAdminNotificationSettingsRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/adminNotificationSettings",
  method: HttpMethod.PUT,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: nonprofitAdminNotificationSettingsConfigurationCodec,
  responseBodyCodec: t.union([
    t.null,
    nonprofitAdminNotificationSettingsResponseCodec,
  ]),
});

export const getNonprofitProjectsRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/projects",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.partial({
    includeArchived: BooleanFromString,
    includeHideFromSearch: BooleanFromString,
  }),
  bodyCodec: t.type({}),
  publicRoute: {
    publicCacheLengthMinutes: 1,
  },
  responseBodyCodec: t.type({
    nonprofits: t.array(nonprofitResponseCodec),
    tags: t.array(tagResponseCodec),
  }),
});

export const archiveRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitId/archive",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ nonprofitId: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ archived: t.union([t.boolean, t.null]) }),
});

export const getNonprofitRaisedByUTMCampaignRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/raised/:utmCampaign",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({
    nonprofitIdentifier: t.union([uuidCodec, slugCodec]),
    utmCampaign: t.string,
  }),
  paramsCodec: t.partial({ isApiRequest: BooleanFromString }),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({
    currency: currencyCodec,
    raised: nonNegativeIntegerFromStringCodec,
    donations: safeIntCodec,
    supporters: safeIntCodec,
  }),
});

export const donateButtonCreatedRouteSpec = makeRouteSpec({
  path: "/nonprofits/:slug/donateButton",
  method: HttpMethod.POST,
  authenticated: false,
  tokensCodec: t.type({ slug: t.string }),
  paramsCodec: t.type({}),
  bodyCodec: t.partial({ url: t.string }),
  responseBodyCodec: t.type({}),
});
